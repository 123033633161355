import React, { useEffect, useState } from "react";
import "./LoginPage.css";
import { Link, useNavigate } from "react-router-dom";
import { useProcessContext } from "../../context/ProcessContext";
import BASE_URL from "../../../config";
import Swal from "sweetalert2";
// import '../../../../public/bg-reg.jpg'

const LoginPage = () => {
  const { setAuthenticated, setUserData, topRightAlert, centerAlert } =
    useProcessContext();
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleResetForm = () => {
    setLoginData({ ...loginData, email: "", password: "" });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("User logged in:", data);
        topRightAlert("success", data.message);
        // Swal.fire({
        //   title: "Log-in Successfull!",
        //   text: "You have logged in to Oflow",
        //   icon: "success",
        // });
        setAuthenticated(true);
        setUserData(data.user); // Store user data in context
        navigate("/dashboard/configurations");
      } else {
        centerAlert("error", data.message);
        //  Swal.fire({
        //    icon: "error",
        //    title: "Oops...",
        //    text: data.error,
        //  });
        console.error("Error logging in:", data);
      }
    } catch (error) {
      centerAlert("error", error);
      //  Swal.fire({
      //    icon: "error",
      //    title: "Oops...",
      //    text: error,
      //  });
      console.error("Error logging in:", error);
    }
    handleResetForm();
  };
  // useEffect(() => {
  //   document.body.classList.add("background-image");
  //   return () => {
  //     document.body.classList.remove("background-image");
  //   };
  // }, []);

  return (
    // <div className="loginPage">
    //   <h1>Login</h1>
    //   <form method="post" onSubmit={handleLoginSubmit}>
    //     <div className="mb-3">
    //       <label htmlFor="exampleInputEmail1" className="form-label">
    //         Email address
    //       </label>
    //       <input
    //         type="email"
    //         className="form-control"
    //         id="exampleInputEmail1"
    //         name="email"
    //         value={loginData.email}
    //         onChange={handleInputChange}
    //         placeholder="enter your email here..."
    //         aria-describedby="emailHelp"
    //         required
    //       />
    //       <div id="emailHelp" className="form-text">
    //         We'll never share your email with anyone else.
    //       </div>
    //     </div>
    //     <div className="mb-3">
    //       <label htmlFor="exampleInputPassword1" className="form-label">
    //         Password
    //       </label>
    //       <input
    //         type="password"
    //         className="form-control"
    //         id="exampleInputPassword1"
    //         name="password"
    //         value={loginData.password}
    //         onChange={handleInputChange}
    //         placeholder="enter your password here..."
    //         required
    //       />
    //     </div>
    //     <button type="submit" className="btn btn-primary">
    //       Sign-in
    //     </button>
    //     <p>
    //       create new account{" "}
    //       <Link to={"/register"} className="formlink">
    //         register here
    //       </Link>
    //     </p>
    //   </form>
    // </div>

    // =======================================================================
    <div className="limiter">
      <div
        className="container-login100"
        // style={{ backgroundImage: 'url("bg-reg.jpg")' }}
      >
        <div className="wrap-login100 p-l-60 p-r-60 p-t-40 p-b-40">
          <span className="login100-form-title p-b-30"> Login </span>
          <form
            className="login100-form validate-form flex-sb flex-w"
            method="post"
            onSubmit={handleLoginSubmit}
          >
            <span className="txt1 p-b-11"> Email address </span>
            <div
              className="wrap-input100 validate-input"
              data-validate="Username is required"
            >
              <input
                className="input100"
                type="email"
                name="email"
                value={loginData.email}
                onChange={handleInputChange}
                placeholder="enter your email here..."
                required
              />
              <span className="focus-input100" />
            </div>
            <div className="input-st">
              We'll never share your email with anyone else.
            </div>
            <span className="txt1 p-b-11"> Password </span>
            <div
              className="wrap-input100 validate-input"
              data-validate="Password is required"
            >
              <input
                className="input100"
                type="password"
                name="password"
                value={loginData.password}
                onChange={handleInputChange}
                placeholder="enter your password here..."
                required
              />
              <span className="focus-input100" />
            </div>
            <div className="m-b-36 mt-2 ms-2">
              <Link
                to="/forget-password"
                style={{ color: "#007485", fontSize: "13px" }}
              >
                Forget Password
              </Link>
            </div>
            <div className="container-login100-form-btn">
              <button type="submit" className="login100-form-btn">
                Login
              </button>
            </div>
            <div className="input-st p-t-20">
              Create New Account{" "}
              {/* <a href="/register" style={{ color: "#2c57ed" }}> */}
              <Link to="/register" style={{ color: "#007485" }}>
                Register Here
              </Link>
            </div>
          </form>
        </div>
        <div className="background-shape">
          <div className="shape" />
          <div className="shape" />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
