// NodeContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { useProcessContext } from "./ProcessContext";
import BASE_URL from "../../config";

const NodeContext = createContext();

const initialNodes = [
  {
    id: "node-1",
    type: "start",
    position: { x: 0, y: 0 },
    data: {
      value: "start",
      action: "",
      form: "",
      isFormSelected: false,
      kanbanData: null,
      prev: null,
      current: null,
      next: null,
    },
  },
];

const initialEdges = [];

export const NodeProvider = ({ children }) => {
  const { topRightAlert, newProcessId } = useProcessContext();
  const [nodes, setNodes] = useState(() => {
    const savedNodes = sessionStorage.getItem("nodes");
    return savedNodes ? JSON.parse(savedNodes) : initialNodes;
  });
  const [edges, setEdges] = useState(() => {
    const savedEdges = sessionStorage.getItem("edges");
    return savedEdges ? JSON.parse(savedEdges) : initialEdges;
  });
  const [nodesJsonData, setNodesJsonData] = useState(JSON.stringify(nodes));
  const [edgesJsonData, setEdgesJsonData] = useState(JSON.stringify(edges));
  const [showShapeUpdateForm, setShowShapeUpdateForm] = useState(true);
  const [shapeId, setShapeId] = useState(() => {
    const savedShapeId = sessionStorage.getItem("shapeId");
    return savedShapeId ? sessionStorage.getItem("shapeId") : null;
  });
  const [nodeAction, setNodeAction] = useState([]);

  useEffect(() => {
    setNodesJsonData(JSON.stringify(nodes));
  }, [nodes]);

  useEffect(() => {
    setEdgesJsonData(JSON.stringify(edges));
  }, [edges]);
  useEffect(() => {
    sessionStorage.setItem("nodes", JSON.stringify(nodes));
  }, [nodes]);

  useEffect(() => {
    sessionStorage.setItem("edges", JSON.stringify(edges));
  }, [edges]);

  useEffect(() => {
    sessionStorage.setItem("shapeId", shapeId);
  }, [shapeId]);

  // method for remove form when node is deleted
  const handleNodeFormRemove = async (formid) => {
    try {
      let formNodeCount = 0;
      nodes.forEach((node) => {
        if (node.data.form && node.data.form.form_builder_id === formid) {
          formNodeCount++;
        }
      });
      if (formNodeCount === 1) {
        const response = await fetch(`${BASE_URL}/form/delete`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            form_builder_id: formid,
            process_id: newProcessId,
          }),
        });
        if (response.ok) {
          const data = await response.json();
          console.log("form deleted response: ", data);
          // topRightAlert("success", data.message);
        } else {
          const data = await response.json();
          console.log("error in deleting form: ", data.message);
          // centerAlert("error", data.message);
        }
      }
    } catch (error) {
      console.log("error in form delete: ", error);
      // centerAlert("error", error);
    }
  };

  // handling delete node from the process
  const handleNodeDelete = (e) => {
    e.preventDefault();
    setNodes((prevNodes) =>
      prevNodes.filter((node) => {
        if (node.id === shapeId) {
          if(node.data.form){
            handleNodeFormRemove(node.data.form.form_builder_id);
          }
          return false;
        }
        return true;
      })
    );
    topRightAlert("success", "Activity deleted...");
    sessionStorage.setItem("shapeId", null);
    setShapeId(null);
  };

  // method for update prev and next step title with new value
  const updateNodeTitle = (nodes, nodeId, newTitle) => {
    // Find the node to update
    const updatedNodes = nodes.map((node) => {
      if (node.id === nodeId) {
        // Update the title of the current node
        node.data.value = newTitle;
      }

      return node;
    });

    // Now update prev_name and next_name in related nodes
    updatedNodes.forEach((node) => {
      // Update prev_name in next nodes
      if (node.data.prev === nodeId) {
        node.data.prev_name = newTitle;
      }

      // Update next_name for normal nodes
      if (node.data.next === nodeId) {
        node.data.next_name = newTitle;
      }

      // Handle decision nodes with multiple next steps
      if (node.data.type === "decision" && Array.isArray(node.data.next_name)) {
        node.data.next_name.forEach((nextStep) => {
          if (nextStep.nextStepId === nodeId) {
            nextStep.nextStep = newTitle; // Update the nextStep name
          }
        });
      }
    });

    return updatedNodes;
  };

  // code to check for start and end node
  const isStartAndEndNode = () => {
    const startNode = nodes.filter((node) => node.type === "start");
    const endNode = nodes.filter((node) => node.type === "end");
    if (startNode.length > 0 && endNode.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  // code or arrow confirmation
  // const handleArrowCheck = () => {
  //   if (nodes && nodes.length > 0 && edges.length > 1) {
  //     for (const node of nodes) {
  //       const outgoingEdge = edges.find((edge) => edge.source === node.id);
  //       const incomingEdge = edges.find((edge) => edge.target === node.id);

  //       if (node.type === "start") {
  //         // Start nodes should have only outgoing edges
  //         if (
  //           !outgoingEdge ||
  //           !outgoingEdge.target ||
  //           !outgoingEdge.sourceHandle
  //         ) {
  //           return false;
  //         }
  //         if (incomingEdge) {
  //           return false;
  //         }
  //       } else if (node.type === "end") {
  //         // End nodes should have only incoming edges
  //         if (!incomingEdge) {
  //           return false;
  //         }
  //         if (outgoingEdge) {
  //           return false;
  //         }
  //       } else if (node.type === "discard") {
  //         // End nodes should have only incoming edges
  //         if (!incomingEdge) {
  //           return false;
  //         }
  //         if (outgoingEdge) {
  //           return false;
  //         }
  //       } else if (node.type === "reject") {
  //         // End nodes should have only incoming edges
  //         if (!incomingEdge) {
  //           return false;
  //         }
  //         if (outgoingEdge) {
  //           return false;
  //         }
  //       } else {
  //         // Other nodes should have both incoming and outgoing edges
  //         if (
  //           !outgoingEdge ||
  //           !outgoingEdge.target ||
  //           !outgoingEdge.sourceHandle
  //         ) {
  //           return false;
  //         }
  //         if (!incomingEdge) {
  //           return false;
  //         }
  //       }
  //     }
  //     // All nodes are correctly connected
  //     return true;
  //   }
  //   return false;
  // };
  const handleArrowCheck = () => {
    if (nodes && nodes.length > 0 && edges.length > 1) {
      for (const node of nodes) {
        const outgoingEdges = edges.filter((edge) => edge.source === node.id);
        const incomingEdges = edges.filter((edge) => edge.target === node.id);

        switch (node.type) {
          case "start":
            // Start nodes should only have outgoing edges
            if (
              outgoingEdges.length === 0 ||
              !outgoingEdges[0].target ||
              !outgoingEdges[0].sourceHandle
            ) {
              console.log("error with start node outgoing edges::::::::::::::");
              // return false;
              return {
                status: false,
                report: `Please connect outgoing arrow edge on ${node.data.value} `,
              };
            }
            if (incomingEdges.length > 0) {
              console.log("error: start node has incoming edges::::::::::::::");
              // return false;
              return {
                status: false,
                report: `Please connect incoming arrow edge on ${node.data.value} `,
              };
            }
            break;

          case "end":
          case "reject":
          case "discard":
          case "exception":
            // End, reject, discard, and exception nodes should only have incoming edges
            if (incomingEdges.length === 0) {
              console.log(
                "error with end/reject/discard/exception node incoming edges::::::::::::::"
              );
              // return false;
              return {
                status: false,
                report: `Please connect incoming arrow edge on ${node.data.value} `,
              };
            }
            if (outgoingEdges.length > 0) {
              console.log(
                "error: end/reject/discard/exception node has outgoing edges::::::::::::::"
              );
              // return false;
              return {
                status: false,
                report: `Please connect outgoing arrow edge on ${node.data.value} `,
              };
            }
            break;

          case "decision":
            // Decision nodes should have both incoming and multiple outgoing edges
            if (incomingEdges.length === 0) {
              console.log(
                "error with decision node incoming edges::::::::::::::"
              );
              // return false;
              return {
                status: false,
                report: `Please connect incoming arrow edge on ${node.data.value} `,
              };
            }
            if (
              outgoingEdges.length === 0 ||
              outgoingEdges.some((edge) => !edge.target || !edge.sourceHandle)
            ) {
              console.log(
                "error with decision node outgoing edges::::::::::::::"
              );
              // return false;
              return {
                status: false,
                report: `Please connect outgoing arrow edge on ${node.data.value} `,
              };
            }
            break;

          default:
            // Other nodes should have both incoming and outgoing edges
            // if (
            //   incomingEdges.length === 0 ||
            //   outgoingEdges.length === 0 ||
            //   outgoingEdges.some((edge) => !edge.target || !edge.sourceHandle)
            // ) {
            //   console.log(
            //     "error with normal activity node edges::::::::::::::"
            //   );
            //   return false;
            // }
            if (incomingEdges.length === 0) {
              console.log(
                "error with normal activity node edges::::::::::::::"
              );
              // return false;
              return {
                status: false,
                report: `Please connect incoming arrow edge on ${node.data.value} `,
              };
            }
            if (
              outgoingEdges.length === 0 ||
              outgoingEdges.some((edge) => !edge.target || !edge.sourceHandle)
            ) {
              console.log(
                "error with normal activity node edges::::::::::::::"
              );
              // return false;
              return {
                status: false,
                report: `Please connect outgoing arrow edge on ${node.data.value} `,
              };
            }
            break;
        }
      }
      // All nodes are correctly connected
      // return true;
      return { status: true };
    }
    // return false;
    return { status: false, report: "Please add activites first" };
  };

  // code for next and prev step confirmation
  // const handlePrevNextCheck = () => {
  //   if (nodes && nodes.length > 0) {
  //     for (const node of nodes) {
  //       const { prev_name, next_name } = node.data;

  //       if (node.type === "start") {
  //         // Start nodes should only have a next value
  //         if (!next_name) {
  //           console.log("error with start::::::::::::::");
  //           return false;
  //         }
  //       } else if (node.type === "end") {
  //         // Start nodes should only have a next value
  //         if (!prev_name) {
  //           console.log("error with end::::::::::::::");
  //           return false;
  //         }
  //       } else if (node.type === "decision") {
  //         // Decision nodes should have both prev and next values
  //         if (!prev_name) {
  //           console.log("error with decision::::::::::");
  //           return false;
  //         }
  //         // Ensure next is an array of objects with nextStep key
  //         if (
  //           !next_name ||
  //           !Array.isArray(next_name) ||
  //           next_name.length === 0
  //         ) {
  //           console.log("error with decision:::::::::::");
  //           return false;
  //         }
  //         for (const step of next_name) {
  //           if (!step.nextStep) {
  //             console.log("error with decision::::::::::");
  //             return false;
  //           }
  //         }
  //         if (!node.data.elseNextStep) {
  //           return false;
  //         }
  //       } else if (node.type === "reject" || node.type === "discard") {
  //         // Other nodes should have both prev and next values

  //         if (!prev_name) {
  //           console.log("error with reject::::::::::::::");
  //           return false;
  //         }
  //       } else if (node.type === "exception") {
  //         // Other nodes should have both prev and next values

  //         if (!prev_name) {
  //           console.log("error with exception::::::::::::::");
  //           return false;
  //         }
  //       } else if (node.type !== "exception") {
  //         // Other nodes should have both prev and next values
  //         if (!prev_name || !next_name) {
  //           console.log("node name::::::::::: ", node.data.value);
  //           console.log("error with exception normal activity::::::::::");
  //           return false;
  //         }
  //       } else if (node.type !== "start" || node.type !== "end") {
  //         // Other nodes should have both prev and next values
  //         if (!prev_name || !next_name) {
  //           console.log("node name::::::::::: ", node.data.value);
  //           console.log("error with normal activity::::::::::");
  //           return false;
  //         }
  //       }
  //       // End nodes are not checked for prev or next
  //     }
  //     // All nodes have correct prev and next values
  //     return true;
  //   }
  //   return false;
  // };
  const handlePrevNextCheck = () => {
    if (nodes && nodes.length > 0) {
      for (const node of nodes) {
        const { prev_name, next_name } = node.data;

        switch (node.type) {
          case "start":
            if (!next_name) {
              console.log("error with start::::::::::::::");
              return {
                status: false,
                report: `Please set next step for ${node.data.value}`,
              };
            }
            break;

          case "end":
          case "reject":
          case "discard":
          case "exception":
            if (!prev_name) {
              console.log(
                "error with end/reject/discard/exception::::::::::::::"
              );
              // return false;
              return {
                status: false,
                report: `Please set prev step for ${node.data.value}`,
              };
            }
            break;

          case "decision":
            if (!prev_name) {
              console.log("error with decision::::::::::");
              // return false;
              return {
                status: false,
                report: `Please set prev step for ${node.data.value}`,
              };
            }
            if (
              !next_name ||
              !Array.isArray(next_name) ||
              next_name.length === 0
            ) {
              console.log("error with decision:::::::::::");
              // return false;
              return {
                status: false,
                report: `Please set conditions for next step on ${node.data.value}`,
              };
            }
            for (const step of next_name) {
              if (!step.nextStep) {
                console.log("error with decision::::::::::");
                // return false;
                return {
                  status: false,
                  report: `Please set next step for ${node.data.value}`,
                };
              }
            }
            // Ensure elseNextStep exists
            if (!node.data.elseNextStep) {
              console.log("error with decision elseNextStep::::::::::");
              // return false;
              return {
                status: false,
                report: `Please set exception step for ${node.data.value}`,
              };
            }
            break;

          default:
            // Other nodes should have both prev and next values
            console.log("error with other nodes::::::::::::::");
            // if (!prev_name || !next_name) {
            //   console.log("node name::::::::::: ", node.data.value);
            //   console.log("error with normal activity::::::::::");
            //   // return false;
            //   return {
            //     status: false,
            //     report: `Please set next step for ${node.data.value}`,
            //   };
            // }
            if (!prev_name) {
              console.log("node name::::::::::: ", node.data.value);
              console.log("error with normal activity::::::::::");
              // return false;
              return {
                status: false,
                report: `Please set prev step for ${node.data.value}`,
              };
            }
            if (!next_name) {
              console.log("node name::::::::::: ", node.data.value);
              console.log("error with normal activity::::::::::");
              // return false;
              return {
                status: false,
                report: `Please set next step for ${node.data.value}`,
              };
            }
            break;
        }
      }
      // All nodes have correct prev and next values
      // return true;
      return { status: true };
    }
    // return false;
    return { status: false, report: `Please add activities first` };
  };

  // code for next and prev step confirmation
  // const handleAssignedFormCheck = () => {
  //   if (nodes && nodes.length > 0) {
  //     for (const node of nodes) {
  //       const { isFormSelected } = node.data;
  //       if (node.type !== "decision" && node.type !== "end") {
  //         if (!isFormSelected) {
  //           return false;
  //         }
  //       }
  //     }
  //     // All nodes have correct prev and next values
  //     return true;
  //   }
  //   return false;
  // };

  const handleAssignedFormCheck = () => {
    if (nodes && nodes.length > 0) {
      for (const node of nodes) {
        const { isFormSelected } = node.data;

        // Types that should not require a form to be assigned
        const excludedTypes = [
          "decision",
          "end",
          "discard",
          "reject",
          "exception",
        ];

        // Check for assigned form only if the node type is not in the excluded list
        if (!excludedTypes.includes(node.type)) {
          if (!isFormSelected) {
            console.log("Form not assigned for node:", node.data.value);
            return { status: false, nodeName: node.data.value };
          }
        }
      }
      // All applicable nodes have forms assigned
      return { status: true };
    }
    return { status: false };
  };

  const updateNodeLinks = (nodes) => {
    nodes.forEach((node, index) => {
      node.data.prev = index === 0 ? null : nodes[index - 1].id;
      node.data.current = node.id;
      node.data.next = index === nodes.length - 1 ? null : nodes[index + 1].id;
    });
    return nodes;
  };

  const setNodesWithLinks = (updatedNodes) => {
    setNodes(updateNodeLinks(updatedNodes));
  };

  const addNode = (newNode) => {
    setNodesWithLinks([...nodes, newNode]);
  };

  const [showNodesJson, setShowNodesJson] = useState(false);
  const [showEdgesJson, setShowEdgesJson] = useState(false);

  const handleCloseJson = () => {
    setShowNodesJson(false);
    setShowEdgesJson(false);
  };

  return (
    <NodeContext.Provider
      value={{
        initialNodes,
        nodes,
        setNodes,
        edges,
        setEdges,
        nodesJsonData,
        edgesJsonData,
        showNodesJson,
        setShowNodesJson,
        showEdgesJson,
        setShowEdgesJson,
        handleCloseJson,
        showShapeUpdateForm,
        setShowShapeUpdateForm,
        shapeId,
        setShapeId,
        nodeAction,
        setNodeAction,
        addNode,
        handleNodeDelete,
        isStartAndEndNode,
        handleArrowCheck,
        handlePrevNextCheck,
        handleAssignedFormCheck,
        updateNodeTitle,
      }}
    >
      {children}
    </NodeContext.Provider>
  );
};

export const useNodeContext = () => {
  return useContext(NodeContext);
};
