import React, { useState } from "react";
import "./LoginPage.css";
import { Link, useNavigate } from "react-router-dom";
import { useProcessContext } from "../../context/ProcessContext";
import BASE_URL, { API_KEY } from "../../../config";
import { useAdminContext } from "../../context/AdminContext";

const AdminLoginPage = () => {
  const { setAdminAuthenticated, setAdminData } = useAdminContext();
  const { topRightAlert, centerAlert } = useProcessContext();
  const [adminLoginData, setAdminLoginData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdminLoginData({ ...adminLoginData, [name]: value });
  };

  const handleResetForm = () => {
    setAdminLoginData({ ...adminLoginData, email: "", password: "" });
  };

  const handleAdminLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}/user/login/admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({...adminLoginData, key: API_KEY}),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Admin logged in:", data);
        topRightAlert("success", data.message);
        setAdminAuthenticated(true);
        setAdminData(data.user); // Store user data in context
        navigate("/admin-dashboard/users");
      } else {
        centerAlert("error", data.message);
        console.error("Error logging in:", data);
      }
    } catch (error) {
      centerAlert("error", error);
      console.error("Error logging in:", error);
    }
    handleResetForm();
  };

  return (
    <div className="limiter">
      <div
        className="container-login100"
        // style={{ backgroundImage: 'url("bg-reg.jpg")' }}
      >
        <div className="wrap-login100 p-l-60 p-r-60 p-t-40 p-b-40">
          <span className="login100-form-title p-b-30"> Admin Login </span>
          <form
            className="login100-form validate-form flex-sb flex-w"
            method="post"
            onSubmit={handleAdminLoginSubmit}
          >
            <span className="txt1 p-b-11"> Email address* </span>
            <div
              className="wrap-input100 validate-input"
              data-validate="Username is required"
            >
              <input
                className="input100"
                type="text"
                name="email"
                value={adminLoginData.email}
                onChange={handleInputChange}
                placeholder="enter your email here..."
                required
              />
              <span className="focus-input100" />
            </div>
            {/* <div className="input-st">
              We'll never share your email with anyone else.
            </div> */}
            <span className="txt1 p-b-11">Password*</span>
            <div
              className="wrap-input100 validate-input m-b-36"
              data-validate="Password is required"
            >
              <input
                className="input100"
                type="password"
                name="password"
                value={adminLoginData.password}
                onChange={handleInputChange}
                placeholder="enter your password here..."
                required
              />
              <span className="focus-input100" />
            </div>
            {/* <div className="m-b-36 mt-2 ms-2">
              <Link
                to="/forget-password"
                style={{ color: "#007485", fontSize: "13px" }}
              >
                Forget Password
              </Link>
            </div> */}
            <div className="container-login100-form-btn">
              <button type="submit" className="login100-form-btn">
                Login
              </button>
            </div>
            {/* <div className="input-st p-t-20">
              Create New Account{" "}
              <Link to="/register" style={{ color: "#007485" }}>
                Register Here
              </Link>
            </div> */}
          </form>
        </div>
        <div className="background-shape">
          <div className="shape" />
          <div className="shape" />
        </div>
      </div>
    </div>
  );
};

export default AdminLoginPage;
