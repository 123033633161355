import React, { useEffect, useState } from "react";
import "./RegisterPage.css";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL, { API_KEY } from "../../../config";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import { useProcessContext } from "../../context/ProcessContext";

const RegisterPage = () => {
  const { centerAlert, centerAlertWithTitle } = useProcessContext();
  const [registerData, setRegisterData] = useState({
    userid: uuidv4(),
    username: "",
    email: "",
    phone: "",
    company: "",
    password: "",
    confirm_password: "",
  });

  const navigate = useNavigate();
  const [isRegisterClick, setRegisterClick] = useState(() => {
    const savedRegisterClicked = sessionStorage.getItem("registerClicked");
    return savedRegisterClicked ? JSON.parse(savedRegisterClicked) : false;
  });
  const [otp, setOtp] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRegisterData({ ...registerData, [name]: value });
  };

  const handleResetForm = () => {
    setRegisterData({
      ...registerData,
      userid: uuidv4(),
      username: "",
      email: "",
      phone: "",
      company: "",
      password: "",
      confirm_password: "",
    });
  };

  // method for register data store
  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    if (registerData.password !== registerData.confirm_password) {
      return centerAlertWithTitle(
        "Password not mathced",
        "Please enter same password in confirm password",
        "error"
      );
    }

    try {
      const response = await fetch(`${BASE_URL}/user/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(registerData),
      });
      const data = await response.json();
      if (response.ok && response.status === 201) {
        setRegisterClick(true);
        sessionStorage.setItem("pinemail", JSON.stringify(registerData.email));
        handleResetForm();
        // Swal.fire({
        //   title: "Created Successfull!",
        //   text: "New User has been Created Successfully.",
        //   icon: "success",
        // });
      } else {
        centerAlert("Error", data.message);
        // Swal.fire({
        //   title: "User Already Exist.",
        //   text: "Please Enter Unqiue Name or Email Id.",
        //   icon: "warning",
        // });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
    }
    // navigate("/");
  };

  // method for verify otp for register email
  const handleOtpVerify = async (e) => {
    e.preventDefault();
    const emailid = JSON.parse(sessionStorage.getItem("pinemail"));
    try {
      const response = await fetch(`${BASE_URL}/user/create/validate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: emailid,
          otp: otp,
          key: API_KEY,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        centerAlert("success", data.message);
        setOtp("");
        setRegisterClick(false);
        sessionStorage.removeItem("pinemail");
        sessionStorage.removeItem("registerClicked");
        navigate("/");
      } else {
        const data = await response.json();
        centerAlert("error", data.message);
        console.error("Error updating process status:", data);
      }
    } catch (error) {
      centerAlert("error", error);
      console.error("Error updating process status:", error);
    }
  };

  // useEffect for setting register click data to session
  useEffect(() => {
    sessionStorage.setItem("registerClicked", JSON.stringify(isRegisterClick));
  }, [isRegisterClick]);

  useEffect(() => {
    document.body.classList.add("background-image");
    return () => {
      document.body.classList.remove("background-image");
    };
  }, []);
  console.log(`${BASE_URL}/user/create`);
  return (
    <div className="limiter">
      <div className="container-login100">
        <div
          className="wrap-login100 p-l-60 p-r-60 p-t-40 p-b-40"
          style={{ width: "550px" }}
        >
          <span className="login100-form-title p-b-30">
            {" "}
            {isRegisterClick ? "Verify OTP" : "Register"}{" "}
          </span>
          {isRegisterClick ? (
            <form
              className="login100-form validate-form flex-sb flex-w"
              method="post"
              onSubmit={handleOtpVerify}
            >
              <span className="txt1 p-b-11"> Enter OTP </span>
              <div
                className="wrap-input100 validate-input"
                data-validate="Username is required"
              >
                <input
                  className="input100"
                  type="text"
                  name="otp"
                  value={otp}
                  maxLength={6}
                  minLength={6}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="enter otp here..."
                  required
                />
                <span className="focus-input100" />
              </div>
              <div className="input-st m-b-36">
                Please enter otp recieved on registered email address.
              </div>
              <div className="container-login100-form-btn">
                <button type="submit" className="login100-form-btn">
                  Verify Otp
                </button>
              </div>
            </form>
          ) : (
            <form
              className="login100-form validate-form flex-sb flex-w"
              method="post"
              onSubmit={handleRegisterSubmit}
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: "48%" }}>
                <span className="txt1 p-b-11"> Full name* </span>
                <div
                  className="wrap-input100 validate-input"
                  data-validate="Username is required"
                >
                  <input
                    className="input100"
                    type="text"
                    name="username"
                    value={registerData.username}
                    onChange={handleInputChange}
                    required
                  />
                  <span className="focus-input100" />
                </div>
              </div>
              <div style={{ width: "48%" }}>
                <span className="txt1 p-b-11"> Company </span>
                <div
                  className="wrap-input100 validate-input"
                  data-validate="Company is required"
                >
                  <input
                    className="input100"
                    type="text"
                    name="company"
                    value={registerData.company}
                    onChange={handleInputChange}
                    // required
                  />
                  <span className="focus-input100" />
                </div>
              </div>
              <div style={{ width: "48%" }}>
                <span className="txt1 p-b-11"> Email address* </span>
                <div
                  className="wrap-input100 validate-input"
                  data-validate="Email is required"
                >
                  <input
                    className="input100"
                    type="email"
                    name="email"
                    value={registerData.email}
                    onChange={handleInputChange}
                    required
                  />
                  <span className="focus-input100" />
                </div>
              </div>
              {/* <div className="input-st">
                We'll never share your email with anyone else.
              </div> */}
              <div style={{ width: "48%" }}>
                <span className="txt1 p-b-11"> Phone </span>
                <div
                  className="wrap-input100 validate-input"
                  data-validate="Phone is required"
                >
                  <input
                    className="input100"
                    type="text"
                    name="phone"
                    value={registerData.phone}
                    onChange={handleInputChange}
                    // required
                  />
                  <span className="focus-input100" />
                </div>
              </div>
              <div style={{ width: "48%" }}>
                <span className="txt1 p-b-11"> Password* </span>
                <div
                  className="wrap-input100 validate-input m-b-36"
                  data-validate="Password is required"
                >
                  <input
                    className="input100"
                    type="password"
                    name="password"
                    value={registerData.password}
                    onChange={handleInputChange}
                    required
                  />
                  <span className="focus-input100" />
                </div>
              </div>
              <div style={{ width: "48%" }}>
                <span className="txt1 p-b-11"> Confirm Password* </span>
                <div
                  className="wrap-input100 validate-input m-b-36"
                  data-validate="Confirm password is required"
                >
                  <input
                    className="input100"
                    type="password"
                    name="confirm_password"
                    value={registerData.confirm_password}
                    onChange={handleInputChange}
                    required
                  />
                  <span className="focus-input100" />
                </div>
              </div>
              <div className="container-login100-form-btn">
                <button type="submit" className="login100-form-btn">
                  Register now
                </button>
              </div>
              <div className="input-st p-t-20">
                Already have an account?{" "}
                <Link to="/" style={{ color: "#007485" }}>
                  Login Here
                </Link>
              </div>
            </form>
          )}
        </div>
        <div className="background-shape">
          <div className="shape" />
          <div className="shape" />
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
