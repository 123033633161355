import React, { useEffect, useState } from "react";
import "./DomainComponent.css";
import { FaRegTimesCircle } from "react-icons/fa";
import { useFormContext } from "../../context/FormContext";

const ConditionField = ({
  index,
  condition,
  updateCondition,
  removeCondition,
  selectedModalFields,
}) => {
  const { formData, parseFormData } = useFormContext();
  const operators = [
    "=",
    "!=",
    ">",
    "<",
    ">=",
    "<=",
    "in",
    "not in",
    "like",
    "ilike",
  ];
  const [domainFieldValue, setDomainFieldValue] = useState([]);
  console.log("selected model fields::::: ", selectedModalFields);

  useEffect(() => {
    const newFields = parseFormData(formData);
    if (newFields && Array.isArray(newFields)) {
      let tempField = [];
      newFields.forEach((field) => {
        tempField.push(field.technicalName);
      });
      setDomainFieldValue(tempField);
    }
  }, [formData]);
  // console.log("form field name: ", newFields);

  console.log("allDomainField::::::::: ", domainFieldValue);

  return (
    <div className="domain_condition_box">
      {index > 0 && (
        <select
          className="domain_condition_logic_operator"
          value={condition.logicOperator}
          onChange={(e) =>
            updateCondition(index, "logicOperator", e.target.value)
          }
        >
          <option value="&">&</option>
          <option value="|">|</option>
          <option value="!">!</option>
          {/* <option value="&">AND</option>
          <option value="|">OR</option>
          <option value="!">NOT</option> */}
        </select>
      )}
      <div className="domain_condition_field">
        <select
          className="domain_condition_operand"
          value={condition.field_name}
          onChange={(e) => updateCondition(index, "field_name", e.target.value)}
        >
          <option value="">Field select</option>
          {selectedModalFields &&
            Array.isArray(selectedModalFields) &&
            selectedModalFields?.map((operand) => (
              <option key={operand} value={operand}>
                {operand}
              </option>
            ))}
        </select>
        {/* <input
          type="text"
          placeholder="Field Name"
          value={condition.field_name}
          onChange={(e) => updateCondition(index, "field_name", e.target.value)}
        /> */}
        <select
          className="domain_condition_operator"
          value={condition.operator}
          onChange={(e) => updateCondition(index, "operator", e.target.value)}
        >
          {operators.map((op) => (
            <option key={op} value={op}>
              {op}
            </option>
          ))}
        </select>
        <input
          list="domain_value_list"
          type="text"
          placeholder="Value"
          value={condition.value}
          onChange={(e) => updateCondition(index, "value", e.target.value)}
          style={{border:"1px solid #ddd", paddingLeft:"10px", height:"30px", borderRadius:"5px", outline:"none", color:"#585858"}}
        />

        <datalist id="domain_value_list">
          {domainFieldValue?.map((domainVal) => (
            <option key={domainVal} value={domainVal}></option>
          ))}
        </datalist>

        <button
          onClick={() => removeCondition(index)}
          className="fdu_domain_condition_remove_btn"
        >
          <FaRegTimesCircle />
        </button>
      </div>
    </div>
  );
};

const DomainComponent = ({
  selectedModalFields,
  updateFieldData,
  handleBlurData,
  newTableField,
  setNewTableRelatedModelDomain,
}) => {
  const [conditions, setConditions] = useState([]);

  const addDomainCondition = (e) => {
    e.preventDefault();
    setConditions([
      ...conditions,
      { field_name: "", operator: "=", value: "", logicOperator: "&" },
    ]);
  };

  const updateCondition = (index, key, value) => {
    const updatedConditions = conditions.map((condition, idx) =>
      idx === index ? { ...condition, [key]: value } : condition
    );
    setConditions(updatedConditions);
  };

  const removeCondition = (index) => {
    const updatedConditions = conditions.filter((_, idx) => idx !== index);
    setConditions(updatedConditions);
  };

  const saveDomain = (e) => {
    e.preventDefault();
    const domain = conditions.reduce((acc, condition, index) => {
      const conditionArray = [
        condition.field_name,
        condition.operator,
        condition.value,
      ];
      if (index === 0) {
        return [...acc, conditionArray];
      } else {
        return [...acc, condition.logicOperator, conditionArray];
      }
    }, []);
    console.log("Generated Domain:", domain);
    if (updateFieldData && updateFieldData.title && updateFieldData !== "") {
      updateFieldData.domain = domain || null;
      handleBlurData();
    }
    if (
      newTableField &&
      newTableField.type &&
      ["many2many", "many2one", "one2many"].includes(newTableField.type)
    ) {
      setNewTableRelatedModelDomain(domain || null)
    }
    // return domain;
  };

  return (
    <div className="domain_component">
      {conditions.map((condition, index) => (
        <ConditionField
          key={index}
          index={index}
          condition={condition}
          updateCondition={updateCondition}
          removeCondition={removeCondition}
          selectedModalFields={selectedModalFields}
        />
      ))}
      <button onClick={addDomainCondition} className="domain_condition_btn">
        Add Condition
      </button>
      {conditions.length > 0 && (
        <button onClick={saveDomain} className="domain_condition_btn">
          Save Domain
        </button>
      )}
    </div>
  );
};

export default DomainComponent;
