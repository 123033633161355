import React, { memo, useEffect, useState } from "react";
import { GiCircle } from "react-icons/gi";
// import { Handle, Position } from "reactflow";
import { useNodeContext } from "../../../../context/NodeContext";
import BASE_URL from "../../../../../config";
import { useFormContext } from "../../../../context/FormContext";
import { MdDeleteOutline } from "react-icons/md";
import { Handle, Position } from "@xyflow/react";
import CustomHandle from "../custome_handle/CustomHandle";

const Circle = ({ id, data, isConnectable }) => {
  const {
    nodes,
    setShowShapeUpdateForm,
    shapeId,
    setShapeId,
    handleNodeDelete,
  } = useNodeContext();
  const { setNewFormId, fetchForms } = useFormContext();
  const [isDelBtn, setDelBtn] = useState(false);

  // useEffect for shoeing del button not active
  useEffect(() => {
    if (id === shapeId) {
      setDelBtn(true);
    } else {
      setDelBtn(false);
    }
  }, [shapeId]);

  const handleClick = async (e) => {
    e.stopPropagation();
    setDelBtn(!isDelBtn);
    if (Array.isArray(nodes)) {
      const selectedNode = nodes.find((node) => node.id === id);
      if (selectedNode.data.form === "" || selectedNode.data.form === null) {
        setNewFormId(null);
        fetchForms();
      } else {
        setNewFormId(selectedNode.data.form.form_builder_id);
      }
    }
    setShowShapeUpdateForm(true);
    setShapeId(id);
  };

  return (
    <div className="" onClick={handleClick}>
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        style={{ top: "50%" }}
        isConnectable={isConnectable}
        // connectionCount={1}
      />

      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "0px",
        }}
      >
        <GiCircle style={{ fontSize: "35px" }} />
        <span
          style={{
            position: "absolute",
            fontSize: "8px",
            bottom: "-10px",
            width: "max-content",
          }}
        >
          {data.value}
        </span>
        {isDelBtn && (
          <button
            style={{ top: "-7px", right: "-8px" }}
            className="btn pm_shape_del_btn"
            onClick={handleNodeDelete}
          >
            <MdDeleteOutline />
          </button>
        )}
      </div>
    </div>
  );
};

export default memo(Circle);
