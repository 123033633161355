import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL, { API_KEY } from "../../../config";
import { useProcessContext } from "../../context/ProcessContext";

const NewPasswordPage = () => {
  const { topRightAlert } = useProcessContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState(() => {
    const savedEmail = sessionStorage.getItem("pinemail");
    return savedEmail ? JSON.parse(savedEmail) : null;
  });
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState(null);
  const [passwordChanged, setPasswordChanged] = useState(() => {
    const savedChangePass = sessionStorage.getItem("changedPass");
    return savedChangePass ? JSON.parse(savedChangePass) : false;
  });
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPassword({ ...password, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.newPassword === password.confirmPassword) {
      try {
        const response = await fetch(`${BASE_URL}/user/email/change`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password.confirmPassword,
            key: API_KEY,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          topRightAlert("success", data.message);
          setEmail(null);
          setError(null);
          setPasswordChanged(true);
          sessionStorage.removeItem("pinemail");
          console.log("Password Changed Successfully");
          setPassword({ newPassword: "", confirmPassword: "" });
        } else {
          const data = await response.json();
          topRightAlert("error", data.message);
          console.error("Error updating process status:", data);
        }
      } catch (error) {
        topRightAlert("error", error);
        console.error("Error updating process status:", error);
      }
    } else {
      setError("Confirm password does not match");
    }
  };
  const handleLoginBack = (e) => {
    e.preventDefault();
    setPasswordChanged(false);
    sessionStorage.removeItem("changedPass");
    navigate("/");
  };
  useEffect(() => {
    sessionStorage.setItem("changedPass", JSON.stringify(passwordChanged));
  }, [passwordChanged]);
  return (
    <div className="limiter">
      <div
        className="container-login100"
        // style={{ backgroundImage: 'url("bg-reg.jpg")' }}
      >
        <div className="wrap-login100 p-l-60 p-r-60 p-t-40 p-b-40">
          <span className="login100-form-title p-b-30">
            {" "}
            {passwordChanged ? "Password Changed" : "Change Password"}{" "}
          </span>
          {passwordChanged ? (
            <>
              <p className="text-center">
                Password has been updated successfully
              </p>
              <p className="text-center m-b-36">
                Login with your new credential
              </p>
              <div className="container-login100-form-btn">
                <button onClick={handleLoginBack} className="login100-form-btn">
                  Login
                </button>
              </div>
            </>
          ) : (
            <form
              className="login100-form validate-form flex-sb flex-w"
              method="post"
              onSubmit={handleSubmit}
            >
              {/* <span className="txt1 p-b-11"> Email address </span>
            <div
              className="wrap-input100 validate-input"
              data-validate="Username is required"
            >
              <input
                className="input100"
                type="email"
                name="email"
                // value={loginData.email}
                // onChange={handleInputChange}
                placeholder="enter your email here..."
                required
              />
              <span className="focus-input100" />
            </div>
            <div className="input-st">
              We'll never share your email with anyone else.
            </div> */}
              <span className="txt1 p-b-11"> New Password </span>
              <div
                className="wrap-input100 validate-input"
                data-validate="Password is required"
              >
                <input
                  className="input100"
                  type="password"
                  name="newPassword"
                  value={password.newPassword}
                  onChange={handlePasswordChange}
                  placeholder="enter new password here..."
                  required
                />
                <span className="focus-input100" />
              </div>
              <span className="txt1 p-b-11"> Confirm Password </span>
              <div
                className="wrap-input100 validate-input"
                data-validate="Password is required"
              >
                <input
                  className="input100"
                  type="password"
                  name="confirmPassword"
                  value={password.confirmPassword}
                  onChange={handlePasswordChange}
                  placeholder="enter confirm password here..."
                  required
                />
                <span className="focus-input100" />
              </div>
              <p
                className="m-b-36 text-danger"
                style={{
                  fontSize: "12px",
                  marginTop: "3px",
                  marginLeft: "3px",
                }}
              >
                {error || " "}
              </p>
              <div className="container-login100-form-btn">
                <button type="submit" className="login100-form-btn">
                  Change Password
                </button>
              </div>
              {/* <div className="input-st p-t-20">
              Create New Account{" "}
              <Link to="/register" style={{ color: "#2c57ed" }}>
                Register Here
              </Link>
            </div> */}
            </form>
          )}
        </div>
        <div className="background-shape">
          <div className="shape" />
          <div className="shape" />
        </div>
      </div>
    </div>
  );
};

export default NewPasswordPage;
