import React, { useEffect, useState } from "react";
import "./FormControl.css";
import NumBox from "./number-box.svg";
import {
  BsPersonFill,
  BsPersonFillAdd,
  BsPersonFillCheck,
  BsTextareaResize,
} from "react-icons/bs";
import {
  FaCamera,
  FaFile,
  FaImage,
  FaTable,
  FaUserClock,
} from "react-icons/fa";
import { PiTextbox } from "react-icons/pi";
import { CgPassword } from "react-icons/cg";
import {
  MdDateRange,
  MdOutlineCurrencyRupee,
  MdOutlineWorkHistory,
} from "react-icons/md";
import { AiOutlineMail } from "react-icons/ai";
import { MdSmartButton } from "react-icons/md";
import { RxGroup } from "react-icons/rx";
import { HiOutlineSelector } from "react-icons/hi";
import { TfiLayoutTab } from "react-icons/tfi";
import { LuAlignVerticalSpaceAround, LuTextCursorInput } from "react-icons/lu";
import { useFormContext } from "../../../context/FormContext";
import { IoChatbubbles, IoGlobeOutline } from "react-icons/io5";
import {
  TbDecimal,
  TbRelationManyToMany,
  TbRelationOneToMany,
} from "react-icons/tb";
import { IoMdCheckboxOutline, IoMdRibbon } from "react-icons/io";
import { TiImage } from "react-icons/ti";

const FormControl = () => {
  const { formData, parseFormData, isKanbanBuilder } = useFormContext();
  const [kanabanFieldBtn, setKanabanFieldBtn] = useState(null);

  // useEffect for updating the buttons
  useEffect(() => {
    if (formData && Array.isArray(formData)) {
      setKanabanFieldBtn(parseFormData(formData));
    }
  }, [formData]);
  // console.log("kanbanFieldBtn: ", JSON.stringify(kanabanFieldBtn));

  // method for dragging kanban field buttons
  const handleKanbanFieldDragStart = (e, type, title, tooltip) => {
    const data = { type, title, tooltip };
    e.dataTransfer.setData("text/plain", JSON.stringify(data));
  };
  // method for dragging form buttons
  const handleDragStart = (
    e,
    type,
    title,
    widget,
    defaultValue,
    technicalName,
    placeholder,
    tooltip,
    isRequired,
    isReadOnly,
    isTracking,
    // isCreatedBy,
    // isLoggedinUser,
    isOnChange,
    isInvisible
  ) => {
    const data = {
      type,
      title,
      widget,
      defaultValue,
      technicalName,
      placeholder,
      tooltip,
      isRequired,
      isReadOnly,
      isTracking,
      // isCreatedBy,
      // isLoggedinUser,
      isOnChange,
      isInvisible,
    };
    e.dataTransfer.setData("text/plain", JSON.stringify(data));
  };

  const componentsBtn = [
    {
      type: "label",
      label: "Label",
      widget: "label",
      icon: <LuTextCursorInput style={{ fontSize: "20px" }} />,
      technicalName: "",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "static_image",
      label: "Static Image",
      widget: "static_image",
      icon: <TiImage style={{ fontSize: "20px" }} />,
      defaultValue: "",
      technicalName: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "ribbon",
      label: "Ribbon",
      widget: "ribbon",
      icon: <IoMdRibbon style={{ fontSize: "20px" }} />,
      defaultValue: "",
      technicalName: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "button",
      label: "Button",
      widget: "button",
      icon: <MdSmartButton className="fieldicon" />,
      defaultValue: "",
      technicalName: "x_o2b_button",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "object",
      label: "Smart Button",
      widget: "statinfo",
      icon: <MdSmartButton className="fieldicon" />,
      defaultValue: "",
      technicalName: "x_o2b_smart_button",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "group",
      label: "Group",
      widget: "group",
      icon: <RxGroup style={{ fontSize: "20px" }} />,
      defaultValue: "",
      technicalName: "",
      placeholder: "This is group area",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "tab",
      label: "Tabs",
      widget: "tab",
      icon: <TfiLayoutTab style={{ fontSize: "20px" }} />,
      defaultValue: "",
      technicalName: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "table",
      label: "Table",
      widget: "table",
      icon: <FaTable style={{ fontSize: "20px" }} />,
      defaultValue: "",
      technicalName: "x_o2b_table",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "chatter",
      label: "Chatter",
      widget: "chatter",
      icon: <IoChatbubbles style={{ fontSize: "20px" }} />,
      defaultValue: "",
      technicalName: "x_o2b_",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "separator",
      label: "Separator",
      widget: "separator",
      icon: <LuAlignVerticalSpaceAround style={{ fontSize: "20px" }} />,
      defaultValue: "",
      technicalName: "x_o2b_",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "oe_avatar",
      label: "Form Image",
      widget: "image",
      icon: <FaImage style={{ fontSize: "20px" }} />,
      defaultValue: "",
      technicalName: "x_o2b_form_image",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "remark_history",
      label: "Remark History",
      widget: "remark_history",
      icon: <MdOutlineWorkHistory style={{ fontSize: "20px" }} />,
      defaultValue: "",
      technicalName: "x_o2b_remark_history",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
  ];
  const fieldsBtn = [
    {
      type: "char",
      label: "Text",
      widget: "char",
      icon: <PiTextbox className="fieldicon" />,
      technicalName: "x_o2b_text",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "char",
      label: "Password",
      widget: "password",
      icon: <CgPassword className="fieldicon" />,
      technicalName: "x_o2b_password",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "date",
      label: "Date",
      widget: "date",
      icon: <MdDateRange className="fieldicon" />,
      technicalName: "x_o2b_date",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "integer",
      label: "Integer",
      widget: "integer",
      icon: <img src={NumBox} alt="number icon" style={{ width: "25px" }} />, // assuming "NumBox" is an image source
      technicalName: "x_o2b_integer",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "many2many",
      label: "Many2many",
      widget: "many2many_tags",
      icon: <TbRelationManyToMany className="fieldicon" />,
      technicalName: "x_o2b_many2many",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "many2one",
      label: "Many2one",
      widget: "many2one",
      icon: <TbRelationOneToMany className="fieldicon" />,
      technicalName: "x_o2b_many2one",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "one2many",
      label: "One2many",
      widget: "one2many",
      icon: <TbRelationOneToMany className="fieldicon" />,
      technicalName: "x_o2b_one2many",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "boolean",
      label: "Checkbox",
      widget: "boolean",
      icon: <IoMdCheckboxOutline className="fieldicon" />,
      technicalName: "x_o2b_checkbox",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "text",
      label: "Textarea",
      widget: "text",
      icon: <BsTextareaResize className="fieldicon" />,
      technicalName: "x_o2b_textarea",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "char",
      label: "Email",
      widget: "email",
      icon: <AiOutlineMail className="fieldicon" />,
      technicalName: "x_o2b_email",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "selection",
      label: "Selection",
      widget: "selection",
      icon: <HiOutlineSelector className="fieldicon" />,
      technicalName: "x_o2b_selection",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "binary",
      label: "Image",
      widget: "image",
      icon: <FaCamera className="fieldicon" style={{ color: "white" }} />,
      technicalName: "x_o2b_image",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "binary",
      label: "File",
      widget: "file",
      icon: <FaFile style={{ color: "#ffffff", fontSize: "20px" }} />,
      technicalName: "x_o2b_file",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "html",
      label: "Html",
      widget: "html",
      icon: <IoGlobeOutline style={{ fontSize: "20px" }} />,
      technicalName: "x_o2b_html",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "float",
      label: "Decimal",
      widget: "float",
      icon: <TbDecimal style={{ fontSize: "20px" }} />,
      technicalName: "x_o2b_decimal",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "logged_in_user",
      label: "Logged-in User",
      widget: "char",
      icon: <BsPersonFill style={{ fontSize: "20px" }} />,
      technicalName: "logged_in_user",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: true,
      isTracking: true,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "create_uid",
      label: "Created By",
      widget: "char",
      icon: <BsPersonFillAdd style={{ fontSize: "20px" }} />,
      technicalName: "create_uid",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: true,
      isTracking: true,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "create_date",
      label: "Created Date",
      widget: "char",
      icon: <FaUserClock style={{ fontSize: "20px" }} />,
      technicalName: "create_date",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: true,
      isTracking: true,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "write_uid",
      label: "Modified By",
      widget: "char",
      icon: <BsPersonFillCheck style={{ fontSize: "20px" }} />,
      technicalName: "write_uid",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: true,
      isTracking: true,
      isOnChange: false,
      isInvisible: false,
    },
    {
      type: "write_date",
      label: "Modified Date",
      widget: "char",
      icon: <FaUserClock style={{ fontSize: "20px" }} />,
      technicalName: "write_date",
      defaultValue: "",
      placeholder: "",
      tooltip: "",
      isRequired: false,
      isReadOnly: true,
      isTracking: true,
      isOnChange: false,
      isInvisible: false,
    },
  ];

  return (
    <div className="fb_control">
      <h3>{isKanbanBuilder ? "Kanban Panel" : "Form Panel"}</h3>
      {isKanbanBuilder ? (
        <div className="kb_kanban_btn_group">
          <span className="headSpan ms-2">Fields</span>
          <div className="fcontrol_btns">
            {kanabanFieldBtn &&
              kanabanFieldBtn.map((btn, btnIndex) => {
                if (
                  btn.type === "button" ||
                  btn.type === "object" ||
                  btn.type === "action"
                ) {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.type,
                          btn.title,
                          btn.tooltip
                        )
                      }
                    >
                      <MdSmartButton className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "text" || btn.type === "char") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.type,
                          btn.title,
                          btn.tooltip
                        )
                      }
                    >
                      <PiTextbox className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "password") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.type,
                          btn.title,
                          btn.tooltip
                        )
                      }
                    >
                      <CgPassword className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "date") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.type,
                          btn.title,
                          btn.tooltip
                        )
                      }
                    >
                      <MdDateRange className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "number") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.type,
                          btn.title,
                          btn.tooltip
                        )
                      }
                    >
                      <img
                        src={NumBox}
                        alt="number icon"
                        style={{ width: "25px" }}
                      />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "textarea") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.type,
                          btn.title,
                          btn.tooltip
                        )
                      }
                    >
                      <BsTextareaResize className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "email") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleKanbanFieldDragStart(
                          e,
                          btn.type,
                          btn.title,
                          btn.tooltip
                        )
                      }
                    >
                      <AiOutlineMail className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "selection") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleDragStart(e, btn.type, btn.title, btn.tooltip)
                      }
                    >
                      <HiOutlineSelector className="fieldicon" />
                      <span>{btn.title}</span>
                    </button>
                  );
                } else if (btn.type === "binary") {
                  return (
                    <button
                      key={btnIndex}
                      className="fcontrol_btn"
                      draggable
                      onDragStart={(e) =>
                        handleDragStart(e, btn.type, btn.title, btn.tooltip)
                      }
                    >
                      <FaFile style={{ fontSize: "17px", color: "#383838" }} />
                      <span>{btn.title}</span>
                    </button>
                  );
                }
                return null;
              })}
          </div>
        </div>
      ) : (
        <>
          <div className="fb_form_panel">
            <span className="headSpan">Components</span>
            {/* <div className="fcontrol_btns">
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "label",
                    "Label",
                    "label",
                    "",
                    "",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <LuTextCursorInput style={{ fontSize: "20px" }} />
                <span>Label</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "static_image",
                    "Static Image",
                    "static_image",
                    "",
                    "",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    false
                  )
                }
              >
                <TiImage style={{ fontSize: "20px" }} />
                <span>Static Image</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "ribbon",
                    "Ribbon",
                    "ribbon",
                    "",
                    "",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <IoMdRibbon style={{ fontSize: "20px" }} />
                <span>Ribbon</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "button",
                    "Button",
                    "button",
                    "",
                    "x_o2b_button",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <MdSmartButton className="fieldicon" />
                <span>Button</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "object",
                    "Smart Button",
                    "statinfo",
                    "",
                    "x_o2b_smart_button",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <MdSmartButton className="fieldicon" />
                <span>Smart Button</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "group",
                    "Group",
                    "group",
                    "",
                    "",
                    "This is group area",
                    false,
                    false
                  )
                }
              >
                <RxGroup style={{ fontSize: "20px" }} />
                <span>Group</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "tab",
                    "Tab",
                    "tab",
                    "",
                    "",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <TfiLayoutTab style={{ fontSize: "20px" }} />
                <span>Tabs</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "table",
                    "Table",
                    "table",
                    "",
                    "x_o2b_table",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <FaTable style={{ fontSize: "20px" }} />
                <span>Table</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "chatter",
                    "Chatter",
                    "chatter",
                    "",
                    "x_o2b_",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <IoChatbubbles style={{ fontSize: "20px" }} />
                <span>Chatter</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "separator",
                    "Separator",
                    "separator",
                    "",
                    "x_o2b_",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <LuAlignVerticalSpaceAround style={{ fontSize: "20px" }} />
                <span>Separator</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "oe_avatar",
                    "Form Image",
                    "image",
                    "",
                    "x_o2b_form_image",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <FaImage style={{ fontSize: "20px" }} />
                <span>Form Image</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "remark_history",
                    "Remark History",
                    "remark_history",
                    "",
                    "x_o2b_remark_history",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    false
                  )
                }
              >
                <MdOutlineWorkHistory style={{ fontSize: "20px" }} />
                <span>Remark History</span>
              </button>
            </div> */}
            <div className="fcontrol_btns">
              {componentsBtn.map((btn) => (
                <button
                  key={btn.type}
                  className="fcontrol_btn"
                  draggable
                  onDragStart={(e) =>
                    handleDragStart(
                      e,
                      btn.type,
                      btn.label,
                      btn.widget,
                      btn.defaultValue,
                      btn.technicalName,
                      btn.placeholder,
                      btn.tooltip,
                      btn.isRequired,
                      btn.isReadOnly,
                      btn.isTracking,
                      btn.isOnChange,
                      btn.isInvisible
                    )
                  }
                >
                  {btn.icon}
                  <span>{btn.label}</span>
                </button>
              ))}
            </div>
          </div>
          <div className="fb_form_panel">
            <span className="headSpan">Fields</span>
            {/* <div className="fcontrol_btns">
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "char",
                    "Text",
                    "char",
                    "",
                    "x_o2b_text",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <PiTextbox className="fieldicon" />
                <span>Text</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "char",
                    "Password",
                    "password",
                    "",
                    "x_o2b_password",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <CgPassword className="fieldicon" />
                <span>Password</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "date",
                    "Date",
                    "date",
                    "",
                    "x_o2b_date",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <MdDateRange className="fieldicon" />
                <span>Date</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "integer",
                    "Integer",
                    "integer",
                    "",
                    "x_o2b_integer",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <img src={NumBox} alt="number icon" style={{ width: "25px" }} />
                <span>Integer</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "many2many",
                    "Many2many",
                    "many2many_tags",
                    "",
                    "x_o2b_many2many",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <TbRelationManyToMany className="fieldicon" />
                <span>Many2many</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "many2one",
                    "Many2one",
                    "many2one",
                    "",
                    "x_o2b_many2one",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <TbRelationOneToMany className="fieldicon" />
                <span>Many2one</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "one2many",
                    "One2many",
                    "one2many",
                    "",
                    "x_o2b_one2many",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <TbRelationOneToMany className="fieldicon" />
                <span>One2many</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "boolean",
                    "Checkbox",
                    "boolean",
                    "",
                    "x_o2b_checkbox",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <IoMdCheckboxOutline className="fieldicon" />
                <span>Checkbox</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "text",
                    "Textarea",
                    "text",
                    "",
                    "x_o2b_textarea",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <BsTextareaResize className="fieldicon" />
                <span>Textarea</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "char",
                    "Email",
                    "email",
                    "",
                    "x_o2b_email",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <AiOutlineMail className="fieldicon" />
                <span>Email</span>
              </button>

              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "selection",
                    "Selection",
                    "selection",
                    "",
                    "x_o2b_selection",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <HiOutlineSelector className="fieldicon" />
                <span>Selection</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "binary",
                    "Image",
                    "image",
                    "",
                    "x_o2b_image",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <FaCamera className="fieldicon" style={{ color: "white" }} />
                <span>Image</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "binary",
                    "File",
                    "file",
                    "",
                    "x_o2b_file",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <FaFile style={{ color: "#ffffff", fontSize: "20px" }} />
                <span>File</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "monetary",
                    "Monetary",
                    "monetary",
                    "",
                    "x_o2b_monetary",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false
                  )
                }
              >
                <MdOutlineCurrencyRupee style={{ fontSize: "20px" }} />
                <span>Monetary</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "html",
                    "Html",
                    "html",
                    "",
                    "x_o2b_html",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <IoGlobeOutline style={{ fontSize: "20px" }} />
                <span>Html</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "float",
                    "Decimal",
                    "float",
                    "",
                    "x_o2b_decimal",
                    "",
                    "",
                    false,
                    false,
                    false,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <TbDecimal style={{ fontSize: "20px" }} />
                <span>Decimal</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "logged_in_user",
                    "Logged-in User",
                    "char",
                    "",
                    "logged_in_user",
                    "",
                    "",
                    false,
                    true,
                    true,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <BsPersonFill style={{ fontSize: "20px" }} />
                <span>Logged-in User</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "create_uid",
                    "Created By",
                    "char",
                    "",
                    "create_uid",
                    "",
                    "",
                    false,
                    true,
                    true,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <BsPersonFillAdd style={{ fontSize: "20px" }} />
                <span>Created By</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "create_date",
                    "Created Date",
                    "char",
                    "",
                    "create_date",
                    "",
                    "",
                    false,
                    true,
                    true,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <FaUserClock style={{ fontSize: "20px" }} />
                <span>Created Date</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "write_uid",
                    "Modified By",
                    "char",
                    "",
                    "write_uid",
                    "",
                    "",
                    false,
                    true,
                    true,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <BsPersonFillCheck style={{ fontSize: "20px" }} />
                <span>Modified By</span>
              </button>
              <button
                className="fcontrol_btn"
                draggable
                onDragStart={(e) =>
                  handleDragStart(
                    e,
                    "write_date",
                    "Modified By",
                    "char",
                    "",
                    "write_date",
                    "",
                    "",
                    false,
                    true,
                    true,
                    false,
                    // false,
                    // false,
                    false
                  )
                }
              >
                <FaUserClock style={{ fontSize: "20px" }} />
                <span>Modified Date</span>
              </button>
            </div> */}
            <div className="fcontrol_btns">
              {fieldsBtn.map((btn) => (
                <button
                  key={btn.type}
                  className="fcontrol_btn"
                  draggable
                  onDragStart={(e) =>
                    handleDragStart(
                      e,
                      btn.type,
                      btn.label,
                      btn.widget,
                      btn.defaultValue,
                      btn.technicalName,
                      btn.placeholder,
                      btn.tooltip,
                      btn.isRequired,
                      btn.isReadOnly,
                      btn.isTracking,
                      btn.isOnChange,
                      btn.isInvisible
                    )
                  }
                >
                  {btn.icon}
                  <span>{btn.label}</span>
                </button>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FormControl;
