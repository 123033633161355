import React, { useEffect, useState } from "react";
import "./ProcessModelerPage.css";
import { CiMenuFries } from "react-icons/ci";
import { useNodeContext } from "../../context/NodeContext";
import ControlPanel from "./process_modeler_content/ControlPanel";
import Board from "./process_modeler_content/Board";
import ShapeUpdateForm from "./process_modeler_content/ShapeUpdateForm";
import ProcessModelerTopBar from "../../template/process_modeler_top/ProcessModelerTopBar";
import { useProcessContext } from "../../context/ProcessContext";
import Swal from "sweetalert2";
import BASE_URL, { SECRE_KEY } from "../../../config";
import generateOdooFormViewXml from "../../utility_files/generateOdooFormViewXml";
import { useFormContext } from "../../context/FormContext";
import { IoRocketSharp } from "react-icons/io5";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const ProcessModelerPage = () => {
  const {
    nodes,
    edges,
    showShapeUpdateForm,
    shapeId,
    isStartAndEndNode,
    handleArrowCheck,
    handlePrevNextCheck,
    handleAssignedFormCheck,
  } = useNodeContext();
  const {
    newProcessId,
    processDetail,
    setProcessDetail,
    userData,
    centerAlert,
    centerAlertWithTitle,
  } = useProcessContext();
  const { forms, setForms, fetchForms, parseFormData, getMaxTitleLength } =
    useFormContext();
  const navigate = useNavigate();
  const [isControlPanelOpen, setControlPanelOpen] = useState(true);
  const [checkinLoading, setCheckInLoading] = useState(false);
  // const [progress, setProgress] = useState(0);
  const [configurationDetail, setConfigurationDetail] = useState(null);

  // state for process detail object
  const [processObj, setProcessObj] = useState({
    process_id: newProcessId,
    process_group: processDetail ? processDetail.process_group : null,
    process_name: processDetail ? processDetail.process_name : "no name",
    process_detail: processDetail ? processDetail.process_detail : "no details",
    user_id: processDetail ? processDetail.user_id : "123",
    secret_key: SECRE_KEY,
  });
  // const [processObj, setProcessObj] = useState({
  //   process_id: newProcessId,
  //   process_name:
  //     processdata.length > 0 ? processdata[0].process_name : "no name",
  //   process_detail:
  //     processdata.length > 0 ? processdata[0].process_detail : "no details",
  //   user_id: processdata.length > 0 ? processdata[0].user_id : "123",
  //   secret_key: null,
  //   // secret_key: SECRE_KEY,
  // });
  //   useEffect for fetching configuration status
  useEffect(() => {
    const fetchConfigStatus = async () => {
      try {
        const response = await fetch(`${BASE_URL}/config/fetch`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userid: userData.userid }),
        });

        if (response.ok) {
          const data = await response.json();
          // console.log("config response Data:::::::: ", data);
          if (data.configured === "YES") {
            setConfigurationDetail({
              configured: data.configured,
              configDetail: { ...data.config },
            });

            // processObj.secret_key = data?.config?.securitykey;
          }

          // console.log("Data fetched successfully:", data);
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.error("Network error: ", error);
      }
    };

    fetchConfigStatus();
  }, [newProcessId, userData]);

  // state for activities detail object
  const [activitiesObj, setActivitiesObj] = useState([]);

  // useEffect for setting latest value in activity object
  useEffect(() => {
    if (nodes.length > 0) {
      const activitiesTemp = [];
      nodes.forEach((node) => {
        // console.log("formData: ", node.data.form.formbuilder_data);
        // const fields = parseFieldData(node.data.form.formbuilder_data);
        const fields = node.data.form.formbuilder_data;
        const activityName = node.data.value;
        const activityPrevName = node.data.prev_name;
        const processName = processDetail.process_name;
        const allFields = parseFormData(fields);
        const maxTitleLength = getMaxTitleLength(allFields);
        const formView = fields
          ? generateOdooFormViewXml(
              fields,
              activityName,
              activityPrevName,
              processName,
              maxTitleLength
            )
          : null;
        const kanbanData = node.data.kanbanData;
        const activity = {
          activity_id: node.id,
          activity_name: activityName,
          activity_type: node.type,
          activity_group: node.data.group,
          activity_prev: node.data.prev,
          activity_prev_name: node.data.prev_name,
          activity_next: node.data.next,
          activity_next_name: node.data.next_name,
          assigned_form: fields,
          form_view: formView,
          kanban_view: kanbanData,
        };
        if (node.type === "decision") {
          activity.elseNextStep = node.data.elseNextStep;
        }
        activitiesTemp.push(activity);
      });
      setActivitiesObj([...activitiesTemp]);
    }
  }, [nodes]);

  // state for odooFitData
  const [odooFitData, setOdooFitData] = useState();

  // useEffect for setting latest value in odooFitData
  useEffect(() => {
    setOdooFitData({
      process: processObj,
      activities: activitiesObj,
    });
  }, [processObj, activitiesObj]);
  console.log("odooFitData: ", JSON.stringify(odooFitData));

  useEffect(() => {
    setForms([]); // Clear forms before fetching new ones
    if (newProcessId) {
      fetchForms();
    }
  }, [newProcessId, setForms]);

  // method for saving the latest process Data into database
  const handleSaveData = async () => {
    try {
      // const dataFitToProcessUpdate = {
      //   ...{
      //     ...processes.filter((process) => process.process_id === newProcessId)[
      //       "0"
      //     ],
      //   },
      //   process_data_flow: {
      //     nodesData: nodesJsonData,
      //     edgesData: edgesJsonData,
      //   },
      //   process_data_form: JSON.stringify(forms),
      // };
      // setProcessDetail({
      //   ...processDetail,
      //   process_data_flow: {
      //     nodesData: JSON.stringify(nodes),
      //     edgesData: JSON.stringify(edges),
      //   },
      //   process_data_form: JSON.stringify(forms),
      // });
      const response = await fetch(`${BASE_URL}/process/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...processDetail }),
        // body: JSON.stringify({
        //   ...{
        //     ...processes.filter(
        //       (process) => process.process_id === newProcessId
        //     )["0"],
        //   },
        //   process_data_flow: {
        //     nodesData: nodesJsonData,
        //     edgesData: edgesJsonData,
        //   },
        //   process_data_form: JSON.stringify(forms),
        // }),
      });
      if (response.ok) {
        const data = await response.json();
        console.log("response data: ", data);
        // console.log("data fit to process Update:::: ", dataFitToProcessUpdate);
      } else {
        const data = await response.json();
        console.log("error in saving: ", data);
      }
    } catch (error) {
      console.log("error in updating: ", error);
    }
  };

  // Function to update the process status
  const updateProcessStatus = async (status) => {
    const request = {
      process_id: newProcessId,
      user_id: userData.userid,
      status: status,
    };
    console.log("request::::: ", JSON.stringify(request));
    try {
      const response = await fetch(`${BASE_URL}/process/update/status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      if (response.ok) {
        const data = await response.json();
        setProcessDetail({ ...data.updateProcess });
        console.log("process status updated:::::::::::: ", data);
        // const updatedProcesses = processes.map((process) =>
        //   process.process_id === newProcessId
        //     ? { ...process, odoo_checking_status: status }
        //     : process
        // );
        // setProcesses(updatedProcesses);
        // console.log("Process status updated successfully:", data);
      } else {
        const data = await response.json();
        console.log("Error updating process status:", data);
      }
    } catch (error) {
      console.log("Error in updating process status:", error);
    }
  };

  // check in odoo funtion start here
  const handleCheckInOdoo = async () => {
    const edgeCheck = handleArrowCheck();
    if (edgeCheck.status) {
      if (isStartAndEndNode()) {
        const stepCheck = handlePrevNextCheck();
        if (stepCheck.status) {
          const formCheck = handleAssignedFormCheck();
          if (formCheck.status) {
            // start code for giving alert
            await Swal.fire({
              title: "Confirm for Check in Odoo",
              text: "Please confirm if you are ready to check in odoo",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Confirm!",
            }).then((result) => {
              if (result.isConfirmed) {
                const odooCheck = async () => {
                  setCheckInLoading(true);
                  try {
                    const response = await fetch(
                      `${BASE_URL}/process/confirm`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          process_id: newProcessId,
                          is_process_desing_completed: "true",
                          odoo_checking_data: odooFitData,
                        }),
                      }
                    );
                    const data = await response.json();
                    if (response.ok && data.message.code === "200") {
                      // console.log(
                      //   "response data from react data.message",
                      //   data.message
                      // );
                      // Call the function to update the status to true
                      updateProcessStatus(true);
                      setCheckInLoading(false);
                      console.log("response data from react data", data);
                      // alert("nodes and edges data saved successfully...");
                      centerAlertWithTitle(
                        "Successfull!",
                        data.message.message,
                        "success"
                      );
                    } else {
                      // Call the function to update the status to false
                      updateProcessStatus(false);
                      setCheckInLoading(false);
                      console.log("error in deploy", data);
                      centerAlertWithTitle(
                        "Internal Server Error!",
                        data.message.message,
                        "error"
                      );
                      // Swal.fire({
                      //   title: "Internal Server Error!",
                      //   text: data.message.message,
                      //   icon: "error",
                      // });
                    }
                  } catch (error) {
                    // Call the function to update the status to false
                    updateProcessStatus(false);
                    setCheckInLoading(false);
                    console.log("error in updating react: ", error);
                    centerAlertWithTitle(
                      "Internal Server Error!",
                      error,
                      "error"
                    );
                    // Swal.fire({
                    //   title: "Internal Server Error!",
                    //   text: error,
                    //   icon: "error",
                    // });
                  }
                };
                odooCheck();
              }
            });
          } else {
            Swal.fire({
              title: "Form not Assigned",
              text: `Please assign form to ${formCheck.nodeName} activity`,
              icon: "error",
            });
          }
        } else {
          Swal.fire({
            title: "Previous or Next Step is Not Set",
            text: stepCheck.report,
            icon: "error",
          });
        }
      } else {
        Swal.fire({
          title: "Missing Start or End Node",
          text: "It is compulsory to have start and end node",
          icon: "error",
        });
      }
    } else {
      Swal.fire({
        title: "Missing edges between activity",
        text: edgeCheck.report,
        icon: "error",
      });
    }
  };

  // method for checkin button click
  const handleCheckinClick = (e) => {
    e.preventDefault();
    handleSaveData();
    // if (configurationDetail?.configured === "YES") {
    handleCheckInOdoo();
    // } else {
    //   topRightAlert("error", "Please configure your data before deploy");
    // }
  };

  // Function to update the module creation status
  const updateModuleCreateStatus = async (status) => {
    const request = {
      process_id: newProcessId,
      user_id: userData.userid,
      status,
    };
    console.log("request::::: ", request);
    try {
      const response = await fetch(`${BASE_URL}/process/update/module/status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      if (response.ok) {
        const data = await response.json();
        setProcessDetail(data.updateProcess);
        console.log("Module Creation status updated successfully:", data);
      } else {
        const data = await response.json();
        centerAlert("error", data.message);
        // topRightAlert("error", data.message);
        console.log("Error updating process status:", data);
      }
    } catch (error) {
      centerAlert("error", error);
      // topRightAlert("error", error);
      console.log("Error in updating process status:", error);
    }
  };

  // Function to create the module
  const createModule = async () => {
    try {
      const response = await fetch(`${BASE_URL}/process/create/module`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(processObj),
      });

      if (response.ok) {
        const data = await response.json();
        setCheckInLoading(false);
        // topRightAlert("success", data.message);
        centerAlert("success", data.message);

        console.log("Module Created successfully:", data);
        updateModuleCreateStatus(true);
      } else {
        const data = await response.json();
        updateModuleCreateStatus(false);
        setCheckInLoading(false);
        console.log("Error creating module:", data);
        centerAlert("error", data.message);
        // topRightAlert("error", data.message);
      }
    } catch (error) {
      updateModuleCreateStatus(false);
      setCheckInLoading(false);
      centerAlert("error", error);
      // topRightAlert("error", error);
      console.log("Error in creating process module:", error);
    }
  };

  // method for module create button click
  const handleModuleCreateClick = (e) => {
    e.preventDefault();
    setCheckInLoading(true);
    handleSaveData();
    // if (configurationDetail?.configured === "YES") {
    createModule();
    // } else {
    //   topRightAlert("error", "Please configure your data before deploy");
    // }
  };

  useEffect(() => {
    setProcessDetail({
      ...processDetail,
      process_data_flow: {
        nodesData: JSON.stringify(nodes),
        edgesData: JSON.stringify(edges),
      },
      process_data_form: JSON.stringify(forms),
    });
    handleSaveData();
  }, [nodes, edges, newProcessId, forms]);

  if (newProcessId === null || newProcessId === "") {
    navigate("/dashboard/processes");
  }

  // useEffect for auto submit the flow data
  // useEffect(() => {
  //   setProcessDetail({
  //     ...processDetail,
  //     process_data_flow: {
  //       nodesData: JSON.stringify(nodes),
  //       edgesData: JSON.stringify(edges),
  //     },
  //     process_data_form: JSON.stringify(forms),
  //   });
  //   const intervalId = setInterval(handleSaveData, 4000); // Execute every 4 seconds

  //   return () => clearInterval(intervalId); // Clean up the interval on component unmount
  // }, []);
  // }, [newProcessId, nodesJsonData, edgesJsonData, forms]);

  // console.log("configurationDetail::::: ", configurationDetail);
  // console.log("processObj::::: ", processObj);
  // console.log("processDetail nodes::::: ", JSON.parse(processDetail?.process_data_flow?.nodesData));
  console.log("processDetail::::: ", processDetail);
  console.log("nodes:::: ", nodes);
  // console.log("nodes data:::::::::::::::: ", JSON.stringify(nodes));
  console.log("newProcessId::::: ", newProcessId);
  // console.log("nodesJsonData:::: ", nodesJsonData);

  return (
    <div className="process-moeler-page">
      {checkinLoading && (
        <div className="progress_bar_checkin">
          {/* Loading */}
          <h3>Processing...</h3>
          {/* <ProgressBar progress={progress} /> */}
          <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#1a7e8f"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}

      {processDetail?.is_module_created ? (
        <button
          className="btn pm_process_save_btn"
          onClick={handleCheckinClick}
        >
          <IoRocketSharp style={{ fontSize: "20px" }} />
          Deploy
        </button>
      ) : (
        <button
          className="btn pm_process_save_btn"
          onClick={handleModuleCreateClick}
        >
          {/* <IoRocketSharp style={{ color: "white", fontSize: "20px" }} /> */}
          Check In
        </button>
      )}
      {newProcessId && <ProcessModelerTopBar />}
      {isControlPanelOpen ? (
        <ControlPanel setControlPanelOpen={setControlPanelOpen} />
      ) : (
        <div className="menu_cp">
          <CiMenuFries
            style={{ fontSize: "40px" }}
            onClick={() => setControlPanelOpen(true)}
          />
        </div>
      )}
      <Board />
      {shapeId !== null ? showShapeUpdateForm && <ShapeUpdateForm /> : ""}
    </div>
  );
};

export default ProcessModelerPage;
