import React, { useEffect, useState } from "react";
import BASE_URL from "../../../config";
import { useProcessContext } from "../../context/ProcessContext";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNodeContext } from "../../context/NodeContext";
import { useNavigate } from "react-router-dom";
import ProcessOverlay from "../../template/process_modeler_overlay/ProcessOverlay";
import ImportProcessOverlay from "../../template/import_process_overlay/ImportProcessOverlay";

const Processes = () => {
  const {
    userData,
    processes,
    setProcesses,
    setProcessDetail,
    setNewProcessId,
    topRightAlert,
    centerAlert,
    fetchAllProcessData,
  } = useProcessContext();
  const { setNodes, setEdges, initialNodes, setShapeId } = useNodeContext();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // fetch all process data
  useEffect(() => {
    fetchAllProcessData();
    setLoading(false);
  }, [setProcesses, userData.userid]);

  const handleDelete = async (processId) => {
    try {
      const response = await fetch(`${BASE_URL}/process/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userData.userid,
          process_id: processId,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log("delete process: ", data);
        topRightAlert("success", data.message);
        // Filter out the deleted process from the state
        // setProcesses((prevProcesses) =>
        //   prevProcesses.filter((process) => process.process_id !== processId)
        // );
        fetchAllProcessData();
      } else {
        const data = await response.json();
        topRightAlert("error", data.message);
        console.error("Error deleting process:", data);
      }
    } catch (error) {
      topRightAlert("error", "Internal Server Error");
      console.error("Error deleting process:", error);
    }
  };
  const handleEdit = async (processId) => {
    if (processId !== null) {
      try {
        const response = await fetch(`${BASE_URL}/process/fetch`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            process_id: processId,
            user_id: userData.userid,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          // console.log("Data ProcessDetail:::::::: ", data);
          // fetchAllProcessData();
          const nodesData = data.processes.process_data_flow.nodesData;
          const edgesData = data.processes.process_data_flow.edgesData;
          console.log("flowData::::: ", nodesData);
          setProcessDetail(data.processes);
          setNodes(
            data.processes.process_data_flow.nodesData
              ? JSON.parse(nodesData)
              : initialNodes
          );
          setEdges(
            data.processes?.process_data_flow.edgesData
              ? JSON.parse(edgesData)
              : []
          );
          setNewProcessId(processId);
          setShapeId(null);
          navigate("/process-modeler");
        } else {
          const data = await response.json();
          console.error("Error updating process status:", data);
        }
      } catch (error) {
        console.error("Error updating process status:", error);
      }
    }
  };
  // Handle the status change for dynamically generated checkboxes
  const handleStatusChange = async (processId, newStatus) => {
    try {
      const response = await fetch(`${BASE_URL}/process/update/status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          process_id: processId,
          user_id: userData.userid,
          status: newStatus,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Process status changed: ", data);
        fetchAllProcessData();
        centerAlert("success", data.message);
      } else {
        const data = await response.json();
        console.error("Error updating process status:", data);
        centerAlert("error", data.message);
      }
    } catch (error) {
      console.error("Error updating process status:", error);
      centerAlert("error", error);
    }
  };
  if (loading) return <span>Loading....</span>;
  return (
    <div className="donor_dash_right">
      <div className="dashboard_buttons">
        <button data-bs-toggle="modal" data-bs-target="#exampleModal">
          New Process
        </button>
        <button data-bs-toggle="modal" data-bs-target="#processImportModal">
          Import Process
        </button>
      </div>
      {/* Modal */}
      <ProcessOverlay />
      <ImportProcessOverlay />
      {/* over modal code */}
      <div className="donor_dash_deatil">
        <div className="donor_kit">
          <h2 className="table_caption_head">List of existing processes</h2>
          <div className="donor_kit_desc" />
          <div className="donor_table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Process Id</th>
                  <th>Process Name</th>
                  <th>Process Detail</th>
                  <th>Hutch</th>
                  <th>Published</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {processes.length > 0 ? (
                  processes.map((process, index) => (
                    <tr key={process.process_id}>
                      <th>{index + 1}</th>
                      <td>{process.process_id}</td>
                      <td>{process.process_name}</td>
                      <td>{process.process_detail}</td>
                      <td>{process.database}</td>
                      <td>
                        <div
                          className="form-check form-switch"
                          style={{ paddingLeft: "3.5rem" }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheckDefault-${process.process_id}`}
                            checked={process.odoo_checking_status}
                            onChange={(e) =>
                              handleStatusChange(
                                process.process_id,
                                e.target.checked
                              )
                            }
                          />
                        </div>
                      </td>
                      <td className="td_action_cell">
                        <CiEdit
                          className="dash_table_icon_edit"
                          onClick={() => handleEdit(process.process_id)}
                        />
                        <RiDeleteBin5Line
                          className="dash_table_icon_del"
                          onClick={() => handleDelete(process.process_id)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr key={userData.userid}>
                    <td colSpan={7} className="text-center ">
                      No existing processes...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Processes;
