import React from "react";
import "./controlpanel.css";
import { BsDiamond, BsShop } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { LuRectangleHorizontal } from "react-icons/lu";
import { FaRegCircle as EndCircle } from "react-icons/fa6";
import { GiCircle } from "react-icons/gi";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { MdBlock } from "react-icons/md";
import DCircle from "./concentric-circles_btn.svg";
import { CgCloseO } from "react-icons/cg";
import { IoCloudCircleOutline } from "react-icons/io5";
import { AiOutlineException } from "react-icons/ai";
import { HiUserPlus } from "react-icons/hi2";
import { FaFileInvoice } from "react-icons/fa";

function ControlPanel({ setControlPanelOpen }) {
  const onDragStart = (e, nodeType) => {
    e.dataTransfer.setData("activityType/oflow", nodeType);
    e.dataTransfer.effectAllowed = "move";
  };

  return (
    <div className="control-panel">
      <button
        className="close_panel_button"
        onClick={() => setControlPanelOpen(false)}
      >
        <IoMdClose style={{ fontSize: "30px" }} />
      </button>
      <h5>Control Panel</h5>
      <div className="cp_activity_group">
        <span className="cp_event_box">Start Events</span>
        <div className="control_btn">
          <button
            onDragStart={(e) => onDragStart(e, "start")}
            title="start"
            draggable
          >
            <GiCircle style={{ fontSize: "25px" }} />
            <span>start</span>
          </button>
          <button
            // onDragStart={(e) => onDragStart(e, "webform")}
            // title="webform"
            title="Coming Soon Feature"
            // draggable
            style={{ cursor: "not-allowed" }}
          >
            <IoCloudCircleOutline style={{ fontSize: "25px" }} />
            <span>webform</span>
          </button>
        </div>
      </div>
      <div className="cp_activity_group">
        <span className="cp_event_box">Activities</span>
        <div className="control_btn">
          <button
            onDragStart={(e) => onDragStart(e, "task")}
            title="task"
            draggable
          >
            <LuRectangleHorizontal style={{ fontSize: "25px" }} />
            <span>task</span>
          </button>
        </div>
      </div>
      <div className="cp_activity_group">
        <span className="cp_event_box">Intermediate</span>
        <div className="control_btn">
          <button
            // onDragStart={(e) => onDragStart(e, "intermediate")}
            // title="intermediate event"
            title="Coming Soon Feature"
            // draggable
            style={{ cursor: "not-allowed" }}
          >
            <img
              src={DCircle}
              alt="concentric circle"
              style={{ width: "25px" }}
            />
            <span>intermediate</span>
          </button>
        </div>
      </div>
      <div className="cp_activity_group">
        <span className="cp_event_box">Gateways</span>
        <div className="control_btn">
          <button
            onDragStart={(e) => onDragStart(e, "decision")}
            title="decision"
            draggable
          >
            <BsDiamond style={{ fontSize: "25px" }} />
            <span>decision</span>
          </button>
          <button
            // onDragStart={(e) => onDragStart(e, "scheduler")}
            // title="scheduler"
            title="Coming Soon Feature"
            // draggable
            style={{ cursor: "not-allowed" }}
          >
            <RiCalendarScheduleLine style={{ fontSize: "25px" }} />
            <span>scheduler</span>
          </button>
        </div>
      </div>
      <div className="cp_activity_group">
        <span className="cp_event_box">End Events</span>
        <div className="control_btn">
          <button
            onDragStart={(e) => onDragStart(e, "end")}
            title="end"
            draggable
          >
            <EndCircle style={{ fontSize: "25px" }} />
            <span>complete</span>
          </button>
          <button
            onDragStart={(e) => onDragStart(e, "discard")}
            title="discard"
            draggable
          >
            <CgCloseO style={{ fontSize: "26px" }} />
            <span>discard</span>
          </button>
          <button
            onDragStart={(e) => onDragStart(e, "reject")}
            title="reject"
            draggable
          >
            <MdBlock style={{ fontSize: "27px" }} />
            <span>reject</span>
          </button>
          <button
            onDragStart={(e) => onDragStart(e, "exception")}
            title="exception"
            draggable
          >
            <AiOutlineException style={{ fontSize: "27px" }} />
            <span>exception</span>
          </button>
        </div>
      </div>
      {/* <div className="cp_activity_group">
        <span className="cp_event_box">Odoo Functions</span>
        <div className="control_btn">
          <button
            onDragStart={(e) => onDragStart(e, "customer")}
            title="create customer"
            draggable
          >
            <HiUserPlus style={{ fontSize: "25px" }} />
            <span>create customer</span>
          </button>
          <button
            onDragStart={(e) => onDragStart(e, "vendor")}
            title="create vendor"
            draggable
          >
            <BsShop style={{ fontSize: "26px" }} />
            <span>create vendor</span>
          </button>
          <button
            onDragStart={(e) => onDragStart(e, "invoice")}
            title="create invoice"
            draggable
          >
            <FaFileInvoice style={{ fontSize: "27px" }} />
            <span>create invoice</span>
          </button>
        </div>
      </div> */}
    </div>
  );
}

export default ControlPanel;
