import React, { useState, useEffect, memo } from "react";
import Select from "react-select";
import "./FormDetailUpdate.css";
import { CiEdit } from "react-icons/ci";
import {
  MdDeleteOutline,
  MdOutlineCheck,
  MdOutlineClose,
} from "react-icons/md";
import { useFormContext } from "../../../context/FormContext";
import { useNavigate } from "react-router-dom";
import { GiCancel } from "react-icons/gi";
import BASE_URL, { API_KEY } from "../../../../config";
import { useProcessContext } from "../../../context/ProcessContext";
import DomainComponent from "../../../template/domain_component/DomainComponent";
import { TbPointFilled } from "react-icons/tb";
import CustomDropdown from "../../../template/custom_dropdown/CustomDropdown";
import { TfiHandDrag } from "react-icons/tfi";
import { RxDragHandleDots2 } from "react-icons/rx";

// Custom styles for react-select
const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    height: "30px",
  }),
  control: (provided) => ({
    ...provided,
    width: "100%",
    minHeight: "25px",
    outline: "none",
    color: "rgb(95, 95, 95)",
    border: "1px solid #ddd",
    padding: "0px 5px",
    borderRadius: "5px",
    fontSize: "14px",
    backgroundColor: "white",
  }),
  menu: (provided) => ({
    ...provided,
    width: "100%",
    borderRadius: "5px",
    zIndex: 9999,
  }),
  option: (provided, state) => ({
    ...provided,
    width: "100%",
    padding: "2px 2px 2px 10px",
    fontSize: "14px",
    backgroundColor: state.isFocused ? "#007485" : "white",
    color: state.isFocused ? "white" : "black",
    "&:hover": {
      backgroundColor: "#007485",
    },
  }),
};

const FormDetailUpdate = () => {
  const {
    forms,
    formData,
    setFormData,
    showFieldDetail,
    setShowFieldDetail,
    setSelectedFieldData,
    selectedFieldData,
    buttonData,
    setButtonData,
    setPythonCode,
    parseFormData,
  } = useFormContext();
  const { groups, topRightAlert, newProcessId, fetchAllGroups, processDetail } =
    useProcessContext();
  const [updateFieldData, setUpdateFieldData] = useState({
    title: "",
    technicalName: "x_o2b_",
    type: "",
    widget: "",
    defaultValue: "",
    options: null,
    odooButtonColor: "",
    placeholder: "",
    tooltip: "",
    relatedModel: "",
    relationTable: "",
    relationField: "",
    groupVisible: [],
    pythonCode: null,
    isRequired: false,
    isReadOnly: false,
    isTracking: false,
    isCreatedBy: false,
    isLoggedinUser: false,
    isOnChange: false,
    isInvisible: false,
  });

  const navigate = useNavigate();
  const [odooModels, setOdooModels] = useState(null);
  const [selectedModalFields, setSelectedModalFields] = useState(null);
  const [filteredRelatedField, setFilteredRelatedField] = useState(null);

  // Array data for all input types
  const inputTypes =
    updateFieldData.type === "button" ||
    updateFieldData.type === "object" ||
    updateFieldData.type === "action"
      ? ["object", "action"]
      : [
          "text",
          "char",
          "date",
          "datetime",
          "binary",
          "boolean",
          "float",
          "html",
          "integer",
          "json",
          "many2many",
          "many2one",
          "many2one_reference",
          "monetary",
          "one2many",
          "reference",
          "create_uid",
          "create_date",
          "write_uid",
          "write_date",
          "logged_in_user",
          "static_image",
          "remark_history",
        ];

  // Array data for all widgets
  const widgets = [
    "char",
    "text",
    "integer",
    "float",
    "monetary",
    "boolean",
    "selection",
    "many2one",
    "many2many",
    "many2many_tags",
    "many2many_checkboxes",
    "one2many",
    "date",
    "datetime",
    "time",
    "binary",
    "image",
    "file",
    "url",
    "phone",
    "email",
    "password",
    "html",
    "priority",
    "percentage",
    "handle",
    "radio",
    "statinfo",
    "statusbar",
    "progressbar",
    "color",
    "rating",
    "kanban_state_selection",
    "html_frame",
    "document",
    "static_image",
    "remark_history",
    "remark_field",
  ];
  // Array data for button class names
  const odooButtonColors = [
    "btn-primary",
    "btn-secondary",
    "btn-success",
    "btn-danger",
    "btn-warning",
    "btn-info",
    "btn-light",
    "btn-dark",
    "btn-outline-primary",
    "btn-outline-secondary",
    "btn-outline-success",
    "btn-outline-danger",
    "btn-outline-warning",
    "btn-outline-info",
    "btn-outline-light",
    "btn-outline-dark",
  ];
  // Array data for table field type
  const tableFieldTypes = [
    "char",
    "text",
    "date",
    "datetime",
    "boolean",
    "float",
    "integer",
  ];
  // icons for odoo smart button
  const odooIcons = [
    "fa-folder-open",
    "fa-users",
    "fa-shopping-cart",
    "fa-check-circle",
    "fa-pencil",
    "fa-trash",
    "fa-save",
    "fa-download",
    "fa-upload",
    "fa-file",
    "fa-file-o",
    "fa-folder",
    "fa-cog",
    "fa-wrench",
    "fa-money",
    "fa-list",
    "fa-book",
    "fa-search",
    "fa-envelope",
    "fa-comment",
    "fa-comments",
    "fa-phone",
    "fa-star",
    "fa-star-o",
    "fa-heart",
    "fa-heart-o",
    "fa-calendar",
    "fa-calendar-o",
    "fa-clock-o",
    "fa-bell",
    "fa-bell-o",
    "fa-info-circle",
    "fa-exclamation-circle",
    "fa-question-circle",
    "fa-eye",
    "fa-eye-slash",
    "fa-user",
    "fa-user-o",
    "fa-user-plus",
    "fa-user-times",
    "fa-users",
    "fa-key",
    "fa-lock",
    "fa-unlock",
    "fa-shield",
    "fa-tasks",
    "fa-bolt",
    "fa-flag",
    "fa-flag-o",
    "fa-map-marker",
    "fa-globe",
    "fa-bar-chart",
    "fa-line-chart",
    "fa-pie-chart",
    "fa-cloud",
    "fa-cloud-download",
    "fa-cloud-upload",
    "fa-print",
    "fa-pencil-square-o",
    "fa-check-square-o",
    "fa-square",
    "fa-square-o",
    "fa-circle",
    "fa-circle-o",
    "fa-refresh",
    "fa-repeat",
    "fa-undo",
    "fa-reply",
    "fa-paperclip",
    "fa-camera",
    "fa-video-camera",
    "fa-play",
    "fa-pause",
    "fa-stop",
    "fa-forward",
    "fa-backward",
    "fa-fast-forward",
    "fa-fast-backward",
    "fa-caret-right",
    "fa-caret-left",
    "fa-caret-up",
    "fa-caret-down",
    "fa-angle-right",
    "fa-angle-left",
    "fa-angle-up",
    "fa-angle-down",
    "fa-plus",
    "fa-minus",
    "fa-plus-circle",
    "fa-minus-circle",
    "fa-check",
    "fa-times",
    "fa-close",
    "fa-remove",
    "fa-share",
    "fa-share-alt",
    "fa-arrows",
    "fa-arrows-h",
    "fa-arrows-v",
    "fa-long-arrow-right",
    "fa-long-arrow-left",
    "fa-long-arrow-up",
    "fa-long-arrow-down",
    "fa-arrow-right",
    "fa-arrow-left",
    "fa-arrow-up",
    "fa-arrow-down",
    "fa-sort",
    "fa-sort-asc",
    "fa-sort-desc",
    "fa-thumbs-up",
    "fa-thumbs-down",
    "fa-external-link",
    "fa-external-link-square",
    "fa-link",
    "fa-chain",
    "fa-cut",
    "fa-scissors",
    "fa-copy",
    "fa-files-o",
    "fa-file-text",
    "fa-file-text-o",
    "fa-indent",
    "fa-outdent",
    "fa-align-left",
    "fa-align-right",
    "fa-align-center",
    "fa-align-justify",
    "fa-bold",
    "fa-italic",
    "fa-underline",
    "fa-strikethrough",
    "fa-code",
    "fa-terminal",
    "fa-bug",
    "fa-database",
    "fa-code-fork",
    "fa-desktop",
    "fa-laptop",
    "fa-tablet",
    "fa-mobile",
    "fa-mobile-phone",
    "fa-folder",
    "fa-folder-open",
    "fa-archive",
    "fa-cutlery",
    "fa-building",
    "fa-home",
    "fa-institution",
    "fa-bank",
    "fa-university",
    "fa-graduation-cap",
    "fa-mortar-board",
    "fa-coffee",
    "fa-book",
    "fa-briefcase",
    "fa-car",
    "fa-road",
    "fa-truck",
    "fa-plane",
    "fa-ship",
    "fa-power-off",
    "fa-signal",
    "fa-gears",
    "fa-cogs",
    "fa-gear",
    "fa-cog",
    "fa-wrench",
    "fa-hammer",
    "fa-shield",
    "fa-bell",
    "fa-bell-o",
    "fa-gift",
    "fa-leaf",
    "fa-fire",
    "fa-eye",
    "fa-eye-slash",
    "fa-binoculars",
    "fa-trash-o",
    "fa-recycle",
    "fa-user-secret",
    "fa-rocket",
    "fa-anchor",
    "fa-puzzle-piece",
    "fa-plug",
    "fa-certificate",
    "fa-sitemap",
    "fa-heartbeat",
    "fa-stethoscope",
    "fa-hospital-o",
    "fa-medkit",
    "fa-wheelchair",
    "fa-ambulance",
    "fa-bed",
    "fa-bookmark",
    "fa-bookmark-o",
    "fa-clipboard",
    "fa-suitcase",
    "fa-cut",
    "fa-copy",
    "fa-paste",
    "fa-puzzle-piece",
    "fa-life-ring",
    "fa-anchor",
    "fa-bell",
    "fa-bell-o",
    "fa-bullhorn",
    "fa-bullseye",
    "fa-calendar",
    "fa-calendar-o",
    "fa-calendar-check-o",
    "fa-calendar-minus-o",
    "fa-calendar-plus-o",
    "fa-calendar-times-o",
    "fa-camera",
    "fa-camera-retro",
    "fa-car",
    "fa-caret-square-o-down",
    "fa-caret-square-o-left",
    "fa-caret-square-o-right",
    "fa-caret-square-o-up",
    "fa-cart-arrow-down",
    "fa-cart-plus",
    "fa-certificate",
    "fa-check-circle",
    "fa-check-square",
    "fa-check-square-o",
    "fa-circle-o",
    "fa-circle-o-notch",
    "fa-circle-thin",
    "fa-clipboard",
    "fa-clock-o",
    "fa-clone",
    "fa-cloud",
    "fa-cloud-download",
    "fa-cloud-upload",
    "fa-code",
    "fa-code-fork",
    "fa-coffee",
    "fa-cog",
    "fa-cogs",
    "fa-columns",
    "fa-comment",
    "fa-comment-o",
    "fa-comments",
    "fa-comments-o",
    "fa-compass",
    "fa-copyright",
    "fa-credit-card",
    "fa-crop",
    "fa-crosshairs",
    "fa-cube",
    "fa-cubes",
    "fa-cutlery",
    "fa-dashboard",
    "fa-database",
    "fa-desktop",
    "fa-download",
    "fa-edit",
    "fa-ellipsis-h",
    "fa-ellipsis-v",
    "fa-envelope",
    "fa-envelope-o",
    "fa-exclamation",
    "fa-exclamation-circle",
    "fa-exclamation-triangle",
    "fa-external-link",
    "fa-external-link-square",
    "fa-eye",
    "fa-eye-slash",
    "fa-facebook",
    "fa-facebook-f",
    "fa-facebook-official",
    "fa-facebook-square",
    "fa-fast-backward",
    "fa-fast-forward",
    "fa-fax",
    "fa-feed",
    "fa-female",
    "fa-fighter-jet",
    "fa-file",
    "fa-file-archive-o",
    "fa-file-audio-o",
    "fa-file-code-o",
    "fa-file-excel-o",
    "fa-file-image-o",
    "fa-file-movie-o",
    "fa-file-o",
    "fa-file-pdf-o",
    "fa-file-photo-o",
    "fa-file-picture-o",
    "fa-file-powerpoint-o",
    "fa-file-sound-o",
    "fa-file-text",
    "fa-file-text-o",
    "fa-file-video-o",
    "fa-file-word-o",
    "fa-files-o",
    "fa-film",
    "fa-filter",
    "fa-fire",
    "fa-fire-extinguisher",
    "fa-flag",
    "fa-flag-checkered",
    "fa-flag-o",
    "fa-flash",
    "fa-flask",
    "fa-folder",
    "fa-folder-o",
    "fa-folder-open",
    "fa-folder-open-o",
    "fa-font",
    "fa-font-awesome",
    "fa-forward",
    "fa-frown-o",
    "fa-futbol-o",
    "fa-gamepad",
    "fa-gavel",
    "fa-gear",
    "fa-gears",
    "fa-gift",
    "fa-git",
    "fa-git-square",
    "fa-github",
    "fa-github-alt",
    "fa-github-square",
    "fa-glass",
    "fa-globe",
    "fa-google",
    "fa-google-plus",
    "fa-google-plus-square",
    "fa-google-wallet",
    "fa-graduation-cap",
    "fa-group",
    "fa-h-square",
    "fa-hacker-news",
    "fa-hand-o-down",
    "fa-hand-o-left",
    "fa-hand-o-right",
    "fa-hand-o-up",
    "fa-hdd-o",
    "fa-header",
    "fa-headphones",
    "fa-heart",
    "fa-heart-o",
    "fa-heartbeat",
    "fa-history",
    "fa-home",
    "fa-hospital-o",
    "fa-hotel",
    "fa-hourglass",
    "fa-hourglass-1",
    "fa-hourglass-2",
    "fa-hourglass-3",
    "fa-hourglass-end",
    "fa-hourglass-half",
    "fa-hourglass-o",
    "fa-hourglass-start",
    "fa-i-cursor",
    "fa-id-badge",
    "fa-id-card",
    "fa-id-card-o",
    "fa-image",
    "fa-inbox",
    "fa-indent",
    "fa-industry",
    "fa-info",
    "fa-info-circle",
    "fa-institution",
    "fa-italic",
    "fa-joomla",
    "fa-jpy",
    "fa-key",
    "fa-keyboard-o",
    "fa-language",
    "fa-laptop",
    "fa-lastfm",
    "fa-lastfm-square",
    "fa-leaf",
    "fa-leanpub",
    "fa-legal",
    "fa-lemon-o",
    "fa-level-down",
    "fa-level-up",
    "fa-life-bouy",
    "fa-life-ring",
    "fa-life-saver",
    "fa-lightbulb-o",
    "fa-line-chart",
    "fa-link",
    "fa-linkedin",
    "fa-linkedin-square",
    "fa-linux",
    "fa-list",
    "fa-list-alt",
    "fa-list-ol",
    "fa-list-ul",
    "fa-location-arrow",
    "fa-lock",
    "fa-long-arrow-down",
    "fa-long-arrow-left",
    "fa-long-arrow-right",
    "fa-long-arrow-up",
    "fa-magic",
    "fa-magnet",
    "fa-mail-forward",
    "fa-mail-reply",
    "fa-mail-reply-all",
    "fa-male",
    "fa-map",
    "fa-map-marker",
    "fa-map-o",
    "fa-map-pin",
    "fa-map-signs",
    "fa-mars",
    "fa-mars-double",
    "fa-mars-stroke",
    "fa-mars-stroke-h",
    "fa-mars-stroke-v",
    "fa-maxcdn",
    "fa-meanpath",
    "fa-medium",
    "fa-medkit",
    "fa-meh-o",
    "fa-mercury",
    "fa-microphone",
    "fa-microphone-slash",
    "fa-minus",
    "fa-minus-circle",
    "fa-minus-square",
    "fa-minus-square-o",
    "fa-mixcloud",
    "fa-mobile",
    "fa-modx",
    "fa-money",
    "fa-moon-o",
    "fa-mortar-board",
    "fa-motorcycle",
    "fa-mouse-pointer",
    "fa-music",
    "fa-navicon",
    "fa-neuter",
    "fa-newspaper-o",
    "fa-object-group",
    "fa-object-ungroup",
    "fa-odnoklassniki",
    "fa-odnoklassniki-square",
    "fa-opencart",
    "fa-openid",
    "fa-opera",
    "fa-optin-monster",
    "fa-outdent",
    "fa-pagelines",
    "fa-paint-brush",
    "fa-paper-plane",
    "fa-paper-plane-o",
    "fa-paperclip",
    "fa-paragraph",
    "fa-paste",
    "fa-pause",
    "fa-pause-circle",
    "fa-pause-circle-o",
    "fa-paw",
    "fa-paypal",
    "fa-pencil",
    "fa-pencil-square",
    "fa-pencil-square-o",
    "fa-percent",
    "fa-phone",
    "fa-phone-square",
    "fa-picture-o",
    "fa-pie-chart",
    "fa-pied-piper",
    "fa-pied-piper-alt",
    "fa-pinterest",
    "fa-pinterest-p",
    "fa-pinterest-square",
    "fa-plane",
    "fa-play",
    "fa-play-circle",
    "fa-play-circle-o",
    "fa-plug",
    "fa-plus",
    "fa-plus-circle",
    "fa-plus-square",
    "fa-plus-square-o",
    "fa-podcast",
    "fa-power-off",
    "fa-print",
    "fa-product-hunt",
    "fa-puzzle-piece",
    "fa-qq",
    "fa-qrcode",
    "fa-question",
    "fa-question-circle",
    "fa-question-circle-o",
    "fa-quote-left",
    "fa-quote-right",
    "fa-ra",
    "fa-random",
    "fa-rebel",
    "fa-recycle",
    "fa-reddit",
    "fa-reddit-alien",
    "fa-reddit-square",
    "fa-refresh",
    "fa-registered",
    "fa-remove",
    "fa-renren",
    "fa-reorder",
    "fa-repeat",
    "fa-reply",
    "fa-reply-all",
    "fa-retweet",
    "fa-road",
    "fa-rocket",
    "fa-rss",
    "fa-rss-square",
    "fa-rub",
    "fa-ruble",
    "fa-rupee",
    "fa-safari",
    "fa-save",
    "fa-scissors",
    "fa-scribd",
    "fa-search",
    "fa-search-minus",
    "fa-search-plus",
    "fa-sellsy",
    "fa-send",
    "fa-send-o",
    "fa-server",
    "fa-share",
    "fa-share-alt",
    "fa-share-alt-square",
    "fa-share-square",
    "fa-share-square-o",
    "fa-shield",
    "fa-ship",
    "fa-shirtsinbulk",
    "fa-shopping-bag",
    "fa-shopping-basket",
    "fa-shopping-cart",
    "fa-sign-in",
    "fa-sign-out",
    "fa-signal",
    "fa-simplybuilt",
    "fa-sitemap",
    "fa-skyatlas",
    "fa-skype",
    "fa-slack",
    "fa-sliders",
    "fa-slideshare",
    "fa-smile-o",
    "fa-soccer-ball-o",
    "fa-sort",
    "fa-sort-alpha-asc",
    "fa-sort-alpha-desc",
    "fa-sort-amount-asc",
    "fa-sort-amount-desc",
    "fa-sort-asc",
    "fa-sort-desc",
    "fa-sort-numeric-asc",
    "fa-sort-numeric-desc",
    "fa-soundcloud",
    "fa-space-shuttle",
    "fa-spinner",
    "fa-spoon",
    "fa-spotify",
    "fa-square",
    "fa-square-o",
    "fa-stack-exchange",
    "fa-stack-overflow",
    "fa-star",
    "fa-star-half",
    "fa-star-half-empty",
    "fa-star-half-full",
    "fa-star-half-o",
    "fa-star-o",
    "fa-steam",
    "fa-steam-square",
    "fa-step-backward",
    "fa-step-forward",
    "fa-stethoscope",
    "fa-sticky-note",
    "fa-sticky-note-o",
    "fa-stop",
    "fa-stop-circle",
    "fa-stop-circle-o",
    "fa-street-view",
    "fa-strikethrough",
    "fa-stumbleupon",
    "fa-stumbleupon-circle",
    "fa-subscript",
    "fa-subway",
    "fa-suitcase",
    "fa-sun-o",
    "fa-superpowers",
    "fa-superscript",
    "fa-support",
    "fa-table",
    "fa-tablet",
    "fa-tachometer",
    "fa-tag",
    "fa-tags",
    "fa-tasks",
    "fa-taxi",
    "fa-telegram",
    "fa-television",
    "fa-tencent-weibo",
    "fa-terminal",
    "fa-text-height",
    "fa-text-width",
    "fa-th",
    "fa-th-large",
    "fa-th-list",
    "fa-themeisle",
    "fa-thumb-tack",
    "fa-thumbs-down",
    "fa-thumbs-o-down",
    "fa-thumbs-o-up",
    "fa-thumbs-up",
    "fa-ticket",
    "fa-times",
    "fa-times-circle",
    "fa-times-circle-o",
    "fa-tint",
    "fa-toggle-down",
    "fa-toggle-left",
    "fa-toggle-off",
    "fa-toggle-on",
    "fa-toggle-right",
    "fa-toggle-up",
    "fa-trademark",
    "fa-train",
    "fa-transgender",
    "fa-transgender-alt",
    "fa-trash",
    "fa-trash-o",
    "fa-tree",
    "fa-trello",
    "fa-tripadvisor",
    "fa-trophy",
    "fa-truck",
    "fa-try",
    "fa-tty",
    "fa-tumblr",
    "fa-tumblr-square",
    "fa-turkish-lira",
    "fa-tv",
    "fa-twitch",
    "fa-twitter",
    "fa-twitter-square",
    "fa-umbrella",
    "fa-underline",
    "fa-undo",
    "fa-university",
    "fa-unlink",
    "fa-unlock",
    "fa-unlock-alt",
    "fa-unsorted",
    "fa-upload",
    "fa-usb",
    "fa-usd",
    "fa-user",
    "fa-user-circle",
    "fa-user-circle-o",
    "fa-user-md",
    "fa-user-o",
    "fa-user-plus",
    "fa-user-secret",
    "fa-user-times",
    "fa-users",
    "fa-venus",
    "fa-venus-double",
    "fa-venus-mars",
    "fa-viacoin",
    "fa-viadeo",
    "fa-viadeo-square",
    "fa-video-camera",
    "fa-vimeo",
    "fa-vimeo-square",
    "fa-vine",
    "fa-vk",
    "fa-volume-control-phone",
    "fa-volume-down",
    "fa-volume-off",
    "fa-volume-up",
    "fa-warning",
    "fa-wechat",
    "fa-weibo",
    "fa-weixin",
    "fa-whatsapp",
    "fa-wheelchair",
    "fa-wheelchair-alt",
    "fa-wifi",
    "fa-wikipedia-w",
    "fa-window-close",
    "fa-window-close-o",
    "fa-window-maximize",
    "fa-window-minimize",
    "fa-window-restore",
    "fa-windows",
    "fa-won",
    "fa-wordpress",
    "fa-wpbeginner",
    "fa-wpexplorer",
    "fa-wpforms",
    "fa-wrench",
    "fa-xing",
    "fa-xing-square",
    "fa-y-combinator",
    "fa-y-combinator-square",
    "fa-yahoo",
    "fa-yc",
    "fa-yc-square",
    "fa-yelp",
    "fa-yen",
    "fa-yoast",
    "fa-youtube",
    "fa-youtube-play",
    "fa-youtube-square",
  ];
  // data for mapping options into object
  const smart_icons = odooIcons?.map((icon) => ({
    value: icon,
    label: icon,
  }));
  // Handler for when an option is selected
  const handleSmartIconChange = (selectedOption) => {
    const event = {
      target: {
        name: "smartIcon",
        value: selectedOption ? selectedOption.value : "",
      },
    };
    handleChangeData(event);
  };
  // Update the form data state when selected field data changes
  useEffect(() => {
    if (selectedFieldData && selectedFieldData.tabName !== undefined) {
      setUpdateFieldData({
        ...updateFieldData,
        title: selectedFieldData.tabName,
        type: "tabPage",
        groupVisible: selectedFieldData.groupVisible || [],
      });
    } else if (selectedFieldData) {
      if (selectedFieldData.tabGroupField !== undefined) {
        if (selectedFieldData?.tabGroupField?.type === "label") {
          updateFieldData.alignment =
            selectedFieldData?.tabGroupField?.alignment || "left";
          updateFieldData.fontSize =
            selectedFieldData?.tabGroupField?.fontSize || "24";
          updateFieldData.fontWeight =
            selectedFieldData?.tabGroupField?.fontWeight || "500";
        }
        if (
          selectedFieldData?.tabGroupField?.type === "many2many" ||
          selectedFieldData?.tabGroupField?.type === "many2one" ||
          selectedFieldData?.tabGroupField?.type === "one2many"
        ) {
          updateFieldData.domain =
            selectedFieldData?.tabGroupField?.domain || null;
        }
        if (selectedFieldData?.tabGroupField?.type === "table") {
          updateFieldData.tableRelatedField =
            selectedFieldData?.tabGroupField?.tableRelatedField || null;

          updateFieldData.tableType =
            selectedFieldData?.tabGroupField?.tableType || null;
        }
        if (selectedFieldData?.tabGroupField?.type === "oe_avatar") {
          updateFieldData.defaultValue =
            selectedFieldData?.tabGroupField?.defaultValue || "";
          updateFieldData.fileName =
            selectedFieldData?.tabGroupField?.fileName || null;
          updateFieldData.alignment =
            selectedFieldData?.tabGroupField?.alignment || "right";
        }
        if (
          selectedFieldData?.tabGroupField?.type === "button" ||
          selectedFieldData?.tabGroupField?.type === "object" ||
          selectedFieldData?.tabGroupField?.type === "action"
        ) {
          updateFieldData.alignment =
            selectedFieldData?.tabGroupField?.alignment || "center";
        }
        let relatedModel = selectedFieldData?.tabGroupField?.relatedModel
          ? selectedFieldData?.tabGroupField?.relatedModel
          : null;
        let relationTable =
          selectedFieldData?.tabGroupField?.type === "many2many"
            ? selectedFieldData?.tabGroupField?.relationTable
            : null;
        let relationField =
          selectedFieldData?.tabGroupField?.type === "one2many"
            ? selectedFieldData?.tabGroupField?.relationField
            : null;
        let odooButtonColor =
          selectedFieldData?.tabGroupField?.type === "button" ||
          selectedFieldData?.tabGroupField?.type === "object" ||
          selectedFieldData?.tabGroupField?.type === "action"
            ? selectedFieldData?.tabGroupField?.odooButtonColor
            : "btn-outline-dark";
        setUpdateFieldData({
          ...updateFieldData,
          title: selectedFieldData.tabGroupField.title || "",
          type: selectedFieldData.tabGroupField.type || "",
          technicalName: selectedFieldData.tabGroupField.technicalName || "",
          widget: selectedFieldData.tabGroupField.widget || "",
          defaultValue: selectedFieldData.tabGroupField.defaultValue || "",
          options: selectedFieldData.tabGroupField.options || null,
          columns: selectedFieldData.tabGroupField.columns || null,
          placeholder: selectedFieldData.tabGroupField.placeholder || "",
          tooltip: selectedFieldData.tabGroupField.tooltip || "",
          groupVisible: selectedFieldData.tabGroupField.groupVisible || [],
          pythonCode: selectedFieldData.tabGroupField.pythonCode || null,
          isRequired: selectedFieldData.tabGroupField.isRequired || false,
          isReadOnly: selectedFieldData.tabGroupField.isReadOnly || false,
          isTracking: selectedFieldData.tabGroupField.isTracking || false,
          // isCreatedBy: selectedFieldData.tabGroupField.isCreatedBy || false,
          // isLoggedinUser: selectedFieldData.tabGroupField.isLoggedinUser || false,
          isOnChange: selectedFieldData.tabGroupField.isOnChange || false,
          isInvisible: selectedFieldData.tabGroupField.isInvisible || false,
          relatedModel: relatedModel,
          relationTable: relationTable,
          relationField: relationField,
          odooButtonColor: odooButtonColor,
        });
      } else if (selectedFieldData.tabField !== undefined) {
        if (selectedFieldData?.tabField?.type === "group") {
          updateFieldData.fields = selectedFieldData?.tabField?.fields || [];
        }
        if (selectedFieldData?.tabField?.type === "label") {
          updateFieldData.alignment =
            selectedFieldData?.tabField?.alignment || "left";
          updateFieldData.fontSize =
            selectedFieldData?.tabField?.fontSize || "24";
          updateFieldData.fontWeight =
            selectedFieldData?.tabField?.fontWeight || "500";
        }
        if (
          selectedFieldData?.tabField?.type === "many2many" ||
          selectedFieldData?.tabField?.type === "many2one" ||
          selectedFieldData?.tabField?.type === "one2many"
        ) {
          updateFieldData.domain = selectedFieldData?.tabField?.domain || null;
        }
        if (selectedFieldData?.tabField?.type === "table") {
          updateFieldData.tableRelatedField =
            selectedFieldData?.tabField?.tableRelatedField || null;

          updateFieldData.tableType =
            selectedFieldData?.tabField?.tableType || null;
        }
        if (selectedFieldData?.tabField?.type === "oe_avatar") {
          updateFieldData.defaultValue =
            selectedFieldData?.tabField?.defaultValue || "";
          updateFieldData.fileName =
            selectedFieldData?.tabField?.fileName || null;
          updateFieldData.alignment =
            selectedFieldData?.tabField?.alignment || "right";
        }
        if (
          selectedFieldData?.tabField?.type === "button" ||
          selectedFieldData?.tabField?.type === "object" ||
          selectedFieldData?.tabField?.type === "action"
        ) {
          updateFieldData.alignment =
            selectedFieldData?.tabField?.alignment || "center";
        }
        let relatedModel = selectedFieldData?.tabField?.relatedModel
          ? selectedFieldData?.tabField?.relatedModel
          : null;
        let relationTable =
          selectedFieldData?.tabField?.type === "many2many"
            ? selectedFieldData?.tabField?.relationTable
            : null;
        let relationField =
          selectedFieldData?.tabField?.type === "one2many"
            ? selectedFieldData?.tabField?.relationField
            : null;
        let odooButtonColor =
          selectedFieldData?.tabField?.type === "button" ||
          selectedFieldData?.tabField?.type === "object" ||
          selectedFieldData?.tabField?.type === "action"
            ? selectedFieldData?.tabField?.odooButtonColor
            : "btn-outline-dark";
        setUpdateFieldData({
          ...updateFieldData,
          title: selectedFieldData.tabField.title || "",
          type: selectedFieldData.tabField.type || "",
          technicalName: selectedFieldData.tabField.technicalName || "",
          widget: selectedFieldData.tabField.widget || "",
          defaultValue: selectedFieldData.tabField.defaultValue || "",
          options: selectedFieldData.tabField.options || null,
          columns: selectedFieldData.tabField.columns || null,
          placeholder: selectedFieldData.tabField.placeholder || "",
          tooltip: selectedFieldData.tabField.tooltip || "",
          groupVisible: selectedFieldData.tabField.groupVisible || [],
          pythonCode: selectedFieldData.tabField.pythonCode || null,
          isRequired: selectedFieldData.tabField.isRequired || false,
          isReadOnly: selectedFieldData.tabField.isReadOnly || false,
          isTracking: selectedFieldData.tabField.isTracking || false,
          // isCreatedBy: selectedFieldData.tabField.isCreatedBy || false,
          // isLoggedinUser: selectedFieldData.tabField.isLoggedinUser || false,
          isOnChange: selectedFieldData.tabField.isOnChange || false,
          isInvisible: selectedFieldData.tabField.isInvisible || false,
          relatedModel: relatedModel,
          relationTable: relationTable,
          relationField: relationField,
          odooButtonColor: odooButtonColor,
        });
      } else if (selectedFieldData.groupField) {
        if (selectedFieldData?.groupField?.type === "label") {
          updateFieldData.alignment =
            selectedFieldData?.groupField?.alignment || "left";
          updateFieldData.fontSize =
            selectedFieldData?.groupField?.fontSize || "24";
          updateFieldData.fontWeight =
            selectedFieldData?.groupField?.fontWeight || "500";
        }
        if (
          selectedFieldData?.groupField?.type === "many2many" ||
          selectedFieldData?.groupField?.type === "many2one" ||
          selectedFieldData?.groupField?.type === "one2many"
        ) {
          updateFieldData.domain =
            selectedFieldData?.groupField?.domain || null;
        }
        if (selectedFieldData?.groupField?.type === "table") {
          updateFieldData.tableRelatedField =
            selectedFieldData?.groupField?.tableRelatedField || null;
          updateFieldData.tableType =
            selectedFieldData?.groupField?.tableType || null;
        }
        if (selectedFieldData?.groupField?.type === "oe_avatar") {
          updateFieldData.defaultValue =
            selectedFieldData?.groupField?.defaultValue || "";
          updateFieldData.fileName =
            selectedFieldData?.groupField?.fileName || null;
          updateFieldData.alignment =
            selectedFieldData?.groupField?.alignment || "right";
        }
        if (
          selectedFieldData?.groupField?.type === "button" ||
          selectedFieldData?.groupField?.type === "object" ||
          selectedFieldData?.groupField?.type === "action"
        ) {
          updateFieldData.alignment =
            selectedFieldData?.groupField?.alignment || "center";
        }
        let relatedModel = selectedFieldData?.groupField?.relatedModel
          ? selectedFieldData?.groupField?.relatedModel
          : null;
        let relationTable =
          selectedFieldData?.groupField?.type === "many2many"
            ? selectedFieldData?.groupField?.relationTable
            : null;
        let relationField =
          selectedFieldData?.groupField?.type === "one2many"
            ? selectedFieldData?.groupField?.relationField
            : null;
        let odooButtonColor =
          selectedFieldData?.groupField?.type === "button" ||
          selectedFieldData?.groupField?.type === "object" ||
          selectedFieldData?.groupField?.type === "action"
            ? selectedFieldData?.groupField?.odooButtonColor
            : "btn-outline-dark";
        setUpdateFieldData({
          ...updateFieldData,
          title: selectedFieldData.groupField.title || "",
          type: selectedFieldData.groupField.type || "",
          technicalName: selectedFieldData.groupField.technicalName || "",
          widget: selectedFieldData.groupField.widget || "",
          defaultValue: selectedFieldData.groupField.defaultValue || "",
          options: selectedFieldData.groupField.options || null,
          columns: selectedFieldData.groupField.columns || null,
          placeholder: selectedFieldData.groupField.placeholder || "",
          tooltip: selectedFieldData.groupField.tooltip || "",
          groupVisible: selectedFieldData.groupField.groupVisible || [],
          pythonCode: selectedFieldData.groupField.pythonCode || null,
          isRequired: selectedFieldData.groupField.isRequired || false,
          isReadOnly: selectedFieldData.groupField.isReadOnly || false,
          isTracking: selectedFieldData.groupField.isTracking || false,
          // isCreatedBy: selectedFieldData.groupField.isCreatedBy || false,
          // isLoggedinUser: selectedFieldData.groupField.isLoggedinUser || false,
          isOnChange: selectedFieldData.groupField.isOnChange || false,
          isInvisible: selectedFieldData.groupField.isInvisible || false,
          relatedModel: relatedModel,
          relationTable: relationTable,
          relationField: relationField,
          odooButtonColor: odooButtonColor,
        });
      } else {
        if (
          selectedFieldData?.field?.type === "tab" ||
          selectedFieldData?.field?.type === "remark_history"
        ) {
          setUpdateFieldData({
            ...updateFieldData,
            title: selectedFieldData?.field?.title || "",
            technicalName: selectedFieldData?.field?.technicalName || "",
            type: selectedFieldData?.field?.type || "",
            widget: selectedFieldData?.field?.widget || "",
            defaultValue: selectedFieldData?.field?.defaultValue || "",
            options: selectedFieldData?.field?.options || null,
            columns: selectedFieldData?.field?.columns || null,
            placeholder: selectedFieldData?.field?.placeholder || "",
            tooltip: selectedFieldData?.field?.tooltip || "",
            groupVisible: selectedFieldData?.field?.groupVisible || [],
            pythonCode: selectedFieldData?.field?.pythonCode || null,
            isRequired: selectedFieldData?.field?.isRequired || false,
            isReadOnly: selectedFieldData?.field?.isReadOnly || false,
            isTracking: selectedFieldData?.field?.isTracking || false,
            // isCreatedBy: selectedFieldData?.field?.isCreatedBy || false,
            // isLoggedinUser: selectedFieldData?.field?.isLoggedinUser || false,
            isOnChange: selectedFieldData?.field?.isOnChange || false,
            isInvisible: selectedFieldData?.field?.isInvisible || false,
            tabs: selectedFieldData?.field?.tabs || [],
            activeTab: selectedFieldData?.field?.activeTab || 0,
          });
        } else if (selectedFieldData?.field?.type === "group") {
          let relatedModel = selectedFieldData?.field?.relatedModel
            ? selectedFieldData?.field?.relatedModel
            : null;
          let relationTable =
            selectedFieldData?.field?.type === "many2many"
              ? selectedFieldData?.field?.relationTable
              : null;
          let relationField =
            selectedFieldData?.field?.type === "one2many"
              ? selectedFieldData?.field?.relationField
              : null;
          let odooButtonColor =
            selectedFieldData?.field?.type === "button" ||
            selectedFieldData?.field?.type === "object" ||
            selectedFieldData?.field?.type === "action"
              ? selectedFieldData?.field?.odooButtonColor
              : "btn-outline-dark";

          setUpdateFieldData({
            ...updateFieldData,
            title: selectedFieldData?.field?.title || "",
            technicalName: selectedFieldData?.field?.technicalName || "",
            type: selectedFieldData?.field?.type || "",
            widget: selectedFieldData?.field?.widget || "",
            defaultValue: selectedFieldData?.field?.defaultValue || "",
            options: selectedFieldData?.field?.options || null,
            columns: selectedFieldData?.field?.columns || null,
            placeholder: selectedFieldData.placeholder || "",
            tooltip: selectedFieldData?.field?.tooltip || "",
            groupVisible: selectedFieldData?.field?.groupVisible || [],
            pythonCode: selectedFieldData?.field?.pythonCode || null,
            isRequired: selectedFieldData?.field?.isRequired || false,
            isReadOnly: selectedFieldData?.field?.isReadOnly || false,
            isTracking: selectedFieldData?.field?.isTracking || false,
            // isCreatedBy: selectedFieldData?.field?.isCreatedBy || false,
            // isLoggedinUser: selectedFieldData?.field?.isLoggedinUser || false,
            isOnChange: selectedFieldData?.field?.isOnChange || false,
            isInvisible: selectedFieldData?.field?.isInvisible || false,
            fields: selectedFieldData?.field?.fields || [],
            relatedModel: relatedModel,
            relationTable: relationTable,
            relationField: relationField,
            odooButtonColor: odooButtonColor,
          });
        } else {
          if (selectedFieldData?.field?.type === "label") {
            if (selectedFieldData?.field?.widget !== "statinfo") {
              updateFieldData.alignment =
                selectedFieldData?.field?.alignment || "left";
            }
            updateFieldData.fontSize =
              selectedFieldData?.field?.fontSize || "24";
            updateFieldData.fontWeight =
              selectedFieldData?.field?.fontWeight || "500";
          }
          if (
            selectedFieldData?.field?.type === "many2many" ||
            selectedFieldData?.field?.type === "many2one" ||
            selectedFieldData?.field?.type === "one2many"
          ) {
            updateFieldData.domain = selectedFieldData?.field?.domain || null;
          }
          if (selectedFieldData?.field?.type === "ribbon") {
            updateFieldData.ribbonBgColor =
              selectedFieldData?.field?.ribbonBgColor || "bg-danger";
          }
          if (selectedFieldData?.field?.type === "table") {
            updateFieldData.tableRelatedField =
              selectedFieldData?.field?.tableRelatedField || null;
            updateFieldData.tableType =
              selectedFieldData?.field?.tableType || null;
          }
          if (
            ["static_image", "oe_avatar"].includes(
              selectedFieldData?.field?.type
            )
          ) {
            updateFieldData.defaultValue =
              selectedFieldData?.field?.defaultValue || "";
            updateFieldData.fileName =
              selectedFieldData?.field?.fileName || null;
            if (selectedFieldData?.field?.widget !== "statinfo") {
              updateFieldData.alignment =
                selectedFieldData?.field?.alignment || "right";
            }
            if (selectedFieldData?.field?.widget !== "static_image") {
              updateFieldData.alignment =
                selectedFieldData?.field?.alignment || "right";
            }
          }
          if (
            selectedFieldData?.field?.type === "button" ||
            selectedFieldData?.field?.type === "object" ||
            selectedFieldData?.field?.type === "action"
          ) {
            if (selectedFieldData?.field?.widget !== "statinfo") {
              updateFieldData.alignment =
                selectedFieldData?.field?.alignment || "center";
            } else {
              updateFieldData.smartIcon =
                selectedFieldData?.field?.smartIcon || "select";
            }
          }
          let relatedModel = selectedFieldData?.field?.relatedModel
            ? selectedFieldData?.field?.relatedModel
            : null;
          let relationTable =
            selectedFieldData?.field?.type === "many2many"
              ? selectedFieldData?.field?.relationTable
              : null;
          let relationField =
            selectedFieldData?.field?.type === "one2many"
              ? selectedFieldData?.field?.relationField
              : null;
          let odooButtonColor =
            selectedFieldData?.field?.type === "button" ||
            selectedFieldData?.field?.type === "object" ||
            selectedFieldData?.field?.type === "action"
              ? selectedFieldData?.field?.odooButtonColor
              : "btn-outline-dark";
          let pythonCode =
            selectedFieldData?.field?.type === "button" ||
            selectedFieldData?.field?.type === "object" ||
            selectedFieldData?.field?.type === "action"
              ? selectedFieldData?.field?.pythonCode
              : null;
          setUpdateFieldData({
            ...updateFieldData,
            title: selectedFieldData?.field?.title || "",
            type: selectedFieldData?.field?.type || "",
            technicalName: selectedFieldData?.field?.technicalName || "",
            widget: selectedFieldData?.field?.widget || "",
            defaultValue: selectedFieldData?.field?.defaultValue || "",
            options: selectedFieldData?.field?.options || null,
            columns: selectedFieldData?.field?.columns || null,
            placeholder: selectedFieldData?.field?.placeholder || "",
            tooltip: selectedFieldData?.field?.tooltip || "",
            groupVisible: selectedFieldData?.field?.groupVisible || [],
            isRequired: selectedFieldData?.field?.isRequired || false,
            isReadOnly: selectedFieldData?.field?.isReadOnly || false,
            isTracking: selectedFieldData?.field?.isTracking || false,
            // isCreatedBy: selectedFieldData?.field?.isCreatedBy || false,
            // isLoggedinUser: selectedFieldData?.field?.isLoggedinUser || false,
            isOnChange: selectedFieldData?.field?.isOnChange || false,
            isInvisible: selectedFieldData?.field?.isInvisible || false,
            relatedModel: relatedModel,
            relationTable: relationTable,
            relationField: relationField,
            odooButtonColor: odooButtonColor,
            pythonCode: pythonCode || null,
          });
        }
      }
    }
  }, [selectedFieldData]);

  // useEffect for fetching all models in odoo
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/odoo/models?process_id=${newProcessId}&api_key=${API_KEY}`
        );
        const data = await response.json();
        if (response.ok) {
          if (Array.isArray(data.data)) {
            setOdooModels(data.data);
          }
        } else {
          console.error("Error in fetch: ", data);
        }
      } catch (error) {
        console.log("server error: ", error);
      }
    };
    fetchModels();
  }, [selectedFieldData]);

  // useEffect for fetching all fields and many2one fields in selected related modal
  useEffect(() => {
    if (updateFieldData?.relatedModel !== "") {
      const fetchModalFields = async () => {
        try {
          const response = await fetch(
            `${BASE_URL}/odoo/field?model=${updateFieldData.relatedModel}&process_id=${newProcessId}&api_key=${API_KEY}`
          );
          const data = await response.json();
          if (response.ok) {
            if (Array.isArray(data.data)) {
              // console.log("related field data:::::::: ", data);
              // console.log("related fields:::::: ", JSON.stringify(data.data));
              setSelectedModalFields(data.data);
            }
          } else {
            console.error("Error in fetch: ", data);
          }
        } catch (error) {
          console.log("server error: ", error);
        }
      };
      const fetchRelatedFields = async () => {
        try {
          const response = await fetch(
            `${BASE_URL}/odoo/fields?model=${updateFieldData.relatedModel}&process_id=${newProcessId}&api_key=${API_KEY}`
          );
          const data = await response.json();
          if (response.ok) {
            if (Array.isArray(data.data)) {
              // console.log("related field data:::::::: ", data);
              // console.log("related fields:::::: ", JSON.stringify(data.data));
              setFilteredRelatedField(data.data);
            }
          } else {
            console.error("Error in fetch: ", data);
          }
        } catch (error) {
          console.log("server error: ", error);
        }
      };
      fetchModalFields();
      fetchRelatedFields();
    }
  }, [updateFieldData.relatedModel]);

  // useEffect for updating technical name
  useEffect(() => {
    if (updateFieldData.widget === "remark_field") {
      setUpdateFieldData((prevData) => ({
        ...prevData,
        technicalName: `x_${prevData.title
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]/g, "_")}`,
      }));
    } else {
      setUpdateFieldData((prevData) => ({
        ...prevData,
        technicalName: `x_o2b_${prevData.title
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]/g, "_")}`,
      }));
    }
  }, [updateFieldData.title]);

  // method for technical name validation
  // const validateTechnicalName = (value) => {
  //   const regex = /^x_o2b_[a-zA-Z0-9_]*$/;
  //   return regex.test(value);
  // };

  // method for changing technical name
  // const handleTechnicalNameChange = (e) => {
  //   const value = e.target.value.toLowerCase();
  //   if (validateTechnicalName(value)) {
  //     setUpdateFieldData({ ...updateFieldData, technicalName: value });
  //     setTechnicalNameError("");
  //   } else {
  //     setTechnicalNameError(
  //       'Input must start with "x_o2b_" and contain only alphabets and underscores.'
  //     );
  //   }
  // };

  // method for reseting selected field data
  const handleReset = () => {
    setUpdateFieldData({
      title: "",
      technicalName: "x_o2b_",
      type: "",
      widget: "",
      options: null,
      odooButtonColor: "",
      placeholder: "",
      tooltip: "",
      pythonCode: null,
      isRequired: false,
      isReadOnly: false,
      isTracking: false,
      isCreatedBy: false,
      isOnChange: false,
      isInvisible: false,
    });
    setShowFieldDetail(false);
  };

  // method for final submit to update field data
  const handleFieldDataSubmit = () => {
    // let updatedFormData = [...formData];
    let updatedFormData = JSON.parse(JSON.stringify(formData));

    if (selectedFieldData.tabGroupField !== undefined) {
      // updating field inside tabGroupfield
      const {
        rowIndex,
        columnIndex,
        tabIndex,
        tabRowIndex,
        tabFieldIndex,
        tabGroupRowIndex,
        tabGroupFieldIndex,
      } = selectedFieldData;
      if (tabGroupRowIndex !== undefined && tabGroupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex].fields[tabGroupRowIndex][tabGroupFieldIndex] = {
          ...updateFieldData,
        };
      } else if (tabGroupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex].fields[tabGroupFieldIndex] = { ...updateFieldData };
      } else {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex] = { ...updateFieldData };
      }
    } else if (selectedFieldData.groupField !== undefined) {
      // updating field inside group
      const { rowIndex, columnIndex, groupRowIndex, groupFieldIndex } =
        selectedFieldData;
      if (groupRowIndex !== undefined && groupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].fields[groupRowIndex][
          groupFieldIndex
        ] = { ...updateFieldData };
      } else if (groupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].fields[groupFieldIndex] = {
          ...updateFieldData,
        };
      } else {
        updatedFormData[rowIndex][columnIndex] = { ...updateFieldData };
      }
    } else if (selectedFieldData.tabName !== undefined) {
      const { rowIndex, columnIndex, tabIndex } = selectedFieldData;
      updatedFormData[rowIndex][columnIndex].tabs[tabIndex].tabName =
        updateFieldData.title;
    } else if (selectedFieldData.tabField !== undefined) {
      // updating field inside tabfield
      const { rowIndex, columnIndex, tabIndex, tabRowIndex, tabFieldIndex } =
        selectedFieldData;
      if (tabRowIndex !== undefined && tabFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex] = { ...updateFieldData };
      } else if (tabFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabFieldIndex
        ] = { ...updateFieldData };
      } else {
        updatedFormData[rowIndex][columnIndex] = { ...updateFieldData };
      }
    } else {
      // updating top level field
      updatedFormData = updatedFormData.map((row) =>
        row.map((field) =>
          field === selectedFieldData.field ? { ...updateFieldData } : field
        )
      );
    }

    setFormData(updatedFormData);
  };
  // method for taking input field data
  const handleChangeData = (e) => {
    const { name, value, type, checked } = e.target;

    setUpdateFieldData({
      ...updateFieldData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // setImageBase64(reader.result);
        updateFieldData.defaultValue = reader.result;
        updateFieldData.fileName = file.name;
        console.log("selected file::::: ", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFileRemove = (e) => {
    e.preventDefault();
    // setUpdateFieldData({...updateFieldData, defaultValue: ""})
    updateFieldData.defaultValue = "";
    handleBlurData();
  };
  // console.log("selected file:::::::: ", selectedFile);
  const handleCheckBoxChange = (e) => {
    const { name, checked } = e.target;
    setUpdateFieldData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  // handle field update form submit
  const handleUpdateFieldDataSubmit = (e) => {
    e.preventDefault();
    handleFieldDataSubmit();
    setPythonCode(null);
    handleReset();
  };

  // method for saving field detail when field is blured
  const handleBlurData = (e) => {
    // e.preventDefault()

    let clickedFieldData = { ...selectedFieldData };
    if (clickedFieldData.tabName !== undefined) {
      clickedFieldData.tabName = updateFieldData.title;
    } else if (clickedFieldData.tabGroupField !== undefined) {
      if (clickedFieldData.tabGroupField.type === "table") {
        const tableRelatedField =
          clickedFieldData.tabGroupField.tableRelatedField;
        updateFieldData.tableRelatedField = tableRelatedField;
        clickedFieldData.tabGroupField = { ...updateFieldData };
      } else {
        clickedFieldData.tabGroupField = { ...updateFieldData };
      }
      // clickedFieldData.tabField = updateFieldData;
    } else if (clickedFieldData.tabField !== undefined) {
      if (clickedFieldData.tabField.type === "table") {
        const tableRelatedField = clickedFieldData.tabField.tableRelatedField;
        updateFieldData.tableRelatedField = tableRelatedField;
        clickedFieldData.tabField = { ...updateFieldData };
      }
      // else if (clickedFieldData.tabField.type === "group") {
      // const fields = clickedFieldData.tabField.fields
      // updateFieldData.fields = fields;
      // clickedFieldData.tabField = updateFieldData;
      // }
      else {
        clickedFieldData.tabField = { ...updateFieldData };
      }
      // clickedFieldData.tabField = updateFieldData;
    } else if (clickedFieldData.groupField !== undefined) {
      if (clickedFieldData.groupField.type === "table") {
        const tableRelatedField = clickedFieldData.groupField.tableRelatedField;
        updateFieldData.tableRelatedField = tableRelatedField;
        clickedFieldData.groupField = { ...updateFieldData };
      } else {
        clickedFieldData.groupField = { ...updateFieldData };
      }
      // clickedFieldData.groupField = updateFieldData;
    } else {
      if (clickedFieldData.field.type === "table") {
        const tableRelatedField = clickedFieldData.field.tableRelatedField;
        updateFieldData.tableRelatedField = tableRelatedField;
        clickedFieldData.field = { ...updateFieldData };
      } else {
        clickedFieldData.field = { ...updateFieldData };
      }
    }
    setSelectedFieldData({ ...clickedFieldData });
    // handleFieldDataSubmit();

    // let updatedFormData = [...formData];
    let updatedFormData = JSON.parse(JSON.stringify(formData));

    if (selectedFieldData.tabGroupField !== undefined) {
      // updating field inside tabGroupfield
      const {
        rowIndex,
        columnIndex,
        tabIndex,
        tabRowIndex,
        tabFieldIndex,
        tabGroupRowIndex,
        tabGroupFieldIndex,
      } = selectedFieldData;
      if (tabGroupRowIndex !== undefined && tabGroupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex].fields[tabGroupRowIndex][tabGroupFieldIndex] = {
          ...updateFieldData,
        };
      } else if (tabGroupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex].fields[tabGroupFieldIndex] = { ...updateFieldData };
      } else {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex] = { ...updateFieldData };
      }
    } else if (selectedFieldData.groupField !== undefined) {
      // updating field inside group
      const { rowIndex, columnIndex, groupRowIndex, groupFieldIndex } =
        selectedFieldData;
      if (groupRowIndex !== undefined && groupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].fields[groupRowIndex][
          groupFieldIndex
        ] = { ...updateFieldData };
      } else if (groupFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].fields[groupFieldIndex] =
          updateFieldData;
      } else {
        updatedFormData[rowIndex][columnIndex] = { ...updateFieldData };
      }
    } else if (selectedFieldData.tabName !== undefined) {
      const { rowIndex, columnIndex, tabIndex } = selectedFieldData;
      updatedFormData[rowIndex][columnIndex].tabs[tabIndex].tabName =
        updateFieldData.title;
    } else if (selectedFieldData.tabField !== undefined) {
      // updating field inside tabfield
      const { rowIndex, columnIndex, tabIndex, tabRowIndex, tabFieldIndex } =
        selectedFieldData;
      if (tabRowIndex !== undefined && tabFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex] = { ...updateFieldData };
      } else if (tabFieldIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabFieldIndex
        ] = { ...updateFieldData };
      } else {
        updatedFormData[rowIndex][columnIndex] = { ...updateFieldData };
      }
    } else {
      const { rowIndex, columnIndex } = selectedFieldData;
      updatedFormData[rowIndex][columnIndex] = { ...updateFieldData };
    }

    setFormData(updatedFormData);
  };

  // method for deleting selected field
  const handleDeleteField = () => {
    let updatedFormData = [...formData];
    if (selectedFieldData.tabGroupField !== undefined) {
      const {
        rowIndex,
        columnIndex,
        tabIndex,
        tabRowIndex,
        tabFieldIndex,
        tabGroupRowIndex,
        tabGroupFieldIndex,
      } = selectedFieldData;
      if (tabGroupFieldIndex !== undefined) {
        // Locate and delete the specific field in the nested group
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex].fields[tabGroupRowIndex] = updatedFormData[rowIndex][
          columnIndex
        ].tabs[tabIndex].content[tabRowIndex][tabFieldIndex].fields[
          tabGroupRowIndex
        ].filter((_, index) => index !== tabGroupFieldIndex);

        // Remove the row if it's empty
        if (
          updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
            tabRowIndex
          ][tabFieldIndex].fields[tabGroupRowIndex].length === 0
        ) {
          updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
            tabRowIndex
          ][tabFieldIndex].fields = updatedFormData[rowIndex][columnIndex].tabs[
            tabIndex
          ].content[tabRowIndex][tabFieldIndex].fields.filter(
            (_, index) => index !== tabGroupRowIndex
          );
        }
      } else {
        // Delete the field at the specified columnIndex
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ] = updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ].filter((_, index) => index !== tabFieldIndex);
      }
    } else if (selectedFieldData.groupField !== undefined) {
      const { rowIndex, columnIndex, groupRowIndex, groupFieldIndex } =
        selectedFieldData;
      if (groupFieldIndex !== undefined) {
        // Locate and delete the specific field in the nested group
        updatedFormData[rowIndex][columnIndex].fields[groupRowIndex] =
          updatedFormData[rowIndex][columnIndex].fields[groupRowIndex].filter(
            (_, index) => index !== groupFieldIndex
          );

        // Remove the row if it's empty
        if (
          updatedFormData[rowIndex][columnIndex].fields[groupRowIndex]
            .length === 0
        ) {
          updatedFormData[rowIndex][columnIndex].fields = updatedFormData[
            rowIndex
          ][columnIndex].fields.filter((_, index) => index !== groupRowIndex);
        }
      } else {
        // Delete the field at the specified columnIndex
        updatedFormData[rowIndex] = updatedFormData[rowIndex].filter(
          (_, index) => index !== columnIndex
        );
      }
    } else if (selectedFieldData.tabName !== undefined) {
      // deleting whole tab from tab field
      const { rowIndex, columnIndex, tabIndex } = selectedFieldData;

      if (
        tabIndex !== undefined &&
        tabIndex === updatedFormData[rowIndex][columnIndex].tabs.length - 1 &&
        updatedFormData[rowIndex][columnIndex].tabs.length - 1 === 0
      ) {
        updatedFormData[rowIndex] = updatedFormData[rowIndex].filter(
          (_, index) => index !== columnIndex
        );
      } else if (
        tabIndex !== undefined &&
        tabIndex === updatedFormData[rowIndex][columnIndex].tabs.length - 1
      ) {
        updatedFormData[rowIndex][columnIndex].tabs = updatedFormData[rowIndex][
          columnIndex
        ].tabs.filter((_, index) => index !== tabIndex);
        formData[rowIndex][columnIndex].activeTab = 0;
      } else if (tabIndex !== undefined) {
        updatedFormData[rowIndex][columnIndex].tabs = updatedFormData[rowIndex][
          columnIndex
        ].tabs.filter((_, index) => index !== tabIndex);
      } else {
        // Delete the field at the specified columnIndex
        updatedFormData[rowIndex] = updatedFormData[rowIndex].filter(
          (_, index) => index !== columnIndex
        );
      }
    } else if (selectedFieldData.tabField !== undefined) {
      const { rowIndex, columnIndex, tabIndex, tabRowIndex, tabFieldIndex } =
        selectedFieldData;
      if (tabFieldIndex !== undefined) {
        // Locate and delete the specific field in the nested group
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ] = updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ].filter((_, index) => index !== tabFieldIndex);

        // Remove the row if it's empty
        if (
          updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
            tabRowIndex
          ].length === 0
        ) {
          updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content =
            updatedFormData[rowIndex][columnIndex].tabs[
              tabIndex
            ].content.filter((_, index) => index !== tabRowIndex);
        }
      } else {
        // Delete the field at the specified columnIndex
        updatedFormData[rowIndex] = updatedFormData[rowIndex].filter(
          (_, index) => index !== columnIndex
        );
      }
    } else {
      // General case: filter out the selected field from all rows
      updatedFormData = updatedFormData.map((row) =>
        row.filter((field) => field !== selectedFieldData.field)
      );
    }

    // Filter out any rows that are now empty
    updatedFormData = updatedFormData.filter((row) => row.length > 0);
    setFormData(updatedFormData);
    topRightAlert("success", "Field removed...");
    handleReset();
  };

  // method for handling option delete
  const handleOptionDelete = (e, optIndex) => {
    e.preventDefault();
    // const opts = updateFieldData.options.filter(
    //   (_, index) => index !== optIndex
    // );
    // setUpdateFieldData({ ...updateFieldData, options: opts });
    updateFieldData?.options?.splice(optIndex, 1);
    setUpdateFieldData({ ...updateFieldData });
    handleBlurData();
    // handleFieldDataSubmit()
  };

  const [showInputFieldOpt, setShowInputFieldOpt] = useState(false);
  const [newOpt, setNewOpt] = useState("");
  const handleAddMoreOptChange = (e) => {
    setNewOpt(e.target.value);
  };
  const handleAddNewOpt = () => {
    if (newOpt && newOpt !== "") {
      updateFieldData.options.push(newOpt);
      handleBlurData();
      setNewOpt("");
      setShowInputFieldOpt(false);
    }
  };

  // handling drag and drop for option
  // Drag and drop logic
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default to allow drop
  };

  const handleDrop = (e, dropIndex) => {
    const dragIndex = e.dataTransfer.getData("text");
    if (dragIndex !== dropIndex) {
      const newOptions = [...updateFieldData.options];
      const draggedItem = newOptions.splice(dragIndex, 1)[0]; // Remove the dragged item
      newOptions.splice(dropIndex, 0, draggedItem); // Insert the dragged item at the new index
      // setUpdateFieldData({ ...updateFieldData, options: [...newOptions] });
      // handleFieldDataSubmit()
      updateFieldData.options = newOptions;
      handleBlurData();
    }
    e.dataTransfer.clearData();
  };

  // handling table columns
  const [showSelectColumn, setShowSelectColumn] = useState(false);
  const [newColumn, setNewColumn] = useState("");
  const [newtableColumnFields, setNewTableColumnFields] = useState({
    title: "",
    technicalName: "x_o2b_",
    type: "char",
  });
  const [allOne2ManyFields, setAllOne2ManyFields] = useState([]);
  const [tableRelatedModelFields, setTableRelatedModelFields] = useState([]);
  const [allFields, setAllFields] = useState(null);
  // useEffect for all fields
  useEffect(() => {
    if (forms) {
      const oprs = [];
      forms.forEach((form) => {
        if (form.formbuilder_data) {
          const opr = parseFormData(form.formbuilder_data);
          opr.forEach((op) => {
            oprs.push(op);
          });
        }
      });
      setAllFields(oprs);
    }
  }, [forms]);
  useEffect(() => {
    // Step 1: Filter data where type is 'one2many'
    const filteredData = allFields?.filter((item) => item.type === "one2many");
    // console.log("filteredData: ", filteredData);
    // Step 2: Ensure uniqueness based on 'technical_name'
    const seenTechnicalNames = new Set();
    const uniqueFilteredData = filteredData?.filter((item) => {
      if (!seenTechnicalNames.has(item.technicalName)) {
        seenTechnicalNames.add(item.technicalName);
        return true;
      }
      return false;
    });
    // console.log("seenTechnicalName: ", seenTechnicalNames);
    // console.log("uniqueFilteredData: ", uniqueFilteredData);

    setAllOne2ManyFields(uniqueFilteredData);
  }, [allFields]);
  const handleFetchRelatedNormalField = async (modelName) => {
    try {
      const response = await fetch(`${BASE_URL}/odoo/field?model=${modelName}`);
      const data = await response.json();
      if (response.ok) {
        setTableRelatedModelFields(data.data);
        // console.log("response data for fields:::::: ", data);
      } else {
        console.log("error in fetching: ", data);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };
  useEffect(() => {
    if (updateFieldData.tableRelatedField !== "") {
      const modelField = allOne2ManyFields?.find(
        (field) => field.technicalName === updateFieldData?.tableRelatedField
      );
      handleFetchRelatedNormalField(modelField?.relatedModel);
    }
  }, [updateFieldData.tableRelatedField]);

  // handle for existing table column value change
  const handleTableColumnChange = (e) => {
    setNewColumn(e.target.value);
  };
  // handle for new table column value change
  const handleNewTableColumnChange = (e) => {
    const value = e.target.value;
    if (e.target.name === "type") {
      setNewTableColumnFields({ ...newtableColumnFields, type: value });
    } else {
      const technicalName = `x_o2b_${value
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, "_")}`;
      setNewTableColumnFields({
        ...newtableColumnFields,
        title: value,
        technicalName,
      });
    }
  };
  // handle for add column button click
  const handleAddNewColumn = () => {
    if (newColumn && newColumn !== "") {
      updateFieldData.columns.push(newColumn);
      handleBlurData();
      setNewColumn("");
      setShowSelectColumn(false);
    }
  };
  // handle for add column button click
  const handleAddNewTableColumn = () => {
    if (
      newtableColumnFields?.title !== "" &&
      newtableColumnFields.type !== ""
    ) {
      updateFieldData.columns.push(newtableColumnFields);
      handleBlurData();
      setNewTableColumnFields({
        title: "",
        technicalName: "x_o2b_",
        type: "char",
      });
      setShowSelectColumn(false);
    }
  };
  // handle for delete column
  const handleColumnDelete = (e, clmnIndex) => {
    e.preventDefault();
    updateFieldData?.columns?.splice(clmnIndex, 1);
    setUpdateFieldData({ ...updateFieldData });
    handleBlurData();
  };

  // handling group visibilty for fields
  // const [newGrp, setNewGrp] = useState("");
  const [showGrpInput, setShowGrpInput] = useState(true);
  const [filteredGroup, setFilteredGroup] = useState(null);
  const handleAddNewGroup = (e) => {
    const { value } = e.target;
    if (value && value !== "") {
      updateFieldData.groupVisible.push(value);
      setShowGrpInput(false);
    }
  };
  const handleGrpDelete = (e, grpIndex) => {
    e.preventDefault();
    updateFieldData.groupVisible.splice(grpIndex, 1);
    // const grps = updateFieldData.groupVisible.filter(
    //   (_, index) => index !== grpIndex
    // );
    // setUpdateFieldData({ ...updateFieldData, groupVisible: grps });
    handleBlurData();
  };

  // useEffect for filter group by database
  useEffect(() => {
    if (groups) {
      const grps = groups.filter(
        (group) => group.database_obj === processDetail.database_obj
      );
      setFilteredGroup(grps);
    } else {
      fetchAllGroups();
    }
  }, [groups]);

  // method for python code editor
  const handleCodeEditorClick = (e) => {
    e.preventDefault();
    // setButtonData(selectedFieldData);
    if (selectedFieldData.tabField !== undefined) {
      setButtonData({
        ...selectedFieldData,
        tabField: { ...updateFieldData, pythonCode: null },
      });
    } else if (selectedFieldData.groupField !== undefined) {
      setButtonData({
        ...buttonData,
        groupField: { ...updateFieldData, pythonCode: null },
      });
    } else {
      setButtonData({ ...updateFieldData, pythonCode: null });
    }
    if (
      updateFieldData.pythonCode !== null &&
      updateFieldData.pythonCode !== undefined
    ) {
      setPythonCode(updateFieldData.pythonCode);
    }
    handleFieldDataSubmit();
    navigate("/code-editor");
  };

  // object for styling selection arrow
  const selectStyle = {
    appearance: updateFieldData.type === "char" ? "auto" : "none", // for most modern browsers
    WebkitAppearance: updateFieldData.type === "char" ? "auto" : "none", // for WebKit browsers
    MozAppearance: updateFieldData.type === "char" ? "auto" : "none", // for Mozilla browsers
  };

  // console.log(
  //   "FormData--------------========>>>>>>: ",
  //   JSON.stringify(formData)
  // );
  // console.log("FormData--------------========>>>>>>: ", formData);
  // console.log("selectedFieldData::::::::: ", JSON.stringify(selectedFieldData));
  // console.log("updateFieldData::: ", JSON.stringify(updateFieldData));
  console.log("updateFieldData::: ", updateFieldData);
  console.log("selectedFieldData::: ", selectedFieldData);

  return (
    <div className="fb_detailUpdate">
      <h2>Field Details</h2>
      {showFieldDetail && (
        <div className="fb_field_details">
          {/* code for field delete button */}
          {updateFieldData.widget !== "remark_field" &&
            updateFieldData.technicalName !== "x_o2b_remark_table" && (
              <button
                className="btn btn-danger fb_field_details_btn"
                onClick={handleDeleteField}
              >
                <MdDeleteOutline className="fb_field_details_btn_icon" />
              </button>
            )}
          <form
            action=""
            className="fb_field_details_form"
            onSubmit={handleUpdateFieldDataSubmit}
          >
            {/* field code for label field */}
            <div className="fb_field_details_form_group">
              <label htmlFor="title">Label</label>
              <input
                type="text"
                name="title"
                id="title"
                value={updateFieldData.title}
                onChange={handleChangeData}
                onBlur={handleBlurData}
                readOnly={
                  [
                    "create_uid",
                    "create_date",
                    "write_uid",
                    "write_date",
                    "logged_in_user",
                    "remark_history",
                  ].includes(updateFieldData.type) ||
                  updateFieldData.widget === "remark_field" ||
                  updateFieldData.technicalName === "x_o2b_remark_table"
                    ? true
                    : false
                }
              />
              {updateFieldData.widget !== "remark_field" &&
                updateFieldData.technicalName !== "x_o2b_remark_table" &&
                ![
                  "create_uid",
                  "create_date",
                  "write_uid",
                  "write_date",
                  "logged_in_user",
                  "static_image",
                  "button",
                  "object",
                  "action",
                  "remark_history",
                  "separator",
                  "table",
                  "group",
                  "tab",
                  "chatter",
                  "ribbon",
                  "label",
                ].includes(updateFieldData.type) &&
                selectedFieldData.tabName === undefined && (
                  <p
                    style={{
                      fontSize: "12px",
                      width: "100%",
                      marginBottom: "0rem",
                    }}
                  >
                    Technical Name:{" "}
                    <span
                      style={{ wordBreak: "break-word", lineBreak: "anywhere" }}
                    >
                      {updateFieldData.technicalName}
                    </span>
                  </p>
                )}
              {/* {updateFieldData.type !== "label" &&
                updateFieldData.type !== "ribbon" &&
                updateFieldData.type !== "chatter" &&
                updateFieldData.type !== "tab" &&
                updateFieldData.type !== "group" &&
                updateFieldData.type !== "table" &&
                updateFieldData.type !== "separator" &&
                updateFieldData.type !== "remark_history" &&
                updateFieldData.widget !== "remark_field" &&
                updateFieldData.technicalName !== "x_o2b_remark_table" &&
                updateFieldData.type !== "button" &&
                updateFieldData.type !== "object" &&
                updateFieldData.type !== "action" &&
                ![
                  "create_uid",
                  "create_date",
                  "write_uid",
                  "write_date",
                  "logged_in_user",
                  "static_image",
                ].includes(updateFieldData.type) &&
                selectedFieldData.tabName === undefined && (
                  <p
                    style={{
                      fontSize: "12px",
                      width: "100%",
                      marginBottom: "0rem",
                    }}
                  >
                    Technical Name:{" "}
                    <span
                      style={{ wordBreak: "break-word", lineBreak: "anywhere" }}
                    >
                      {updateFieldData.technicalName}
                    </span>
                  </p>
                )} */}
            </div>
            {/* field code for technical field */}
            {/* {updateFieldData.type !== "tab" &&
              updateFieldData.type !== "table" &&
              updateFieldData.type !== "separator" &&
              updateFieldData.type !== "button" &&
              updateFieldData.type !== "object" &&
              updateFieldData.type !== "action" &&
              selectedFieldData.tabName === undefined && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="technicalName">Technical Name</label>
                  <input
                    type="text"
                    name="technicalName"
                    id="technicalName"
                    value={updateFieldData.technicalName}
                    onChange={handleTechnicalNameChange}
                  />
                  {technicalNameError !== "" && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {technicalNameError}
                    </span>
                  )}
                </div>
              )} */}

            {/* field code for type field */}
            {![
              "group",
              "selection",
              "chatter",
              "tab",
              "remark_history",
              "table",
              "separator",
              "label",
              "ribbon",
              "static_image",
              "oe_avatar",
            ].includes(updateFieldData.type) &&
              !["remark_field"].includes(updateFieldData.widget) &&
              selectedFieldData.tabName === undefined && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputTypeSelect">Field Type</label>
                  <select
                    id="inputTypeSelect"
                    name="type"
                    value={updateFieldData.type}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                    disabled
                    // disabled={updateFieldData.type === "char" ? false : true}
                    style={selectStyle}
                  >
                    {inputTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            {/* field code for widget field */}
            {![
              "group",
              "selection",
              "chatter",
              "tab",
              "remark_history",
              "table",
              "separator",
              "label",
              "ribbon",
              "static_image",
              "oe_avatar",
              "tabPage",
              "button",
              "object",
              "action",
            ].includes(updateFieldData.type) &&
              !["remark_field"].includes(updateFieldData.widget) &&
              updateFieldData.type !== "group" && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputWidgetSelect">Widget</label>
                  <select
                    id="inputWidgetSelect"
                    name="widget"
                    value={updateFieldData.widget}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                    disabled
                    // disabled={updateFieldData.widget === "char" ? false : true}
                    style={selectStyle}
                  >
                    {widgets.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            {/* field code for label alignment field */}
            {["label", "button", "action", "object", "static_image"].includes(
              updateFieldData.type
            ) &&
            !(
              updateFieldData.type === "object" &&
              updateFieldData.widget === "statinfo"
            ) ? (
              <>
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputAlignmentSelect">Alignment</label>
                  <CustomDropdown
                    name="alignment"
                    defaultValue={updateFieldData.alignment}
                    options={[
                      { label: "Left Align", value: "left" },
                      { label: "Center Align", value: "center" },
                      { label: "Right Align", value: "right" },
                    ]}
                    onChange={(event) => handleChangeData(event)}
                    onBlur={handleBlurData}
                  />
                  {/* <select
                    id="inputAlignmentSelect"
                    name="alignment"
                    value={updateFieldData.alignment}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                  >
                    <option value="left">Left Align</option>
                    <option value="center">Center Align</option>
                    <option value="right">Right Align</option>
                  </select> */}
                </div>
                {updateFieldData.type !== "static_image" &&
                  updateFieldData.type !== "object" &&
                  updateFieldData.type !== "action" &&
                  updateFieldData.type !== "button" && (
                    <>
                      <div className="fb_field_details_form_group">
                        <label htmlFor="inputFontSizeSelect">Font Size</label>
                        <CustomDropdown
                          name="fontSize"
                          defaultValue={updateFieldData.fontSize || "24"}
                          options={[
                            "14",
                            "16",
                            "18",
                            "20",
                            "24",
                            "28",
                            "32",
                            "36",
                          ]}
                          onChange={(event) => handleChangeData(event)}
                          onBlur={handleBlurData}
                        />
                      </div>
                      <div className="fb_field_details_form_group">
                        <label htmlFor="inputFontWeightSelect">
                          Font Weight
                        </label>
                        <CustomDropdown
                          name="fontWeight"
                          defaultValue={updateFieldData.fontWeight || "500"}
                          options={["400", "500", "600", "700", "800"]}
                          onChange={(event) => handleChangeData(event)}
                          onBlur={handleBlurData}
                        />
                        {/* <select
                          id="inputFontWeightSelect"
                          name="fontWeight"
                          value={updateFieldData.fontWeight || "500"}
                          onChange={handleChangeData}
                          onBlur={handleBlurData}
                        >
                          <option value="400">400</option>
                          <option value="500">500</option>
                          <option value="600">600</option>
                          <option value="700">700</option>
                          <option value="800">800</option>
                        </select> */}
                      </div>
                    </>
                  )}
              </>
            ) : null}
            {/* field code for default value field */}
            {![
              "group",
              "selection",
              "chatter",
              "tab",
              "remark_history",
              "table",
              "separator",
              "label",
              "ribbon",
              "oe_avatar",
              "tabPage",
              "button",
              "object",
              "action",
            ].includes(updateFieldData.type) &&
              !["remark_field"].includes(updateFieldData.widget) && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputDefaultValue">Default Value</label>
                  {["oe_avatar", "static_image"].includes(
                    updateFieldData.type
                  ) ? (
                    <>
                      {updateFieldData?.defaultValue !== "" ? (
                        <div
                          className="d-flex justify-content-between align-items-center ms-3"
                          style={{ width: "90%" }}
                        >
                          <li>{updateFieldData?.fileName}</li>
                          <button
                            className="fdu_group_delete"
                            onClick={(e) => handleFileRemove(e)}
                          >
                            <GiCancel />
                          </button>
                        </div>
                      ) : (
                        <input
                          type="file"
                          accept="image/*"
                          id="inputDefaultValue"
                          name="defaultValue"
                          placeholder="Enter default value"
                          className="form-control"
                          onChange={handleFileChange}
                          onBlur={handleBlurData}
                        />
                      )}
                    </>
                  ) : (
                    <input
                      type="text"
                      id="inputDefaultValue"
                      name="defaultValue"
                      placeholder="Enter default value"
                      value={updateFieldData.defaultValue}
                      onChange={handleChangeData}
                      onBlur={handleBlurData}
                    />
                  )}
                </div>
              )}
            {/* code for selection field options */}
            {![
              "group",
              "chatter",
              "tab",
              "remark_history",
              "table",
              "separator",
              "label",
              "ribbon",
              "tabPage",
            ].includes(updateFieldData.type) &&
              updateFieldData.options && (
                // old code for selection list and option
                // <div className="fb_field_details_form_group">
                //   <details style={{ width: "100%" }}>
                //     <summary>
                //       <label htmlFor="inputSelectionOptions">Options</label>
                //     </summary>
                //     <ul
                //       className="d-flex flex-column gap-1 mb-0 ps-4"
                //       style={{ width: "100%" }}
                //     >
                //       {updateFieldData.options &&
                //         updateFieldData.options.map((option, index) => (
                //           <li
                //             key={index}
                //             className="d-flex justify-content-between align-items-center gap-3"
                //           >
                //             <span style={{ fontSize: "14px" }}>{option}</span>
                //             <button
                //               className="select_option_delete"
                //               onClick={(e) => handleOptionDelete(e, index)}
                //             >
                //               <MdDeleteOutline
                //                 style={{ fontSize: "20px", color: "#ddd" }}
                //               />
                //             </button>
                //           </li>
                //         ))}
                //       {!showInputFieldOpt && (
                //         <li
                //           className="d-flex justify-content-start align-items-center gap-1"
                //           style={{ cursor: "pointer" }}
                //           onClick={() => setShowInputFieldOpt(true)}
                //         >
                //           <CiEdit style={{ fontSize: "20px" }} />
                //           <span className="selection_option_add_more_button">
                //             Add more
                //           </span>
                //         </li>
                //       )}
                //       {showInputFieldOpt && (
                //         <div
                //           className="d-flex justify-content-center align-items-center gap-2"
                //           style={{}}
                //         >
                //           <input
                //             type="text"
                //             value={newOpt}
                //             placeholder="enter option"
                //             className="rounded-1"
                //             style={{
                //               backgroundColor: "white",
                //               color: "#222222",
                //               paddingLeft: "5px",
                //             }}
                //             onChange={handleAddMoreOptChange}
                //           />
                //           <button
                //             className="fdu_group_add_button"
                //             onClick={handleAddNewOpt}
                //           >
                //             <MdOutlineCheck />
                //           </button>
                //           <button
                //             className="fdu_group_close_button"
                //             onClick={() => setShowInputFieldOpt(false)}
                //           >
                //             <MdOutlineClose />
                //           </button>
                //         </div>
                //       )}
                //     </ul>
                //   </details>
                // </div>

                // new code for options selection
                <div className="fb_field_details_form_group">
                  <details style={{ width: "100%" }}>
                    <summary>
                      <label htmlFor="inputSelectionOptions">Options</label>
                    </summary>
                    <ul
                      className="d-flex flex-column gap-1 mb-0 ps-4"
                      style={{ width: "100%" }}
                    >
                      {updateFieldData.options &&
                        updateFieldData.options.map((option, index) => (
                          <li
                            key={index}
                            className="d-flex justify-content-between align-items-center gap-3"
                            draggable
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, index)}
                            style={{
                              cursor: "move",
                              fontSize: "14px",
                              padding: "5px 0",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            <div className="d-flex justify-content-start align-items-center gap-1">
                              <RxDragHandleDots2 style={{ fontSize: "20px" }} />
                              <span>{option}</span>
                            </div>
                            <button
                              className="select_option_delete"
                              onClick={(e) => handleOptionDelete(e, index)}
                            >
                              <MdDeleteOutline
                                style={{ fontSize: "20px", color: "#ddd" }}
                              />
                            </button>
                          </li>
                        ))}

                      {!showInputFieldOpt && (
                        <li
                          className="d-flex justify-content-start align-items-center gap-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowInputFieldOpt(true)}
                        >
                          <CiEdit style={{ fontSize: "20px" }} />
                          <span className="selection_option_add_more_button">
                            Add more
                          </span>
                        </li>
                      )}

                      {showInputFieldOpt && (
                        <div
                          className="d-flex justify-content-center align-items-center gap-2"
                          style={{}}
                        >
                          <input
                            type="text"
                            value={newOpt}
                            placeholder="enter option"
                            className="rounded-1"
                            style={{
                              backgroundColor: "white",
                              color: "#222222",
                              paddingLeft: "5px",
                            }}
                            onChange={handleAddMoreOptChange}
                          />
                          <button
                            className="fdu_group_add_button"
                            onClick={handleAddNewOpt}
                          >
                            <MdOutlineCheck />
                          </button>
                          <button
                            className="fdu_group_close_button"
                            onClick={() => setShowInputFieldOpt(false)}
                          >
                            <MdOutlineClose />
                          </button>
                        </div>
                      )}
                    </ul>
                  </details>
                </div>
              )}
            {/* field code for relational fields */}
            {["many2many", "many2one", "one2many"].includes(
              updateFieldData.type
            ) ? (
              <ManyToManyFields
                updateFieldData={updateFieldData}
                setUpdateFieldData={setUpdateFieldData}
                handleChangeData={handleChangeData}
                odooModels={odooModels}
                filteredRelatedField={filteredRelatedField}
                handleBlurData={handleBlurData}
                selectedModalFields={selectedModalFields}
                formData={formData}
                selectedFieldData={selectedFieldData}
              />
            ) : null}
            {/* field code for placeholder field */}
            {![
              "group",
              "selection",
              "chatter",
              "tab",
              "remark_history",
              "table",
              "separator",
              "label",
              "ribbon",
              "static_image",
              "oe_avatar",
              "tabPage",
              "button",
              "object",
              "action",
            ].includes(updateFieldData.type) &&
              !["remark_field"].includes(updateFieldData.widget) &&
              selectedFieldData.tabName === undefined && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="fieldPlaceHolder">Placeholder</label>
                  <input
                    type="text"
                    id="fieldPlaceHolder"
                    name="placeholder"
                    placeholder="Enter placeholder"
                    value={updateFieldData.placeholder}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                  />
                </div>
              )}

            {/* code for button class name */}
            {["button", "object", "action"].includes(updateFieldData.type) &&
              !(updateFieldData.widget === "statinfo") && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputClassSelect">Class</label>
                  <CustomDropdown
                    name="odooButtonColor"
                    defaultValue={
                      updateFieldData.odooButtonColor || "btn-outline-dark"
                    }
                    options={odooButtonColors}
                    onChange={(event) => handleChangeData(event)}
                    onBlur={handleBlurData}
                  />
                  {/* <select
                    id="inputClassSelect"
                    name="odooButtonColor"
                    value={
                      updateFieldData.odooButtonColor || "btn-outline-dark"
                    }
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                  >
                    {odooButtonColors.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select> */}
                </div>
              )}
            {/* code for smart button icon */}
            {updateFieldData.type === "object" &&
              updateFieldData.widget === "statinfo" && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputIconSelect">Icon</label>
                  <Select
                    id="inputIconSelect"
                    name="smartIcon"
                    value={
                      updateFieldData.smartIcon
                        ? smart_icons.find(
                            (icon) => icon.value === updateFieldData.smartIcon
                          )
                        : { value: "select", label: "Select" }
                    }
                    onChange={handleSmartIconChange}
                    onBlur={handleBlurData}
                    options={smart_icons}
                    isClearable
                    unstyled
                    styles={customStyles}
                  />
                  {/* <select
                    id="inputClassSelect"
                    name="odooButtonColor"
                    value={updateFieldData.odooButtonColor}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                  >
                    {odooButtonColors.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select> */}
                </div>
              )}
            {/* field code for tooltip field */}
            {![
              "chatter",
              "remark_history",
              "ribbon",
              "static_image",
              "oe_avatar",
            ].includes(updateFieldData.type) &&
              selectedFieldData.tabName === undefined &&
              !["remark_field"].includes(updateFieldData.widget) &&
              updateFieldData.technicalName !== "x_o2b_remark_table" && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="fieldToolTip">Tooltip</label>
                  <input
                    type="text"
                    id="fieldToolTip"
                    name="tooltip"
                    placeholder="Enter tooltip text"
                    value={updateFieldData.tooltip}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                  />
                </div>
              )}

            {/* field code for table columns field */}
            {updateFieldData?.type === "table" &&
              updateFieldData.technicalName !== "x_o2b_remark_table" &&
              updateFieldData?.columns && (
                <div className="fb_field_details_form_group">
                  <details style={{ width: "100%" }}>
                    <summary>
                      <label htmlFor="inputSelectionOptions">Columns</label>
                    </summary>
                    <ul
                      className="d-flex flex-column gap-1 mb-0 ps-4"
                      style={{ width: "100%" }}
                    >
                      {/* code for display existing columns */}
                      {updateFieldData?.columns?.map((column, index) => (
                        <li
                          key={index}
                          className="d-flex justify-content-between align-items-start gap-3"
                          style={{ width: "100%" }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                              width: "80%",
                              wordBreak: "break-all",
                              wordWrap: "break-word",
                              lineBreak: "anywhere",
                            }}
                          >
                            <strong className="me-2">{index + 1}</strong>
                            {updateFieldData?.tableType === "existing"
                              ? column
                              : column.title}
                          </span>
                          <button
                            className="select_option_delete"
                            onClick={(e) => handleColumnDelete(e, index)}
                          >
                            <MdDeleteOutline
                              style={{ fontSize: "20px", color: "#ddd" }}
                            />
                          </button>
                        </li>
                      ))}
                      {/* code for table show table new field input box */}
                      {!showSelectColumn && (
                        <li
                          className="d-flex justify-content-start align-items-center gap-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowSelectColumn(true)}
                        >
                          <CiEdit style={{ fontSize: "20px" }} />
                          <span style={{ fontSize: "14px" }}>Add more</span>
                        </li>
                      )}
                      {/* code for existing table add field */}
                      {showSelectColumn &&
                        updateFieldData?.tableType === "existing" && (
                          <div className="d-flex justify-content-center align-items-center gap-2">
                            <select
                              name=""
                              id=""
                              onChange={handleTableColumnChange}
                              value={newColumn || ""}
                            >
                              <option value="">Select column</option>
                              {tableRelatedModelFields?.map((field) => (
                                <option key={field}>{field}</option>
                              ))}
                            </select>
                            <button
                              className="fdu_group_add_button"
                              onClick={handleAddNewColumn}
                            >
                              <MdOutlineCheck />
                            </button>
                            <button
                              className="fdu_group_close_button"
                              onClick={() => setShowSelectColumn(false)}
                            >
                              <MdOutlineClose />
                            </button>
                          </div>
                        )}

                      {/* code for new table add field */}
                      {showSelectColumn &&
                        updateFieldData?.tableType === "new" && (
                          <div className="d-flex flex-column justify-content-center align-items-start gap-1">
                            <label htmlFor="">Field Details:</label>
                            <div
                              className="d-flex flex-column justify-content-center align-content-start gap-1"
                              style={{
                                width: "95%",
                                marginTop: "-5px",
                                marginLeft: "5px",
                              }}
                            >
                              <label htmlFor="newTableColumnName">
                                Field Name
                              </label>
                              <input
                                type="text"
                                id="newTableColumnName"
                                name="title"
                                value={newtableColumnFields.title}
                                onChange={handleNewTableColumnChange}
                                placeholder="enter field name"
                                style={{
                                  width: "100%",
                                  height: "25px",
                                  fontSize: "14px",
                                }}
                              />
                              <span
                                style={{ fontSize: "10px", marginLeft: "5px" }}
                              >
                                Technical Name:{" "}
                                {newtableColumnFields.technicalName}
                              </span>
                            </div>
                            <div
                              className="d-flex flex-column justify-content-center align-content-start gap-1"
                              style={{ width: "95%", marginLeft: "5px" }}
                            >
                              <label htmlFor="newTableColumnType">
                                Field Type
                              </label>
                              <select
                                name="type"
                                id="newTableColumnType"
                                onChange={handleNewTableColumnChange}
                                value={newtableColumnFields.type || "char"}
                                style={{
                                  width: "100%",
                                  height: "25px",
                                  fontSize: "14px",
                                }}
                              >
                                {tableFieldTypes?.map((type) => (
                                  <option key={type}>{type}</option>
                                ))}
                              </select>
                            </div>
                            <div className="d-flex justify-content-center align-content-start gap-1">
                              <button
                                className="fdu_group_add_button"
                                onClick={handleAddNewTableColumn}
                              >
                                <MdOutlineCheck />
                              </button>
                              <button
                                className="fdu_group_close_button"
                                onClick={() => setShowSelectColumn(false)}
                              >
                                <MdOutlineClose />
                              </button>
                            </div>
                          </div>
                        )}
                    </ul>
                  </details>
                </div>
              )}

            {/* field code for group field */}
            {selectedFieldData.tabName === undefined &&
              updateFieldData.type !== "chatter" &&
              !["remark_field"].includes(updateFieldData.widget) &&
              updateFieldData.type !== "static_image" &&
              updateFieldData.technicalName !== "x_o2b_remark_table" &&
              updateFieldData.type !== "ribbon" && (
                <div className="fb_field_details_form_group">
                  <label htmlFor="availGroups">Group Visibilty</label>
                  <div className="fdu_group_box">
                    {updateFieldData.groupVisible &&
                      updateFieldData.groupVisible.length > 0 && (
                        <ul>
                          <>
                            {updateFieldData.groupVisible.length > 0 &&
                              updateFieldData.groupVisible.map(
                                (names, index) => (
                                  <li key={index} className="fdu_group_list">
                                    <span style={{ fontSize: "14px" }}>
                                      {index + 1}.
                                    </span>
                                    <span className="fdu_group_name_span">
                                      {names}
                                    </span>
                                    <button
                                      className="fdu_group_delete"
                                      onClick={(e) => handleGrpDelete(e, index)}
                                    >
                                      <GiCancel />
                                    </button>
                                  </li>
                                )
                              )}
                          </>
                        </ul>
                      )}
                    {showGrpInput ? (
                      <div className="fdu_group_input_box">
                        <select
                          name="group"
                          id="group"
                          onChange={handleAddNewGroup}
                          onBlur={handleBlurData}
                        >
                          <option value="">Select Group</option>
                          {filteredGroup?.map((group, index) => (
                            <option
                              key={index}
                              value={`base.${group.group_internal}`}
                            >
                              {group.group_name}
                              {/* {`(base.${group.group_internal})`} */}
                            </option>
                          ))}
                          {/* {groups?.map((group, index) => (
                            <option
                              key={index}
                              value={`base.${group.group_internal}`}
                            >
                              {group.group_name}
                              {`(base.${group.group_internal})`}
                            </option>
                          ))} */}
                        </select>
                        <button
                          className="fdu_group_close_button"
                          onClick={() => setShowGrpInput(false)}
                        >
                          <MdOutlineClose />
                        </button>
                        {/* <input
                          type="text"
                          list="availGroups"
                          name="groupVisible"
                          placeholder="Enter group name"
                          value={newGrp}
                          onChange={(e) => setNewGrp(e.target.value)}
                          onBlur={handleBlurData}
                        />
                        <datalist id="availGroups">
                          {groups &&
                            groups.map((groupName, index) => (
                              <option
                                key={index}
                                value={`base.${groupName.group_internal}`}
                              ></option>
                            ))}
                        </datalist>
                        <button
                          className="fdu_group_add_button"
                          onClick={handleAddNewGroup}
                        >
                          <MdOutlineCheck />
                        </button>
                        <button
                          className="fdu_group_close_button"
                          onClick={() => setShowGrpInput(false)}
                        >
                          <MdOutlineClose />
                        </button> */}
                      </div>
                    ) : (
                      <>
                        <span
                          onClick={() => setShowGrpInput(true)}
                          className="fdu_group_show_input"
                        >
                          Add group
                        </span>
                      </>
                    )}
                  </div>
                  {/* <input
                type="text"
                id="fieldGroupVisible"
                name="groupVisible"
                value={updateFieldData.groupVisible}
                onChange={handleChangeData}
              /> */}
                </div>
              )}

            {/* field code for checkboxes and required and readonly field */}
            {![
              "group",
              "chatter",
              "tab",
              "remark_history",
              "table",
              "separator",
              "label",
              "ribbon",
              "static_image",
              "button",
              "object",
              "action",
            ].includes(updateFieldData.type) &&
              !["remark_field"].includes(updateFieldData.widget) &&
              selectedFieldData.tabName === undefined && (
                <>
                  {/* <div className="fb_field_details_form_group_checkbox_container">
                    <label htmlFor="fieldRequired">Required</label>
                    <input
                      type="checkbox"
                      id="fieldRequired"
                      name="isRequired"
                      checked={updateFieldData.isRequired}
                      onChange={handleCheckBoxChange}
                      onBlur={handleBlurData}
                    />
                  </div> */}
                  {![
                    "create_uid",
                    "create_date",
                    "write_uid",
                    "write_date",
                    "logged_in_user",
                  ].includes(updateFieldData.type) && (
                    <div className="fb_field_details_form_group_checkbox_container">
                      <label htmlFor="fieldRequired" className="label_checkbox">
                        Mandatory
                      </label>
                      <label className="checkbox_container">
                        <input
                          type="checkbox"
                          id="fieldRequired"
                          name="isRequired"
                          checked={updateFieldData.isRequired}
                          onChange={handleCheckBoxChange}
                          onBlur={handleBlurData}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  )}
                  {/* <div className="fb_field_details_form_group_checkbox_container">
                    <label htmlFor="fieldReadOnly">Read Only</label>
                    <input
                      type="checkbox"
                      id="fieldReadOnly"
                      name="isReadOnly"
                      checked={updateFieldData.isReadOnly}
                      onChange={handleCheckBoxChange}
                      onBlur={handleBlurData}
                    />
                  </div> */}
                  <div className="fb_field_details_form_group_checkbox_container">
                    <label htmlFor="fieldReadOnly" className="label_checkbox">
                      Read-only
                    </label>
                    <label className="checkbox_container">
                      <input
                        type="checkbox"
                        id="fieldReadOnly"
                        name="isReadOnly"
                        checked={updateFieldData.isReadOnly}
                        onChange={handleCheckBoxChange}
                        onBlur={handleBlurData}
                        disabled={
                          [
                            "create_uid",
                            "create_date",
                            "write_uid",
                            "write_date",
                            "logged_in_user",
                          ].includes(updateFieldData.type) && true
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  {/* <div className="fb_field_details_form_group_checkbox_container">
                    <label htmlFor="fieldTracking">Tracking</label>
                    <input
                      type="checkbox"
                      id="fieldTracking"
                      name="isTracking"
                      checked={updateFieldData.isTracking}
                      onChange={handleCheckBoxChange}
                      onBlur={handleBlurData}
                    />
                  </div> */}
                  <div className="fb_field_details_form_group_checkbox_container">
                    <label htmlFor="fieldTracking" className="label_checkbox">
                      Trackable
                    </label>
                    <label className="checkbox_container">
                      <input
                        type="checkbox"
                        id="fieldTracking"
                        name="isTracking"
                        checked={updateFieldData.isTracking}
                        onChange={handleCheckBoxChange}
                        onBlur={handleBlurData}
                        disabled={
                          [
                            "create_uid",
                            "create_date",
                            "write_uid",
                            "write_date",
                            "logged_in_user",
                          ].includes(updateFieldData.type) && true
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  {/* <div className="fb_field_details_form_group_checkbox_container">
                    <label htmlFor="fieldCreatedBy">Created By</label>
                    <input
                      type="checkbox"
                      id="fieldCreatedBy"
                      name="isCreatedBy"
                      checked={updateFieldData.isCreatedBy}
                      onChange={handleCheckBoxChange}
                      onBlur={handleBlurData}
                    />
                  </div> */}
                  {/* <div className="fb_field_details_form_group_checkbox_container">
                    <label htmlFor="fieldCreatedBy" className="label_checkbox">
                      Created By
                    </label>
                    <label className="checkbox_container">
                      <input
                        type="checkbox"
                        id="fieldCreatedBy"
                        name="isCreatedBy"
                        checked={updateFieldData.isCreatedBy}
                        onChange={handleCheckBoxChange}
                        onBlur={handleBlurData}
                      />
                      <span className="checkmark" />
                    </label>
                  </div> */}
                  {/* <div className="fb_field_details_form_group_checkbox_container">
                    <label htmlFor="fieldLoggedinUser">Logged-in user</label>
                    <input
                      type="checkbox"
                      id="fieldLoggedinUser"
                      name="isLoggedinUser"
                      checked={updateFieldData.isLoggedinUser}
                      onChange={handleCheckBoxChange}
                      onBlur={handleBlurData}
                    />
                  </div> */}
                  {/* <div className="fb_field_details_form_group_checkbox_container">
                    <label
                      htmlFor="fieldLoggedinUser"
                      className="label_checkbox"
                    >
                      Logged-in user
                    </label>
                    <label className="checkbox_container">
                      <input
                        type="checkbox"
                        id="fieldLoggedinUser"
                        name="isLoggedinUser"
                        checked={updateFieldData.isLoggedinUser}
                        onChange={handleCheckBoxChange}
                        onBlur={handleBlurData}
                      />
                      <span className="checkmark" />
                    </label>
                  </div> */}
                  {/* <div className="fb_field_details_form_group_checkbox_container">
                    <label htmlFor="fieldOnChange">On-change</label>
                    <input
                      type="checkbox"
                      id="fieldOnChange"
                      name="isOnChange"
                      checked={updateFieldData.isOnChange}
                      onChange={handleCheckBoxChange}
                      onBlur={handleBlurData}
                    />
                  </div> */}
                  <div className="fb_field_details_form_group_checkbox_container">
                    <label htmlFor="checkcustomOne" className="label_checkbox">
                      On-change
                    </label>
                    <label className="checkbox_container">
                      <input
                        type="checkbox"
                        id="checkcustomOne"
                        name="isOnChange"
                        checked={updateFieldData.isOnChange}
                        onChange={handleCheckBoxChange}
                        onBlur={handleBlurData}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </>
              )}
            {/* field code for invisible field */}
            {!["chatter", "remark_history", "ribbon"].includes(
              updateFieldData.type
            ) &&
              updateFieldData.technicalName !== "x_o2b_remark_table" &&
              updateFieldData.widget !== "remark_field" && (
                // <div className="fb_field_details_form_group_checkbox_container">
                //   <label htmlFor="fieldInvisible">Invisible</label>
                //   <input
                //     type="checkbox"
                //     id="fieldInvisible"
                //     name="isInvisible"
                //     checked={updateFieldData.isInvisible}
                //     onChange={handleCheckBoxChange}
                //     onBlur={handleBlurData}
                //   />
                // </div>
                <div className="fb_field_details_form_group_checkbox_container">
                  <label htmlFor="fieldInvisible" className="label_checkbox">
                    Hidden
                  </label>
                  <label className="checkbox_container">
                    <input
                      type="checkbox"
                      id="fieldInvisible"
                      name="isInvisible"
                      checked={updateFieldData.isInvisible}
                      onChange={handleCheckBoxChange}
                      onBlur={handleBlurData}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              )}

            {/* field code for code editor button */}
            {["button", "object", "action"].includes(updateFieldData.type) ? (
              <>
                {!updateFieldData.pythonCode && (
                  <button
                    className="code_editor_button"
                    onClick={handleCodeEditorClick}
                  >
                    Write Python Code
                  </button>
                )}
                {updateFieldData.pythonCode && (
                  <div
                    style={{ width: "90%" }}
                    className="d-flex justify-content-between align-content-center"
                  >
                    <span style={{ fontSize: "14px" }}>Python Code</span>
                    <button
                      className="cde_edit_button"
                      onClick={handleCodeEditorClick}
                    >
                      Edit
                    </button>
                  </div>
                )}
              </>
            ) : (
              ""
            )}

            {/* field code for ribbon */}
            {updateFieldData.type === "ribbon" && (
              <>
                <div className="fb_field_details_form_group">
                  <label htmlFor="inputRibbonBgSelect">Background Color</label>
                  <select
                    id="inputRibbonBgSelect"
                    name="ribbonBgColor"
                    value={updateFieldData.ribbonBgColor || "bg-danger"}
                    onChange={handleChangeData}
                    onBlur={handleBlurData}
                    // style={selectStyle}
                  >
                    <option value="bg-danger">bg-danger</option>
                    <option value="bg-success">bg-success</option>
                    <option value="bg-primary">bg-primary</option>
                    <option value="bg-warning">bg-warning</option>
                    <option value="bg-secondary">bg-secondary</option>
                    <option value="bg-info">bg-info</option>
                    <option value="bg-dark">bg-dark</option>
                  </select>
                </div>
              </>
            )}

            <button type="submit" className="btn fb_field_details_form_submit">
              Save
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default memo(FormDetailUpdate);

const ManyToManyFields = ({
  formData,
  updateFieldData,
  handleChangeData,
  odooModels,
  filteredRelatedField,
  handleBlurData,
  selectedModalFields,
  setUpdateFieldData,
  selectedFieldData,
  // filteredOptions,
  // searchTerm,
  // handleModelSearchChange,
}) => {
  const options = odooModels?.map((option) => ({
    value: option.model,
    label: option.model,
  }));

  // Handler for when an option is selected
  const handleSelectChange = (selectedOption) => {
    const event = {
      target: {
        name: "relatedModel",
        value: selectedOption ? selectedOption.value : "",
      },
    };
    handleChangeData(event);
  };

  // Handle for domin delete
  const handleDomainDelete = (e) => {
    e.preventDefault();
    updateFieldData.domain = null;
    // setUpdateFieldData({ ...updateFieldData, domain: null });
    console.log("clicked update Field::: ", updateFieldData);
    handleBlurData();
  };

  // console.log("options data:::::: ", options);
  // console.log("odooModals data:::::: ", odooModels);
  console.log("domain data:::::: ", JSON.stringify(updateFieldData.domain));
  return (
    <>
      <div className="fb_field_details_form_group">
        <label htmlFor="relatedModelSelect">Related Model</label>
        <Select
          id="relatedModelSelect"
          name="relatedModel"
          value={
            updateFieldData.relatedModel
              ? options.find(
                  (option) => option.value === updateFieldData.relatedModel
                )
              : { value: "select", label: "Select" }
          }
          onChange={handleSelectChange}
          onBlur={handleBlurData}
          options={options}
          isClearable
          unstyled
          styles={customStyles}
        />
      </div>
      {updateFieldData.type !== "many2one" && (
        <>
          {updateFieldData.type === "many2many" && (
            <div className="fb_field_details_form_group">
              <label htmlFor="inputRelationTable">Relation Table</label>
              <input
                type="text"
                name="relationTable"
                id="inputRelationTable"
                placeholder="Enter details here..."
                value={updateFieldData.relationTable || ""}
                onChange={handleChangeData}
                onBlur={handleBlurData}
              />
            </div>
          )}
          {updateFieldData.type === "one2many" && (
            <div className="fb_field_details_form_group">
              <label htmlFor="inputRelationField">Relation Field</label>
              <input
                type="text"
                name="relationField"
                id="inputRelationField"
                list="relatedFieldList"
                placeholder="Enter details here..."
                value={updateFieldData.relationField || ""}
                onChange={handleChangeData}
                onBlur={handleBlurData}
              />
              <datalist id="relatedFieldList">
                {filteredRelatedField?.map((relatedField, index) => (
                  <option key={index} value={relatedField.name}></option>
                ))}
              </datalist>
            </div>
          )}
        </>
      )}
      <div className="fb_field_details_form_group">
        <label>Domain</label>
        {updateFieldData?.domain ? (
          <div
            style={{ width: "90%" }}
            className="ms-3 d-flex justify-content-between align-items-center"
          >
            <p className="mb-0">
              <span>
                <TbPointFilled />
              </span>
              <span
                style={{
                  fontSize: "14px",
                  wordBreak: "break-all",
                  lineBreak: "anywhere",
                  wordWrap: "break-word",
                }}
              >
                {updateFieldData?.domain}
              </span>
            </p>
            <button className="fdu_group_delete" onClick={handleDomainDelete}>
              <GiCancel />
            </button>
          </div>
        ) : (
          <DomainComponent
            selectedModalFields={selectedModalFields}
            updateFieldData={updateFieldData}
            handleBlurData={handleBlurData}
          />
        )}
      </div>
    </>
  );
};
