import React, { useEffect, useState } from "react";
import "./ConditionFields.css";
import { useFormContext } from "../../context/FormContext";
import { FaRegTimesCircle } from "react-icons/fa";
import CustomDropdown from "../custom_dropdown/CustomDropdown";

const ConditionFields = ({
  index,
  handleConditionChange,
  handleRemoveCondition,
}) => {
  const { forms, parseFormData } = useFormContext();
  const [operands, setOperands] = useState(null);
  const [condition, setCondition] = useState({
    conditionOperand: "",
    conditionOperator: "=",
    conditionValue: "",
  });

  const handleConditionInputChange = (e) => {
    const { name, value } = e.target;
    const updatedCondition = { ...condition, [name]: value };
    setCondition(updatedCondition);
    handleConditionChange(index, updatedCondition);
  };
  // Get selected operand type
  const selectedOperand = operands?.find(
    (operand) => operand.technicalName === condition.conditionOperand
  );
  // useEffect for getting all fields data
  // useEffect(() => {
  //   if (forms) {
  //     const oprs = [];
  //     forms.forEach((form) => {
  //       if (form.formbuilder_data) {
  //         const opr = parseFormData(form.formbuilder_data);
  //         opr.forEach((op) => {
  //           if (
  //             op.type !== "chatter" &&
  //             op.type !== "button" &&
  //             op.type !== "table" &&
  //             op.type !== "tab" &&
  //             op.type !== "group" &&
  //             op.type !== "separator" &&
  //             op.type !== "label"
  //           ) {
  //             oprs.push(op);
  //           }
  //         });
  //       }
  //     });
  //     setOperands(oprs);
  //   }
  // }, [forms]);
  useEffect(() => {
    if (forms) {
      const uniqueOperands = new Map();

      forms.forEach((form) => {
        if (form.formbuilder_data) {
          const opr = parseFormData(form.formbuilder_data);
          opr.forEach((op) => {
            if (
              ![
                "static_image",
                "chatter",
                "button",
                "table",
                "tab",
                "group",
                "separator",
                "label",
              ].includes(op.type)
            ) {
              // Add to the map if the technicalName doesn't exist already
              if (!uniqueOperands.has(op.technicalName)) {
                uniqueOperands.set(op.technicalName, op);
              }
            }
          });
        }
      });

      // Convert the map values to an array
      setOperands([...uniqueOperands.values()]);
    }
  }, [forms]);

  // Render value field conditionally based on the operand type
  const renderValueField = () => {
    if (selectedOperand) {
      if (selectedOperand.type === "date") {
        return (
          <input
            type="date"
            name="conditionValue"
            value={condition.conditionValue}
            onChange={handleConditionInputChange}
            style={{ width: "85%", height: "25px", color: "#666666" }}
          />
        );
      } else if (selectedOperand.type === "selection") {
        return (
          <CustomDropdown
            name="conditionValue"
            defaultValue={condition.conditionValue || null}
            options={selectedOperand.options?.map((opt) => {
              return {
                value: `${opt.toLowerCase().trim().replace(/ /g, "_")}`,
                label: opt,
              };
            })}
            onChange={(event) => handleConditionInputChange(event)}
            width={"85%"}
            height={"25px"}
            border="1px solid gray"
            placeholder="value"
          />
          // <select
          //   name="conditionValue"
          //   value={condition.conditionValue}
          //   onChange={handleConditionInputChange}
          // >
          //   {/* Add options for selection based on specific operand data */}
          //   <option value="">Select value</option>
          //   {selectedOperand.options &&
          //     selectedOperand.options.map((option, index) => (
          //       <option key={index} value={option}>
          //         {option}
          //       </option>
          //     ))}
          // </select>
        );
      }
    }

    // Default case: return input type="text" for other operand types
    return (
      <>
        <input
          type="text"
          name="conditionValue"
          list="operandlist1"
          value={condition.conditionValue}
          placeholder="Value"
          onChange={handleConditionInputChange}
          style={{ width: "85%" }}
        />
        <datalist id="operandlist1">
          {operands &&
            operands.map((operand, index) => (
              <option key={index + "1"} value={operand.technicalName} />
            ))}
        </datalist>
      </>
      // <input
      //   type="text"
      //   name="conditionValue"
      //   value={condition.conditionValue}
      //   onChange={handleConditionInputChange}
      //   placeholder="Value"
      // />
    );
  };

  // console.log("forms: ", forms);
  console.log("operands: ", operands);

  return (
    <div className="condition_field_container">
      <CustomDropdown
        name="conditionOperand"
        defaultValue={condition.conditionOperand || null}
        options={operands?.map((field) => {
          return {
            value: field.technicalName,
            label: field.title,
          };
        })}
        onChange={(event) => handleConditionInputChange(event)}
        width={"60%"}
        height={"25px"}
        border="1px solid gray"
        placeholder="Operand"
      />
      {/* <select
        name="conditionOperand"
        value={condition.conditionOperand}
        onChange={handleConditionInputChange}
        className="condition_field_operand_selection"
      >
        <option value="">select operand</option>
        {operands &&
          operands.map((operand, index) => (
            <option key={index} value={operand.technicalName}>
              {operand.title}
            </option>
          ))}
      </select> */}
      {/* <input
        type="text"
        name="conditionOperand"
        list="operandlist"
        value={condition.conditionOperand}
        placeholder="Operand"
        onChange={handleConditionInputChange}
      />
      <datalist id="operandlist">
        {operands &&
          operands.map((operand, index) => (
            <option key={index} value={operand.technicalName} />
          ))}
      </datalist> */}
      <CustomDropdown
        name="conditionOperator"
        defaultValue={condition.conditionOperator || null}
        options={["=", "!=", ">", ">=", "<", "<=", "in", "not in"]}
        onChange={(event) => handleConditionInputChange(event)}
        width={"30%"}
        height={"25px"}
        border="1px solid gray"
        // placeholder="Operand"
      />
      {/* <select
        name="conditionOperator"
        value={condition.conditionOperator}
        onChange={handleConditionInputChange}
      >
        <option value="=">==</option>
        <option value="!=">!=</option>
        <option value=">">{">"}</option>
        <option value=">=">≥</option>
        <option value="<">{"<"}</option>
        <option value="<=">≤</option>
        <option value="in">in</option>
        <option value="not in">not in</option>
      </select> */}
      {/* <input
        type="text"
        name="conditionValue"
        list="operandlist1"
        value={condition.conditionValue}
        placeholder="Value"
        onChange={handleConditionInputChange}
      />
      <datalist id="operandlist1">
        {operands &&
          operands.map((operand, index) => (
            <option key={index + "1"} value={operand.technicalName} />
          ))}
      </datalist> */}
      {/* Conditionally render value field based on operand type */}
      {renderValueField()}
      <button
        onClick={() => handleRemoveCondition(index)}
        className="condition_field_remove_btn"
      >
        <FaRegTimesCircle />
      </button>
    </div>
  );
};

export default ConditionFields;
