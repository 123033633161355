import React, { useEffect, useState } from "react";
import "./ImportProcessOverlay.css";
import BASE_URL, { API_KEY, FILE_SECRET } from "../../../config";
import Swal from "sweetalert2";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import { useProcessContext } from "../../context/ProcessContext";
import { IoMdClose } from "react-icons/io";
import CustomDropdown from "../custom_dropdown/CustomDropdown";
import { useRef } from "react";

const ImportProcessOverlay = () => {
  const {
    userData,
    topRightAlert,
    fetchAllActiveDatabase,
    centerAlert,
    fetchAllGroups,
    groups,
    fetchAllProcessData,
    decryptData,
  } = useProcessContext();
  // const [loading, setLoading] = useState(false);
  const [activeDataBases, setActiveDatabases] = useState(null);
  const [fetchedProcess, setFetchedProcess] = useState(null);
  const [isUpdateClicked, setUpdateClicked] = useState(false);
  const [filteredGroup, setFilteredGroup] = useState(null);
  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const fileInputRef = useRef(null);

  // method for handle data chenges
  const processDataChange = (e) => {
    const { name, value } = e.target;
    if (name === "processName") {
      // fetchedProcess.process.process_name = value;
      setFetchedProcess({
        ...fetchedProcess,
        process: { ...fetchedProcess.process, process_name: value },
      });
    } else if (name === "processDetail") {
      // fetchedProcess.process.process_detail = value;
      setFetchedProcess({
        ...fetchedProcess,
        process: { ...fetchedProcess.process, process_detail: value },
      });
    } else if (name === "processDatabase") {
      // fetchedProcess.process.process_detail = value;
      setFetchedProcess({
        ...fetchedProcess,
        database: value,
      });
      setSelectedDatabase(value);
      // if (fetchedProcess.database) {
      const configDetail = activeDataBases?.find(
        (config) => config._id === value
      );
      if (configDetail) {
        setFetchedProcess((prevFetchedProcess) => ({
          ...prevFetchedProcess,
          process: {
            ...prevFetchedProcess.process,
            database_obj: value,
            database_url: configDetail.parameter,
            database: `${configDetail.configname} (${configDetail.database})`,
          },
        }));
      }
      // }
      console.log("selectedDatabase Value::::::: ", selectedDatabase);
    } else if (name === "processGroup") {
      // fetchedProcess.process.process_detail = value;
      setFetchedProcess({
        ...fetchedProcess,
        group: value,
      });
      // if (fetchedProcess.group) {
      setFetchedProcess((prevFetchedProcess) => ({
        ...prevFetchedProcess,
        process: { ...prevFetchedProcess.process, process_group: value },
      }));
      // }
    }
  };

  // method for getting active database
  const getData = async () => {
    const activeDatabases = await fetchAllActiveDatabase();
    console.log("Active Databases:", activeDatabases);
    setActiveDatabases(activeDatabases);
  };

  // fetching active database
  useEffect(() => {
    getData();
  }, []);

  // fetching all groups available in specific database
  useEffect(() => {
    if (selectedDatabase) {
      if (groups.length > 0) {
        const grps = groups.filter(
          (group) => group.database_obj === fetchedProcess.database
        );
        setFilteredGroup(grps);
      } else {
        fetchAllGroups();
      }
    }
  }, [selectedDatabase, groups]);

  const handleImport = (event) => {
    const file = event.target.files[0];

    if (file && file.name.endsWith(".oflow")) {
      // Ensure the file has the correct extension

      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const encryptedData = e.target.result;
          const decryptedData = decryptData(encryptedData, FILE_SECRET);
          const importedData = JSON.parse(decryptedData);
          // const importedData = decryptedData;
          const forms = JSON.parse(importedData.process_data_form);
          console.log("imported process:::: ", importedData);
          console.log(
            "All Forms Data:::::: ",
            JSON.parse(importedData.process_data_form)
          );
          const updatedForm = forms.map((form) => {
            if (form.form_builder_id !== null) {
              form.user_id = userData.userid;
              delete form["_id"];
              delete form["__v"];
              return form;
            } else {
              return form;
            }
          });
          console.log("updatedFormData:::: ", updatedForm);
          // setProcessName(importedData.process_name);
          const process = {
            ...importedData,
            user_id: userData.userid,
            process_data_form: JSON.stringify(updatedForm),
            is_module_created: false,
            odoo_checking_status: false,
            is_process_desing_completed: false,
          };
          delete process["_id"];
          delete process["__v"];
          topRightAlert("success", "Process Fetched...");
          console.log("updated Process at fetch::: ", process);
          // onImport(importedData);
          const importFitData = { process: process, forms: updatedForm };
          setFetchedProcess({ ...importFitData, database: "" });
        } catch (error) {
          event.target.value = "";
          topRightAlert("error", "Process data could not found.");
          console.error("Error parsing the imported file:", error);
        }
      };

      reader.readAsText(file);
    } else {
      event.target.value = "";
      centerAlert("error", "Please select a valid .oflow file");
      // alert("Please select a valid .oflow file.");
    }
  };

  const handleRemarkHistoryModelNameChange = (allNodeData) => {
    let nodeData = JSON.parse(allNodeData);

    nodeData = nodeData.map((node) => {
      // Check if the form is assigned and selected
      if (
        node.data.form &&
        node.data.isFormSelected &&
        node.data.form.form_builder_id
      ) {
        if (
          node.data.form.formbuilder_data &&
          Array.isArray(node.data.form.formbuilder_data)
        ) {
          // Iterate over each row in formbuilder_data
          let newFormData = [...node.data.form.formbuilder_data];
          newFormData = newFormData.map((row) => {
            // Check if the first object in the row is of type 'remark_history'
            if (row[0].type === "remark_history") {
              const tabs = row[0].tabs;
              if (tabs && Array.isArray(tabs) && tabs.length > 0) {
                const contentArray = tabs[0].content;
                // Check if content has the last object with type 'table'
                if (Array.isArray(contentArray) && contentArray.length > 0) {
                  const lastContentObject =
                    contentArray[contentArray.length - 1][0];

                  if (lastContentObject.type === "table") {
                    // Modify the tableRelatedField key with the new value
                    lastContentObject.tableRelatedField = `x_o2b_${fetchedProcess.process.process_name
                      .trim()
                      .toLowerCase()
                      .replace(/ /g, "_")}_remark_history`;
                    row[0].tabs[0].content[contentArray.length - 1][0] = {
                      ...lastContentObject,
                    };
                  }
                }
              }
            }
            return row;
          });

          // Update the formbuilder_data with the modified data
          node.data.form.formbuilder_data = [...newFormData];
        }
      }

      return node;
    });

    return JSON.stringify(nodeData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      fetchedProcess === null ||
      fetchedProcess.database === "" ||
      fetchedProcess.database === null
    ) {
      return centerAlert("error", "Hutch filed is mandatory to fill");
    }
    if (
      fetchedProcess === null ||
      fetchedProcess.group === "" ||
      fetchedProcess.group === null
    ) {
      return centerAlert("error", "Group access filed is mandatory to fill");
    }

    console.log("imported process data: ", JSON.stringify(fetchedProcess));
    try {
      const allNodeJsonData = handleRemarkHistoryModelNameChange(
        fetchedProcess.process.process_data_flow.nodesData
      );
      const mainData = {
        process: {
          ...fetchedProcess.process,
          process_data_flow: {
            ...fetchedProcess.process.process_data_flow,
            nodesData: allNodeJsonData,
          },
        },
        forms: fetchedProcess.forms,
        database: fetchedProcess.database,
        group: fetchedProcess.group,
      };
      console.log("mainData: ", mainData);
      const payload = {
        data: { ...mainData },
        // data: { ...fetchedProcess },
        api_key: API_KEY,
        user_id: userData.userid,
      };
      console.log("payload for import: ", payload);
      console.log("payload json for import: ", JSON.stringify(payload));
      const response = await fetch(`${BASE_URL}/process/import/module`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (response.ok) {
        console.log("response for import: ", data);
        fetchAllProcessData();
        topRightAlert("success", data.message);
        setFetchedProcess(null);
        setSelectedDatabase(null);
        // Reset the file input field
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        const modalElement = document.getElementById("processImportModal");
        const modalInstance =
          bootstrapBundleMin.Modal.getInstance(modalElement);
        modalInstance.hide();
      } else {
        console.log("response for import: ", data);
        centerAlert("error", data.message);
      }
    } catch (error) {
      centerAlert("error", error);
    }
  };
  // console.log("fetched process:::: ", fetchedProcess);
  // console.log("selectedDatabase:::: ", selectedDatabase);
  // console.log("groups:::: ", groups);
  // console.log("filteredGroup:::: ", filteredGroup);
  return (
    <div
      className="modal fade"
      id="processImportModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Import process
            </h1>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                position: "absolute",
                right: "20px",
                fontSize: "25px",
                color: "white",
              }}
            >
              <IoMdClose />
            </button>
          </div>
          <form method="post" onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="importProcessModalForm">
                <div className="importProcessModalForm_inputField">
                  <label htmlFor="formFile">Upload file</label>
                  <input
                    type="file"
                    id="formFile"
                    className="form-control"
                    placeholder="upload process icon"
                    name="process_icon"
                    onChange={handleImport}
                    accept=".oflow"
                    required
                    ref={fileInputRef}
                  />
                  <span>*only .oflow extension is accepted.</span>
                </div>
                {fetchedProcess && (
                  <>
                    {isUpdateClicked ? (
                      <>
                        <div className="importProcessModalForm_inputField">
                          <label htmlFor="processName">Process Name</label>
                          <input
                            type="text"
                            id="processName"
                            className="form-control"
                            placeholder="enter process name"
                            value={fetchedProcess?.process?.process_name}
                            name="processName"
                            onChange={processDataChange}
                            required
                          />
                        </div>
                        <div className="importProcessModalForm_inputField">
                          <label htmlFor="processDetail">Process Detail</label>
                          <input
                            type="text"
                            id="processDetail"
                            className="form-control"
                            placeholder="enter process detail"
                            value={fetchedProcess?.process?.process_detail}
                            name="processDetail"
                            onChange={processDataChange}
                            required
                          />
                        </div>
                        {/* <p>No. of Form: {fetchedProcess?.forms?.length}</p> */}
                        <button
                          className="btn btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            setUpdateClicked(!isUpdateClicked);
                          }}
                        >
                          Update
                        </button>
                      </>
                    ) : (
                      <>
                        {/* <div className="importProcessModalForm_inputField">
                          <label htmlFor="processDatabase">Hutch*</label>
                          <select
                            id="processDatabase"
                            name="processDatabase"
                            value={fetchedProcess.processDatabase}
                            onChange={processDataChange}
                            className="processModalFormSelectField"
                            aria-label="Default select example"
                            required
                          >
                            <option value="">Choose hutch</option>
                            {activeDataBases?.map((db) => (
                              <option
                                key={db._id}
                                value={db._id}
                              >{`${db.configname} (${db.database})`}</option>
                            ))}
                          </select>
                        </div> */}
                        {/* ============================================================ */}

                        <div className="importProcessModalForm_inputField">
                          <label htmlFor="processDatabase">Hutch*</label>
                          <CustomDropdown
                            name="processDatabase"
                            defaultValue={fetchedProcess.database || null}
                            options={activeDataBases?.map((db) => {
                              return {
                                value: db._id,
                                label: `${db.configname} (${db.database})`,
                              };
                            })}
                            onChange={(event) => processDataChange(event)}
                            width={"95%"}
                            height={"35px"}
                            border="1px solid gray"
                          />
                        </div>
                        <div className="importProcessModalForm_inputField">
                          <label htmlFor="processGroup">Group Access*</label>
                          <CustomDropdown
                            name="processGroup"
                            defaultValue={fetchedProcess?.group || null}
                            options={filteredGroup?.map((group) => {
                              return {
                                value: `base.${group.group_internal}`,
                                label: group.group_name,
                              };
                            })}
                            onChange={(event) => processDataChange(event)}
                            width={"95%"}
                            height={"35px"}
                            border="1px solid gray"
                          />
                        </div>

                        {/* ============================================================ */}
                        <div className="importProcessModalForm_inputField_fetch">
                          <label htmlFor="processName">Process Name</label>
                          <span>:</span>
                          <span style={{ width: "100%" }}>
                            {fetchedProcess?.process?.process_name}
                          </span>
                        </div>
                        <div className="importProcessModalForm_inputField_fetch">
                          <label htmlFor="processDetail">Process Detail</label>
                          <span>:</span>
                          <p>{fetchedProcess?.process?.process_detail}</p>
                        </div>
                        <div className="importProcessModalForm_inputField_fetch">
                          <label htmlFor="processDetail">No. of Forms:</label>
                          <span>:</span>
                          <span style={{ width: "100%" }}>
                            {fetchedProcess?.forms?.length}
                          </span>
                        </div>
                        <button
                          className="btn modal_process_update_button"
                          onClick={(e) => {
                            e.preventDefault();
                            setUpdateClicked(!isUpdateClicked);
                          }}
                        >
                          Update Process Data
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn modal_save_button">
                Import
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ImportProcessOverlay;
