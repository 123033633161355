import React, { useEffect, useState } from "react";
import "./GroupModalOverlay.css";
import BASE_URL, { API_KEY } from "../../../config";
import { useProcessContext } from "../../context/ProcessContext";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import { IoMdClose } from "react-icons/io";
import CustomDropdown from "../custom_dropdown/CustomDropdown";

const GroupModalOverlay = ({ fetchAllGroups }) => {
  const {
    groups,
    userData,
    topRightAlert,
    centerAlert,
    fetchAllActiveDatabase,
  } = useProcessContext();
  const [activeDataBases, setActiveDatabases] = useState(null);
  const [odooGroups, setOdooGroups] = useState([]);
  const [groupData, setGroupData] = useState({
    group_database: null,
    group_type: null,
    parent_group: null,
    group_name: "",
    group_id: null,
    category_name: "",
    category_id: null,
  });
  const [groupsActiveDb, setGroupsActiveDb] = useState(null);
  // method for getting active database
  const getData = async () => {
    const activeDatabases = await fetchAllActiveDatabase();
    console.log("Active Databases:", activeDatabases);
    setActiveDatabases(activeDatabases);
  };
  // fetching active database
  useEffect(() => {
    getData();
  }, []);
  // fetch odoo existing groups
  const fetchGroups = async (dbname) => {
    try {
      const response = await fetch(
        `${BASE_URL}/odoo/groups?database=${
          dbname || groupData.group_database
        }&api_key=${API_KEY}`
      );
      const data = await response.json();
      if (response.ok) {
        if (Array.isArray(data.data)) {
          setOdooGroups(data.data);
        }
      } else {
        console.error("Error in fetch: ", data);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };
  useEffect(() => {
    if (groupData.group_database !== "") {
      fetchGroups();
    }
  }, [setOdooGroups, groups]);

  const handleDataChange = (e) => {
    const { name, value } = e.target;
    if (name === "group_database") {
      fetchGroups(value);
    }
    setGroupData({ ...groupData, [name]: value });
  };

  const handleSelectChange = (event) => {
    const selectedGroupId = event.target.value;
    const selectedGroupName =
      event.target.options[event.target.selectedIndex].text;
    setGroupData({
      ...groupData,
      group_id: selectedGroupId,
      group_name: selectedGroupName,
    });
  };

  const handleCategoryChange = (event) => {
    const selectedCategoryId = event.target.value;
    const selectedCategoryName =
      event.target.options[event.target.selectedIndex].text;
    setGroupData({
      ...groupData,
      category_id: selectedCategoryId,
      category_name: selectedCategoryName,
    });
  };

  const handleResetField = () =>
    setGroupData({
      group_database: null,
      group_type: null,
      group_id: null,
      group_name: "",
      category_id: null,
      category_name: "",
    });

  const createGroup = async () => {
    try {
      const response = await fetch(`${BASE_URL}/group/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...groupData, user_id: userData.userid }),
      });
      const data = await response.json();
      console.log("group create data: ", data);
      if (response.ok) {
        topRightAlert("success", data.message);
        fetchAllGroups(); // Fetch the latest groups after creating a new one
      } else {
        centerAlert("error", data.message);
        console.error("Error in fetch: ", data);
      }
    } catch (error) {
      centerAlert("error", error);
      console.error("Error: ", error);
    }
  };

  const handleAddMore = (e) => {
    e.preventDefault();
    if (groupData.group_database === null || groupData.group_database === "") {
      return centerAlert("error", "Hutch field is mandatory to fill.");
    }
    if (groupData.group_type === null || groupData.group_type === "") {
      return centerAlert("error", "Group type field is mandatory to fill.");
    }
    console.log("groupData: ", groupData);
    createGroup();
    handleResetField();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (groupData.group_database === null || groupData.group_database === "") {
      return centerAlert("error", "Hutch field is mandatory to fill.");
    }
    if (groupData.group_type === null || groupData.group_type === "") {
      return centerAlert("error", "Group type field is mandatory to fill.");
    }
    console.log("groupData handle submit: ", groupData);
    createGroup();
    handleResetField();
    const modalElement = document.getElementById("groupModal");
    const modalInstance = bootstrapBundleMin.Modal.getInstance(modalElement);
    modalInstance.hide();
  };

  const uniqueCategories = Array.from(
    new Set(odooGroups.map((group) => group.category_name))
  ).filter((name) => name); // Filter out any false or null values

  const filteredGroups = odooGroups.filter(
    (group) => group.category_id === parseInt(groupData.category_id)
  );

  // fetching all groups available in specific database
  useEffect(() => {
    if (groupData.group_database) {
      if (groups.length > 0) {
        const grps = groups.filter(
          (group) => group.database_obj === groupData.group_database
        );
        setGroupsActiveDb(grps);
      } else {
        fetchAllGroups();
      }
    }
  }, [groupData.group_database, groups]);

  // console.log("user_id::::::: ", userData.userid);
  // console.log("groupData::::::: ", groupData);
  return (
    <div
      className="modal fade"
      id="groupModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="groupModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="groupModalLabel">
              Create Group
            </h1>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                position: "absolute",
                right: "20px",
                fontSize: "25px",
                color: "white",
              }}
            >
              <IoMdClose />
            </button>
          </div>
          <form method="post" onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="groupModalForm">
                <div className="groupModalForm_inputField">
                  <label htmlFor="group_database">Hutch*</label>
                  <CustomDropdown
                    name="group_database"
                    defaultValue={groupData.group_database || null}
                    options={activeDataBases?.map((db) => {
                      return {
                        value: db._id,
                        label: `${db.configname} (${db.database})`,
                      };
                    })}
                    onChange={(event) => handleDataChange(event)}
                    width={"95%"}
                    height={"30px"}
                    border="1px solid gray"
                  />
                </div>
                <div className="groupModalForm_inputField">
                  <label htmlFor="group_type">Group Type*</label>
                  <CustomDropdown
                    name="group_type"
                    defaultValue={groupData.group_type || null}
                    options={[
                      { value: "new", label: "New Group" },
                      { value: "existing", label: "Existing Group" },
                    ]}
                    onChange={(event) => handleDataChange(event)}
                    width={"95%"}
                    height={"30px"}
                    border="1px solid gray"
                  />
                </div>
                {groupData.group_type === "new" && (
                  <>
                    <div className="groupModalForm_inputField">
                      <label htmlFor="new_group_name">Group Name</label>
                      <input
                        type="text"
                        name="group_name"
                        id="new_group_name"
                        placeholder="Enter new group name"
                        value={groupData.group_name}
                        onChange={handleDataChange}
                      />
                    </div>
                    <div className="groupModalForm_inputField">
                      <label htmlFor="new_category_name">Category</label>
                      <input
                        list="categories"
                        name="category_name"
                        id="new_category_name"
                        placeholder="Enter category name"
                        value={groupData.category_name}
                        onChange={handleDataChange}
                      />
                      <datalist id="categories">
                        {uniqueCategories.map((category, index) => (
                          <option key={index} value={category} />
                        ))}
                      </datalist>
                    </div>
                    <div className="groupModalForm_inputField">
                      <label htmlFor="parent_group">Parent Group</label>
                      <CustomDropdown
                        name="parent_group"
                        defaultValue={groupData.parent_group || null}
                        options={groupsActiveDb?.map((group) => {
                          return {
                            value: `base.${group.group_internal}`,
                            label: group.group_name,
                          };
                        })}
                        onChange={(event) => handleDataChange(event)}
                        width={"95%"}
                        height={"30px"}
                        border="1px solid gray"
                      />
                    </div>
                  </>
                )}
                {groupData.group_type === "existing" && (
                  <>
                    <div className="groupModalForm_inputField">
                      <label htmlFor="category_name">Category Name</label>
                      {/* <CustomDropdown
                        name="category_name"
                        defaultValue={groupData.category_id || ""}
                        options={uniqueCategories?.map((category) => {
                          return {
                            value: odooGroups.find(
                              (group) => group.category_name === category
                            ).category_id,
                            label: category,
                          };
                        })}
                        onChange={(event) => handleCategoryChange(event)}
                        width={"95%"}
                        height={"30px"}
                        border="1px solid gray"
                      /> */}
                      <select
                        name="category_name"
                        id="category_name"
                        value={groupData.category_id || ""}
                        onChange={handleCategoryChange}
                      >
                        <option value="#">Select category</option>
                        {uniqueCategories.map((category, index) => (
                          <option
                            key={index}
                            value={
                              odooGroups.find(
                                (group) => group.category_name === category
                              ).category_id
                            }
                          >
                            {category}
                          </option>
                        ))}
                      </select>
                    </div>
                    {groupData.category_id && (
                      <div className="groupModalForm_inputField">
                        <label htmlFor="group_name">Group Name</label>
                        <select
                          name="group_name"
                          id="group_name"
                          value={groupData.group_id || ""}
                          onChange={handleSelectChange}
                        >
                          <option value="#">Select group</option>
                          {filteredGroups.map((group, index) => (
                            <option key={index} value={group.id}>
                              {group.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                // type="submit"
                className="btn modal_save_button"
                onClick={handleAddMore}
              >
                Add more
              </button>
              <button type="submit" className="btn modal_save_button">
                Add & Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GroupModalOverlay;
