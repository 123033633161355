import React, { memo, useEffect, useState } from "react";
import "./ShapeUpdateForm.css";

import { PiKanban } from "react-icons/pi";

import { useNodeContext } from "../../../context/NodeContext";
import FormBuilderOverlay from "../../../template/form_builder_overlay/FormBuilderOverlay";
import { useFormContext } from "../../../context/FormContext";
import { useProcessContext } from "../../../context/ProcessContext";
import BASE_URL from "../../../../config";
import SelectedForm from "../../../template/selected_form_ui/SelectedForm";
import { MdDelete, MdOutlineClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import SelectedKanban from "../../../template/selected_form_ui/SelectedKanban";
import ConditionalModalOverlay from "../../../template/decision_conditional_modal/ConditionalModalOverlay";
import { IoMdClose } from "react-icons/io";
import SelectField from "../../../template/selection_field/SelectField";
import { GiCancel } from "react-icons/gi";
import ConditionFields from "../../../template/condition_fields/ConditionFields";
import ConditionContainer from "../../../template/condition_fields/ConditionContainer";
import DoctypeForm from "../../../template/doctype_form/DoctypeForm";
import CustomDropdown from "../../../template/custom_dropdown/CustomDropdown";
import CreateCustomer from "./CreateCustomer";
import ActionComponent from "./ActionComponent";
import CreateVendor from "./CreateVendor";
import CreateInvoice from "./CreateInvoice";
import CustomerCreateOverlay from "../../../template/action_modals/CustomerCreateOverlay";
import VendorCreateOverlay from "../../../template/action_modals/VendorCreateOverlay";
import InvoiceCreateOverlay from "../../../template/action_modals/InvoiceCreateOverlay";
import Swal from "sweetalert2";

const ShapeUpdateForm = () => {
  const navigate = useNavigate();
  const { nodes, setNodes, setShowShapeUpdateForm, shapeId, updateNodeTitle } =
    useNodeContext();
  const {
    forms,
    setForms,
    newFormId,
    setKanbanDetail,
    setIsKanbanBuilder,
    fetchForms,
  } = useFormContext();
  const {
    newProcessId,
    setToDos,
    setDocTypes,
    groups,
    topRightAlert,
    processDetail,
    fetchAllGroups,
    centerAlert,
  } = useProcessContext();
  const [conditionModalOpen, setConditionModalOpen] = useState(false);

  const [activeTab, setActiveTab] = useState("General");
  const [showDocTypeForm, setShowDocTypeForm] = useState(false);
  const [doctypes, setDoctpes] = useState([]);
  const tabs = ["General", "Steps", "Views", "Action", "DocTypes", "ToDos"];

  const [shapeData, setShapeData] = useState({
    value: "",
    type: "",
    group: [],
    action: "",
    form: "",
    isFormSelected: false,
    kanbanData: null,
    prev: null,
    prev_name: null,
    current: null,
    next: null,
    next_name: null,
    elseNextStep: null,
    doctype: null,
    todos: null,
  });

  // console.log("newFormId: ", newFormId);
  console.log("newProcessId: ", newProcessId);

  // hard coded doctype object for testing
  // const doctypes = [
  //   {
  //     docType_type: "pdf",
  //     docType_description: "this is portable document format",
  //     isMandatory: true,
  //   },
  //   {
  //     docType_type: "docx",
  //     docType_description: "this is text file",
  //     isMandatory: false,
  //   },
  //   {
  //     docType_type: "pptx",
  //     docType_description: "this is power point file",
  //     isMandatory: true,
  //   },
  // ];

  // Set shape data from the selected node
  useEffect(() => {
    if (shapeId !== null && nodes) {
      const selectedNode = nodes.find((node) => node.id === shapeId);
      console.log("selectedNode::::: ", selectedNode);
      if (selectedNode) {
        const nextsteps = selectedNode.data.next
          ? selectedNode.data.next
          : selectedNode.data.decision_conditions
          ? selectedNode.data.decision_conditions.map((condition) => ({
              condition,
              nextStep: "",
            }))
          : selectedNode.data.next;
        // console.log("nextSteps: ", nextsteps);
        if (selectedNode.type === "decision") {
          if (activeTab === "Views") {
            setActiveTab("General");
          }
        }
        setShapeData({
          value: selectedNode.data.value || "",
          type: selectedNode.type || "",
          group: selectedNode.data.group || [],
          action: selectedNode.data.action || "",
          form: selectedNode.data.form || "",
          isFormSelected: selectedNode.data.isFormSelected || false,
          kanbanData: selectedNode.data.kanbanData || null,
          prev: selectedNode.data.prev || null,
          prev_name: selectedNode.data.prev_name || null,
          current: selectedNode.data.current || null,
          next: nextsteps || null,
          next_name: selectedNode.data.next_name || null,
          elseNextStep: selectedNode.data.elseNextStep || null,
        });
      }
    }
    console.log("shapeData: ", shapeData);
  }, [shapeId, nodes]);

  // Handling input data for shape details
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShapeData((prevShapeData) => ({
      ...prevShapeData,
      [name]: value,
    }));
  };
  const handleStepsChange = (e) => {
    const { name, value } = e.target;
    if (value !== "" || value !== undefined) {
      const stepNode = nodes.find((node) => value === node.id);
      const nodeName = stepNode.data.value;
      const stepKey = `${name}_name`;
      setShapeData((prevShapeData) => ({
        ...prevShapeData,
        [name]: value,
        [stepKey]: nodeName,
      }));
    }
  };

  // method for property tab change
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  // shape update form's submit button
  const handleSubmit = () => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? { ...node, data: { ...node.data, ...shapeData } }
          : node
      )
    );
    console.log("shapeData: ", shapeData);
  };

  // useEffect for automate the submit process
  useEffect(() => {
    const intervalId = setInterval(handleSubmit, 2000); // Execute every 2 seconds
    if (conditionModalOpen) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [shapeId, shapeData, setNodes]);

  // method for field blur
  const handleBlurField = (e) => {
    e.preventDefault();
    if (e.target.name === "value") {
      const updatedNodesData = updateNodeTitle(nodes, shapeId, shapeData.value);
      setNodes(updatedNodesData);
    }
    handleSubmit();
  };

  // handling form name for duplicate name
  // const newFormName = (input) => {
  //   if (input.length === 0) {
  //     return "1";
  //   }

  //   const lastChar = input.charAt(input.length - 1);
  //   if (!isNaN(lastChar) && lastChar !== " ") {
  //     const num = parseInt(lastChar, 10);
  //     return input.slice(0, -1) + (num + 1);
  //   } else {
  //     return input + "1";
  //   }
  // };
  const newFormName = (input) => {
    const nameWithoutNumber = input.replace(/\d+$/, "").trim(); // Remove trailing digits
    const numberMatch = input.match(/(\d+)$/); // Find trailing digits, if any

    let newNumber = numberMatch ? parseInt(numberMatch[1], 10) + 1 : 1;
    return `${nameWithoutNumber} ${newNumber}`;
  };

  // method for handling kanban create
  const handleCreateKanban = () => {
    handleSubmit();
    setKanbanDetail([]);
    setIsKanbanBuilder(true);
    navigate("/form-builder");
  };
  useEffect(() => {
    setForms([]); // Clear forms before fetching new ones
    if (newProcessId) {
      fetchForms();
    }
  }, [newProcessId, setForms]);
  // handling the form selection
  // original code
  // const handleFormSelect = (form) => {
  //   // console.log(
  //   //   "formmmSelecteddddd==========>>>>>>>>>>>  ",
  //   //   JSON.stringify(form)
  //   // );
  //   // ===========================================================

  //   Swal.fire({
  //     title: "Choose your option",
  //     text: "Do you want to proceed with the original form or copy form?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     showDenyButton: true,
  //     confirmButtonText: "Original Form",
  //     denyButtonText: "Copy Form",
  //     cancelButtonText: "Cancel",
  //     customClass: {
  //       confirmButton: "swal-confirm", // Default confirm button styling
  //       denyButton: "swal-deny", // Custom class for Copy Form button
  //       cancelButton: "swal-cancel", // Custom class for Cancel button
  //     },
  //     reverseButtons: true, // reverses the position of the buttons
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       // Handle the Original Form button click
  //       setNodes((prevNodes) =>
  //         prevNodes.map((node) =>
  //           node.id === shapeId
  //             ? {
  //                 ...node,
  //                 data: { ...node.data, form, isFormSelected: true },
  //               }
  //             : node
  //         )
  //       );
  //       Swal.fire(
  //         "Original Form Selected",
  //         "Form assigned successfully to this activity.",
  //         "success"
  //       );
  //     } else if (result.isDenied) {
  //       // Handle the Copy Form button click
  //       copyForm();
  //       // Swal.fire(
  //       //   "Copy Form Selected",
  //       //   "You clicked the copy form option!",
  //       //   "success"
  //       // );
  //     } else if (result.isDismissed) {
  //       // Handle the Cancel button click
  //       // Swal.fire("Cancelled", "Action cancelled.", "info");
  //     }
  //   });

  //   // ===========================================================
  //   const copyForm = async () => {
  //     try {
  //       const response = await fetch(`${BASE_URL}/form/create`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           form_builder_name: `${shapeData.value.replace(
  //             " ",
  //             "-"
  //           )} form - copy`,
  //           // form_builder_name: `${shapeData.value.replace(
  //           //   " ",
  //           //   "-"
  //           // )}_${form.form_builder_name.replace(" ", "-")}`,
  //           form_builder_detail: form.form_builder_detail,
  //           formbuilder_data: form.formbuilder_data,
  //           user_id: form.user_id,
  //           process_id: form.process_id,
  //         }),
  //       });
  //       const data = await response.json();
  //       console.log("form create data:", data);
  //       if (response.ok) {
  //         // console.log("response selected new form data:", data.formBuilder);
  //         form = data.formBuilder;
  //         // console.log("after response form: ", form);
  //         // setShapeData((prevShapeData) => ({
  //         //   ...prevShapeData,
  //         //   form,
  //         //   isFormSelected: true,
  //         // }));
  //         setNodes((prevNodes) =>
  //           prevNodes.map((node) =>
  //             node.id === shapeId
  //               ? {
  //                   ...node,
  //                   data: { ...node.data, form, isFormSelected: true },
  //                 }
  //               : node
  //           )
  //         );
  //         // topRightAlert("success", data.message);
  //         Swal.fire("Copy Form Selected", data.message, "success");
  //         setForms([...forms, data.formBuilder]);
  //       } else if (response.status === 409) {
  //         let newName = `copy - ${newFormName(
  //           `${shapeData.value.trim()} form`
  //         )}`;
  //         // let newName = `${shapeData.value.replace(" ", "-")}_${newFormName(
  //         //   form.form_builder_name.replace(" ", "-")
  //         // )}`;
  //         const response = await fetch(`${BASE_URL}/form/create`, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             form_builder_name: newName,
  //             form_builder_detail: form.form_builder_detail,
  //             formbuilder_data: form.formbuilder_data,
  //             user_id: form.user_id,
  //             process_id: form.process_id,
  //           }),
  //         });
  //         const data = await response.json();
  //         if (response.ok) {
  //           setNodes((prevNodes) =>
  //             prevNodes.map((node) =>
  //               node.id === shapeId
  //                 ? {
  //                     ...node,
  //                     data: { ...node.data, form, isFormSelected: true },
  //                   }
  //                 : node
  //             )
  //           );
  //           // topRightAlert("success", data.message);
  //           Swal.fire("Copy Form Selected", data.message, "success");
  //           setForms([...forms, data.formBuilder]);
  //         }
  //       } else {
  //         centerAlert("error", data.message);
  //         console.error("Error creating copy form:", data);
  //       }
  //     } catch (error) {
  //       centerAlert("error", error);
  //       console.error("Error creating copy of selected form:", error);
  //     }
  //   };
  //   // copyForm();
  //   // setShapeData((prevShapeData) => ({
  //   //   ...prevShapeData,
  //   //   form,
  //   //   isFormSelected: true,
  //   // }));
  // };
  // secondary code
  // const handleFormSelect = (form) => {
  //   Swal.fire({
  //     title: "Choose your option",
  //     text: "Do you want to proceed with the original form or copy form?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     showDenyButton: true,
  //     confirmButtonText: "Original Form",
  //     denyButtonText: "Copy Form",
  //     cancelButtonText: "Cancel",
  //     customClass: {
  //       confirmButton: "swal-confirm",
  //       denyButton: "swal-deny",
  //       cancelButton: "swal-cancel",
  //     },
  //     reverseButtons: true,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       // Handle the Original Form selection
  //       setNodes((prevNodes) =>
  //         prevNodes.map((node) =>
  //           node.id === shapeId
  //             ? { ...node, data: { ...node.data, form, isFormSelected: true } }
  //             : node
  //         )
  //       );
  //       Swal.fire(
  //         "Original Form Selected",
  //         "Form assigned successfully.",
  //         "success"
  //       );
  //     } else if (result.isDenied) {
  //       // Handle the Copy Form selection
  //       copyForm(form);
  //     }
  //   });
  // };

  // const copyForm = async (form) => {
  //   try {
  //     const response = await fetch(`${BASE_URL}/form/create`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         form_builder_name: `${shapeData.value.replace(" ", "-")} form - copy`,
  //         form_builder_detail: form.form_builder_detail,
  //         formbuilder_data: form.formbuilder_data,
  //         user_id: form.user_id,
  //         process_id: form.process_id,
  //       }),
  //     });

  //     const data = await response.json();

  //     if (response.ok) {
  //       assignFormToNode(data.formBuilder);
  //       Swal.fire("Copy Form Selected", data.message, "success");
  //     } else if (response.status === 409) {
  //       handleConflict(form);
  //     } else {
  //       showErrorAlert(data.message);
  //     }
  //   } catch (error) {
  //     showErrorAlert(error.message);
  //     console.error("Error creating copy form:", error);
  //   }
  // };

  // const handleConflict = async (form) => {
  //   const newName = `copy - ${newFormName(`${shapeData.value.trim()} form`)}`;

  //   try {
  //     const response = await fetch(`${BASE_URL}/form/create`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         form_builder_name: newName,
  //         form_builder_detail: form.form_builder_detail,
  //         formbuilder_data: form.formbuilder_data,
  //         user_id: form.user_id,
  //         process_id: form.process_id,
  //       }),
  //     });

  //     const data = await response.json();

  //     if (response.ok) {
  //       assignFormToNode(data.formBuilder);
  //       Swal.fire("Copy Form Selected", data.message, "success");
  //     } else {
  //       showErrorAlert(data.message);
  //     }
  //   } catch (error) {
  //     showErrorAlert(error.message);
  //     console.error("Error handling form copy conflict:", error);
  //   }
  // };

  // const assignFormToNode = (form) => {
  //   setNodes((prevNodes) =>
  //     prevNodes.map((node) =>
  //       node.id === shapeId
  //         ? { ...node, data: { ...node.data, form, isFormSelected: true } }
  //         : node
  //     )
  //   );
  //   setForms((prevForms) => [...prevForms, form]);
  // };

  // const showErrorAlert = (message) => {
  //   Swal.fire("Error", message, "error");
  // };

  const handleFormSelect = (form) => {
    Swal.fire({
      title: "Choose your option",
      text: "Do you want to proceed with the original form or copy form?",
      icon: "warning",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: "Original Form",
      denyButtonText: "Copy Form",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "swal-confirm",
        denyButton: "swal-deny",
        cancelButton: "swal-cancel",
      },
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setNodes((prevNodes) =>
          prevNodes.map((node) =>
            node.id === shapeId
              ? { ...node, data: { ...node.data, form, isFormSelected: true } }
              : node
          )
        );
        Swal.fire(
          "Original Form Selected",
          "Form assigned successfully.",
          "success"
        );
      } else if (result.isDenied) {
        copyForm(form);
      }
    });
  };

  const copyForm = async (form) => {
    try {
      let response = await tryCreatingForm(
        form,
        `${shapeData.value.replace(" ", "-")} form - copy`
      );

      if (response.ok) {
        const data = await response.json();
        assignFormToNode(data.formBuilder);
        Swal.fire("Copy Form Selected", data.message, "success");
      }
    } catch (error) {
      showErrorAlert(error.message);
      console.error("Error creating copy form:", error);
    }
  };

  const tryCreatingForm = async (form, initialName) => {
    let attempt = 0;
    let name = initialName;
    let response;

    do {
      response = await fetch(`${BASE_URL}/form/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          form_builder_name: name,
          form_builder_detail: form.form_builder_detail,
          formbuilder_data: form.formbuilder_data,
          user_id: form.user_id,
          process_id: form.process_id,
        }),
      });

      if (response.status === 409) {
        attempt += 1;
        name = `copy - ${attempt} - ${shapeData.value.trim()} form`;
      }
    } while (response.status === 409);

    return response;
  };

  const assignFormToNode = (form) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? { ...node, data: { ...node.data, form, isFormSelected: true } }
          : node
      )
    );
    setForms((prevForms) => [...prevForms, form]);
  };

  const showErrorAlert = (message) => {
    Swal.fire("Error", message, "error");
  };

  // ===============================================================

  // handling the form remove from node
  // const handleFormRemove = async (formid) => {
  //   try {
  //     const response = await fetch(`${BASE_URL}/form/delete`, {
  //       method: "DELETE",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         form_builder_id: formid,
  //         process_id: newProcessId,
  //       }),
  //     });
  //     if (response.ok) {
  //       const data = await response.json();
  //       // setShapeData((prevShapeData) => ({
  //       //   ...prevShapeData,
  //       //   form: "",
  //       //   isFormSelected: false,
  //       // }));
  //       setNodes((prevNodes) =>
  //         prevNodes.map((node) => {
  //           if (node.id === shapeId && node.data.form) {
  //             return {
  //               ...node,
  //               data: {
  //                 ...node.data,
  //                 form: "",
  //                 isFormSelected:
  //                   node.id === shapeId ? false : node.data.isFormSelected,
  //               },
  //             };
  //           }
  //           return node;
  //         })
  //       );
  //       fetchForms();
  //       console.log("form deleted response: ", data);
  //       topRightAlert("success", data.message);
  //     } else {
  //       const data = await response.json();
  //       console.log("error in deleting form: ", data.message);
  //       centerAlert("error", data.message);
  //     }
  //   } catch (error) {
  //     console.log("error in form delete: ", error);
  //     centerAlert("error", error);
  //   }
  // };
  const handleFormRemove = async (formid) => {
    try {
      let formNodeCount = 0;
      nodes.forEach((node) => {
        if (node.data.form && node.data.form.form_builder_id === formid) {
          formNodeCount++;
        }
      });
      if (formNodeCount === 1) {
        const response = await fetch(`${BASE_URL}/form/delete`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            form_builder_id: formid,
            process_id: newProcessId,
          }),
        });
        if (response.ok) {
          const data = await response.json();
          // setShapeData((prevShapeData) => ({
          //   ...prevShapeData,
          //   form: "",
          //   isFormSelected: false,
          // }));
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === shapeId && node.data.form) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    form: "",
                    isFormSelected:
                      node.id === shapeId ? false : node.data.isFormSelected,
                  },
                };
              }
              return node;
            })
          );
          fetchForms();
          console.log("form deleted response: ", data);
          topRightAlert("success", data.message);
        } else {
          const data = await response.json();
          console.log("error in deleting form: ", data.message);
          centerAlert("error", data.message);
        }
      } else {
        // setNodes((prevNodes) =>
        //   prevNodes.map((node) => {
        //     if (node.id === shapeId) {
        //       return {
        //         ...node,
        //         data: {
        //           ...node.data,
        //           form: "",
        //           isFormSelected: false,
        //         },
        //       };
        //     }
        //     return node;
        //   })
        // );
        setShapeData((prevShapeData) => ({
          ...prevShapeData,
          form: "",
          isFormSelected: false,
        }));
        fetchForms();
        topRightAlert("success", "Form removed from this activity.");
      }
    } catch (error) {
      console.log("error in form delete: ", error);
      centerAlert("error", error);
    }
  };

  // handling the kanban remove from node
  const handleKanbanRemove = async () => {
    try {
      const response = await fetch(`${BASE_URL}/form/update/kanban`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          kanban_data: null,
          process_id: newProcessId,
          form_builder_id: newFormId,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        if (shapeId !== null) {
          topRightAlert("success", data.message);
          setNodes((prevNodes) =>
            prevNodes.map((node) =>
              node.id === shapeId
                ? {
                    ...node,
                    data: {
                      ...node.data,
                      kanbanData: null,
                    },
                  }
                : node
            )
          );
        }
      } else {
        topRightAlert("warning", data.message);
        console.log("error in saving kanban data: ", data);
      }
    } catch (err) {
      topRightAlert("error", err);
      console.error("error: ", err);
    }
    setShapeData((prevShapeData) => ({
      ...prevShapeData,
      kanbanData: null,
    }));
  };

  // method for calling handleSubmit method on clicking save button
  const handleSaveClick = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  // handling delete node from the process
  // const handleDelete = (e) => {
  //   e.preventDefault();
  //   setNodes((prevNodes) =>
  //     prevNodes.filter((node) => {
  //       if (node.id === shapeId) {
  //         return false;
  //       }
  //       return true;
  //     })
  //   );
  // };

  // method for removing condition
  const handleConditionRemove = (e, conIndex) => {
    e.preventDefault();
    const currentNode = nodes.find((node) => node.id === shapeId);
    const conditions = currentNode.data.next_name.filter(
      (_, index) => index !== conIndex
    );
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? {
              ...node,
              data: { ...node.data, next_name: [...conditions] },
            }
          : node
      )
    );
  };

  // handlimg the selection of next step for decision activity
  const handleSelectChange = (index, event) => {
    const updatedConditions = shapeData.next_name;
    const node = nodes.find((nd) => nd.id === event.target.value);
    updatedConditions[index].nextStepId = event.target.value;
    updatedConditions[index].nextStep = node.data.value;
    setShapeData({ ...shapeData, next_name: updatedConditions });
  };

  // handlimg the selection of exception step for decision activity
  const handleSelectElseNextStepChange = (e) => {
    let exceptionStepObj = shapeData.elseNextStep;
    if (exceptionStepObj === null) {
      exceptionStepObj = { nextStepId: "", nextStep: "" };
    }
    const node = nodes.find((nd) => nd.id === e.target.value);
    exceptionStepObj.nextStepId = e.target.value;
    exceptionStepObj.nextStep = node.data.value;
    setShapeData({ ...shapeData, elseNextStep: exceptionStepObj });
  };

  // handling group visibilty for fields
  // const [groupAccess, setGroupAccess] = useState("");
  const [showGroupAccessSelect, setShowGroupAccessSelect] = useState(true);
  const [filteredGroup, setFilteredGroup] = useState(null);
  const handleAddNewGroupAccess = (e) => {
    const selectedGroup = e.target.value;
    if (selectedGroup) {
      // setShapeData((prevData) => ({
      //   ...prevData,
      //   group: [...prevData.group, selectedGroup],
      // }));
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === shapeId
            ? {
                ...node,
                data: {
                  ...node.data,
                  group: [...node.data.group, selectedGroup],
                },
              }
            : node
        )
      );

      setShowGroupAccessSelect(false);
    }
  };

  const handleGroupAccessDelete = (e, index) => {
    setShapeData((prevData) => ({
      ...prevData,
      group: prevData.group.filter((_, i) => i !== index),
    }));
  };

  // useEffect for filter group by database
  useEffect(() => {
    if (groups) {
      const grps = groups.filter(
        (group) => group.database_obj === processDetail.database_obj
      );
      setFilteredGroup(grps);
    } else {
      fetchAllGroups();
    }
  }, [groups]);

  // method for prev and next drop down selection
  const formatNodesForPrevNextDropdown = () => {
    return nodes
      .filter((node) => node.id !== shapeId) // Exclude current shapeId from options
      .map((node) => ({
        value: node.id, // Use node id as value
        label: node.data.value, // Use node.data.value as label
      }));
  };

  // methods for action tab fields
  // state for storing array field
  const [actions, setActions] = useState([]);
  const [availableActions, setAvailableActions] = useState([
    "Create Customer",
    "Create Vendor",
    "Create Invoice",
  ]);
  const handleRemoveAction = (actionToRemove) => {
    setActions(actions.filter((action) => action !== actionToRemove));
    setAvailableActions([...availableActions, actionToRemove]);
  };

  console.log("shapeId: ", shapeId);
  console.log("formId: ", newFormId);
  // console.log("allFormsData: ", JSON.stringify(forms));
  // console.log("selectedConditions: ", selectedConditions);

  return (
    <div className="suf_container">
      <form action="" onSubmit={handleSaveClick} className="suf_container_form">
        <button
          className="close_btn"
          onClick={() => setShowShapeUpdateForm(false)}
        >
          <IoMdClose style={{ fontSize: "30px" }} />
        </button>
        <h4>{shapeData?.value ? shapeData.value : "Activity"} Properties</h4>
        {shapeId === null ? (
          <></>
        ) : (
          <>
            {/* delete node button  */}
            {/* <button
              className="btn btn-danger pm_shape_del_btn"
              onClick={handleDelete}
            >
              <MdDeleteOutline className="pm_shape_del_btn_icon" />
            </button> */}
            <ul className="saf_tab_box">
              {tabs.map((tab) => (
                <li
                  key={tab}
                  className={`${
                    ["Views"].includes(tab) && shapeData.type === "decision"
                      ? "d-none"
                      : ""
                  }`}
                >
                  {["Action", "DocTypes", "ToDos"].includes(tab) ? (
                    <button
                      className={`saf_tab_button ${
                        activeTab === tab ? "active_tab" : ""
                      }`}
                      title="Coming Soon Feature"
                      style={{ cursor: "not-allowed" }}
                      // onClick={() => handleTabClick(tab)}
                    >
                      {tab}
                    </button>
                  ) : (
                    <button
                      className={`saf_tab_button ${
                        activeTab === tab ? "active_tab" : ""
                      }`}
                      onClick={() => handleTabClick(tab)}
                    >
                      {tab}
                    </button>
                  )}
                </li>
              ))}
            </ul>
            <div
              className={`${
                activeTab === "General" ? "saf_tab_general_box" : "d-none"
              } `}
            >
              <div className="suf_inpfield mt-2">
                <label htmlFor="type">Activity Type:</label>
                <input
                  id="type"
                  type="text"
                  name="type"
                  style={{ backgroundColor: "#eee" }}
                  value={shapeData.type}
                  readOnly
                />
              </div>
              <div className="suf_inpfield mt-1">
                <label htmlFor="title">Activity Name:</label>
                <input
                  id="title"
                  type="text"
                  placeholder="Enter title here..."
                  name="value"
                  value={shapeData.value}
                  className="nodrag"
                  onChange={handleInputChange}
                  onBlur={handleBlurField}
                  readOnly={
                    ["decision"].includes(shapeData.type) ? true : false
                  }
                />
              </div>
              <div className="suf_inpfield mt-1">
                <label htmlFor="group">Activity Group:</label>
                <div className="suf_group_access_box">
                  {shapeData && shapeData.group.length > 0 && (
                    <ul>
                      {Array.isArray(shapeData.group) &&
                        shapeData.group.map((groupName, index) => (
                          <li key={index} className="suf_group_access_list">
                            <span style={{ fontSize: "14px" }}>
                              {index + 1}.
                            </span>
                            <span className="suf_group_access_name_span">
                              {groupName}
                            </span>
                            <button
                              className="suf_group_access_delete"
                              onClick={(e) => handleGroupAccessDelete(e, index)}
                            >
                              <GiCancel />
                            </button>
                          </li>
                        ))}
                    </ul>
                  )}
                  {showGroupAccessSelect ? (
                    <div className="suf_group_access_select_box">
                      <select
                        name="group"
                        id="group"
                        onChange={handleAddNewGroupAccess}
                        onBlur={handleBlurField}
                      >
                        <option value="">Select Group</option>
                        {filteredGroup?.map((group, index) => (
                          <option
                            key={index}
                            value={`base.${group.group_internal}`}
                          >
                            {group.group_name}
                            {/* {`(base.${group.group_internal})`} */}
                          </option>
                        ))}
                        {/* {groups?.map((group, index) => (
                          <option
                            key={index}
                            value={`base.${group.group_internal}`}
                          >
                            {group.group_name}
                            {`(base.${group.group_internal})`}
                          </option>
                        ))} */}
                      </select>
                      <button
                        className="suf_group_access_close_button"
                        onClick={() => setShowGroupAccessSelect(false)}
                      >
                        <MdOutlineClose />
                      </button>
                    </div>
                  ) : (
                    <>
                      <span
                        onClick={() => setShowGroupAccessSelect(true)}
                        className="suf_group_access_show_select"
                      >
                        Add group
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div
              className={`${
                activeTab === "Steps" ? "saf_tab_steps_box" : "d-none"
              } `}
            >
              <div className="suf_inpfield align-items-center mt-1">
                <label className="align-self-start">Activity Steps:</label>
                <div className="suf_inpfield d-none">
                  <label htmlFor="currentStep">Current Step:</label>
                  <input
                    id="currentStep"
                    type="text"
                    name="current"
                    value={shapeData.value}
                    style={{ cursor: "not-allowed" }}
                    className="nodrag"
                    onChange={handleInputChange}
                    onBlur={handleBlurField}
                    readOnly
                  />
                </div>
                {shapeData.type !== "start" && (
                  <div className="suf_sub_inpfield">
                    <label htmlFor="prevStep">Previous Step</label>
                    <CustomDropdown
                      name="prev"
                      defaultValue={shapeData.prev || ""}
                      options={formatNodesForPrevNextDropdown()}
                      onChange={(event) => handleStepsChange(event)}
                      onBlur={handleBlurField}
                      width={"90%"}
                      height={"25px"}
                    />
                  </div>
                )}
                {shapeData.type !== "end" &&
                  shapeData.type !== "reject" &&
                  shapeData.type !== "discard" &&
                  shapeData.type !== "exception" &&
                  shapeData.type !== "decision" && (
                    <div className="suf_sub_inpfield">
                      <label htmlFor="nextStep">Next Step</label>
                      <CustomDropdown
                        name="next"
                        defaultValue={shapeData.next || ""}
                        options={formatNodesForPrevNextDropdown()}
                        onChange={(event) => handleStepsChange(event)}
                        onBlur={handleBlurField}
                        width={"90%"}
                        height={"25px"}
                      />
                    </div>
                  )}
              </div>
              {/* code for multi condition */}
              {shapeData.type === "decision" && (
                <div className="suf_inpfield mt-1">
                  <label className="align-self-start">Conditions:</label>
                  {shapeData.next_name && shapeData.next_name.length > 0 && (
                    <>
                      <div
                        className="ps-3 d-flex flex-column justify-content-center align-content-center gap-2"
                        style={{ width: "95%" }}
                      >
                        {shapeData.next_name.map((conditionData, index) => (
                          <div
                            className="d-flex flex-column justify-content-center align-items-start gap-1 mt-2"
                            key={index}
                            style={{ width: "100%" }}
                          >
                            <div
                              className="d-flex justify-content-between align-items-start"
                              style={{ width: "100%" }}
                            >
                              <div
                                className="d-flex justify-content-start align-items-start gap-2"
                                style={{ width: "100%" }}
                              >
                                <span
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                  }}
                                >
                                  {index + 1}
                                  {"."}
                                </span>
                                <span
                                  style={{
                                    width: "90%",
                                    wordBreak: "break-all",
                                    wordWrap: "break-word",
                                    lineBreak: "anywhere",
                                    lineClamp: "3",
                                    fontSize: "12px",
                                  }}
                                >
                                  {JSON.stringify(
                                    conditionData.condition,
                                    null,
                                    2
                                  )}
                                </span>
                              </div>
                              <button
                                onClick={(e) => handleConditionRemove(e, index)}
                                className="condition_delete_button"
                              >
                                <MdDelete />
                              </button>
                            </div>
                            <div className="suf_sub_inpfield">
                              <label htmlFor={`next${index + 1}`}>
                                Next Step
                              </label>
                              <select
                                id={`next${index + 1}`}
                                name={`next${index + 1}`}
                                value={
                                  shapeData.next_name
                                    ? shapeData.next_name[index].nextStepId
                                    : ""
                                }
                                onChange={(event) =>
                                  handleSelectChange(index, event)
                                }
                                onBlur={handleBlurField}
                              >
                                <option value="">Select next activity</option>
                                {nodes.map((node) => {
                                  if (node.id !== shapeId) {
                                    return (
                                      <option key={node.id} value={node.id}>
                                        {node.data.value}
                                      </option>
                                    );
                                  }
                                  return null;
                                })}
                              </select>
                            </div>
                          </div>
                        ))}
                        {/* code for exception case field */}
                        <div className="suf_sub_inpfield">
                          <label htmlFor="elseNextStep">Exception Step</label>
                          <select
                            id="elseNextStep"
                            name="elseNextStep"
                            value={shapeData?.elseNextStep?.nextStepId || ""}
                            onChange={handleSelectElseNextStepChange}
                            onBlur={handleBlurField}
                          >
                            <option value="">Select exception activity</option>
                            {nodes.map((node) => {
                              if (node.id !== shapeId) {
                                return (
                                  <option key={node.id} value={node.id}>
                                    {node.data.value}
                                  </option>
                                );
                              }
                              return null;
                            })}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  <ConditionContainer shapeData={shapeData} />
                </div>
              )}
            </div>

            <div
              className={`${
                activeTab === "Views" ? "saf_tab_views_box" : "d-none"
              } ${
                activeTab === "Views" && shapeData.type === "decision"
                  ? "d-none"
                  : ""
              } `}
            >
              <div className="suf_inpfield mt-2">
                {shapeData.isFormSelected && <label>Assign Form:</label>}
                {shapeData.isFormSelected ? (
                  <SelectedForm
                    sltdForm={shapeData.form}
                    handleFormRemove={handleFormRemove}
                  />
                ) : (
                  <>
                    <label>Assign Form:</label>
                    <SelectField
                      options={
                        forms.length > 0
                          ? [
                              { value: "create", label: "Create Form" },
                              { value: "existing", label: "Existing Form" },
                            ]
                          : [{ value: "create", label: "Create Form" }]
                      }
                      // options={select_options}
                      forms={forms}
                      handleFormSelect={handleFormSelect}
                      handleBlurField={handleBlurField}
                    />
                  </>
                )}
              </div>
              {shapeData.isFormSelected && (
                <div className="suf_inpfield mt-1">
                  {shapeData.kanbanData && <label>Assign Kanban:</label>}
                  {shapeData.kanbanData ? (
                    <SelectedKanban
                      sltdForm={shapeData.form}
                      handleKanbanRemove={handleKanbanRemove}
                    />
                  ) : (
                    <button
                      style={{ cursor: "not-allowed" }}
                      title="Coming soon feature"
                      className="create_kanban_button"
                      // className="btn btn-dark"
                      // onClick={handleCreateKanban}
                    >
                      <PiKanban style={{ fontSize: "20px" }} /> Create Kanban
                    </button>
                  )}
                </div>
              )}
            </div>
            <div
              className={`${
                activeTab === "Action" ? "saf_tab_doctype_box" : "d-none"
              } `}
            >
              {actions.map((action) => {
                if (action === "Create Customer") {
                  return (
                    <CreateCustomer handleRemoveAction={handleRemoveAction} />
                  );
                } else if (action === "Create Vendor") {
                  return (
                    <CreateVendor handleRemoveAction={handleRemoveAction} />
                  );
                } else if (action === "Create Invoice") {
                  return (
                    <CreateInvoice handleRemoveAction={handleRemoveAction} />
                  );
                }
                return null;
              })}

              <ActionComponent
                actions={actions}
                setActions={setActions}
                availableActions={availableActions}
                setAvailableActions={setAvailableActions}
                shapeData={shapeData}
              />
            </div>
            <div
              className={`${
                activeTab === "DocTypes" ? "saf_tab_doctype_box" : "d-none"
              } `}
            >
              {Array.isArray(doctypes) && doctypes?.length > 0 ? (
                <>
                  <div className="suf_inpfield mt-1">
                    <label htmlFor="title">Attach Doctype:</label>
                    <select name="" id="">
                      <option value="">Select doctype</option>
                      {doctypes.map((doctype, index) => (
                        <option key={index} value={doctype.docType_type}>
                          {doctype.docType_type}
                        </option>
                      ))}
                    </select>
                    {/* <input
                      id="title"
                      type="text"
                      placeholder="Enter title here..."
                      name="value"
                      value={shapeData.value}
                      className="nodrag"
                      onChange={handleInputChange}
                      onBlur={handleBlurField}
                    /> */}
                  </div>
                </>
              ) : (
                <>
                  {showDocTypeForm ? (
                    <>
                      <DoctypeForm
                        showDocTypeForm={showDocTypeForm}
                        setShowDocTypeForm={setShowDocTypeForm}
                      />
                    </>
                  ) : (
                    <div className="suf_inpfield mt-1">
                      <li className="ms-3">Doctype is not available</li>
                      <button
                        className="docType_create_btn mt-2"
                        onClick={() => setShowDocTypeForm(true)}
                      >
                        Create Doctype
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>

            {/* <button type="submit" className="btn pb_save_button">
              <FaSave style={{ fontSize: "20px" }} /> Save
            </button> */}
          </>
        )}
      </form>
      {/* Modal */}
      <FormBuilderOverlay />
      <CustomerCreateOverlay shapeData={shapeData} />
      <VendorCreateOverlay shapeData={shapeData} />
      <InvoiceCreateOverlay shapeData={shapeData} />
      {/* <ConditionalModalOverlay setConditionModalOpen={setConditionModalOpen} /> */}
      {/* over modal code */}
    </div>
  );
};

export default memo(ShapeUpdateForm);
