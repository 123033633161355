import React, { useState } from "react";
import "./DoctypeForm.css";

const DoctypeForm = ({ showDocTypeForm, setShowDocTypeForm }) => {
  const [doctype, setDoctype] = useState({
    docType_type: "",
    docType_description: "",
    isMandatory: false,
  });

  const resetDoctypeForm = () => {
    setDoctype({
      docType_type: "",
      docType_description: "",
      isMandatory: false,
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setDoctype((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleAddMore = () => {
    // Submit data
    console.log("Form submitted:", doctype);

    // Reset form fields
    resetDoctypeForm();
  };

  const handleAddAndClose = () => {
    // Submit data
    console.log("Form submitted:", doctype);

    // Close form and reset fields
    resetDoctypeForm();
    setShowDocTypeForm(false);
  };

  const handleCancel = () => {
    // Close form without submitting and reset fields
    resetDoctypeForm();
    setShowDocTypeForm(false);
  };

  return (
    <>
      {showDocTypeForm ? (
        <>
          <span className="doctype_form_head_label">Add Doctype</span>
          <div className="suf_inpfield">
            <label htmlFor="doctype_type">Document Type:</label>
            <input
              id="doctype_type"
              type="text"
              name="docType_type"
              placeholder="enter detail"
              style={{ backgroundColor: "#eee" }}
              value={doctype.docType_type}
              onChange={handleChange}
              required
            />
          </div>
          <div className="suf_inpfield mt-2">
            <label htmlFor="doctype_description">Description:</label>
            <textarea
              name="docType_description"
              id="doctype_description"
              rows={3}
              value={doctype.docType_description}
              placeholder="enter detail"
              onChange={handleChange}
            ></textarea>
          </div>
          <div
            className="suf_inpfield mt-2"
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <input
              id="isMandatory"
              type="checkbox"
              name="isMandatory"
              style={{ backgroundColor: "#eee" }}
              checked={doctype.isMandatory}
              onChange={handleChange}
              required
            />
            <label htmlFor="isMandatory">Is mandatory</label>
          </div>
          <div className="docType_buttons">
            <button className="docType_btn" onClick={handleCancel}>
              Cancel
            </button>
            <button className="docType_btn" onClick={handleAddAndClose}>
              Add & Close
            </button>
            <button className="docType_btn" onClick={handleAddMore}>
              Add More
            </button>
          </div>
        </>
      ) : (
        <>
          <span
            className="suf_group_access_show_select ms-3"
            onClick={() => setShowDocTypeForm(true)}
          >
            Add new doctype
          </span>
        </>
      )}
    </>
  );
};

export default DoctypeForm;
